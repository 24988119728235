import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../components/modal/modal.component';
import {IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonTabButton} from "@ionic/react";
import {MenuItem} from "../../models/menu";
import {StyledModalContent} from "../../components/modal/modal.style";
import {Links} from "../../app/links";
import {Link, useHistory} from "react-router-dom";
import {
    calendarOutline,
    checkboxOutline,
    documentTextOutline, logOutOutline, notificationsOutline, peopleOutline,
    pieChartOutline, readerOutline,
    walletOutline, chatbubblesOutline, receiptOutline, arrowForward, settingsOutline, bagOutline, qrCodeOutline,
    idCardOutline, personOutline, cardOutline
} from "ionicons/icons";
import ChartImage from "../../assets/images/chart-pie-36.svg";
import UserImage from "../../assets/images/file-user.svg";
import BellImage from "../../assets/images/bell.svg";
import AvatarImage from "../../assets/images/avatar.svg";
import {logout, useProvideAuth} from "../../services/auth.service";
import {Preferences} from "@capacitor/preferences";
import ProfileImage from "../../assets/images/avatar.svg";
import {MenuContainer} from "./menu.style";
import ExactPayImage from '../../assets/images/exact-pay-gray.svg';
import ExactPayImageActive from '../../assets/images/exact-pay-active.svg';
import ReportsImage from '../../assets/images/reports.svg';
import ReportsImageActive from "../../assets/images/reports-active.svg";
import JobOffersImage from '../../assets/images/job-offers.svg';
import JobOffersImageActive from '../../assets/images/job-offers-active.svg';
import RegisterImage from '../../assets/images/time-clock-edit.svg';
import RegisterImageActive from '../../assets/images/time-clock-edit-active.svg';
import BillingImage from '../../assets/images/planning-white.svg';
import BillingImageActive from '../../assets/images/planning-active.svg';
import HoursWorkedImage from '../../assets/images/time-clock.svg';
import BenefitsImage from '../../assets/images/benefits.svg';
import ReferFriendImage from '../../assets/images/referer_friends.svg';
import HoursWorkedImageActive from '../../assets/images/time-clock-active.svg';
import NotificationsImage from '../../assets/images/notifications.svg';
import NotificationsImageActive from '../../assets/images/notifications-active.svg';
import AvailabilityImage from '../../assets/images/availability.svg';
import AvailabilityImageActive from '../../assets/images/availability-active.svg';
import ScheduleImage from '../../assets/images/schedule.svg';
import ScheduleImageActive from '../../assets/images/schedule-active.svg';
import WalletImage from '../../assets/images/wallet.svg';
import WalletImageActive from '../../assets/images/wallet-active.svg';
import HoursRegistryImage from '../../assets/images/hours-registry.svg';
import HoursRegistryImageActive from '../../assets/images/hours-registry-active.svg';
import ContractsImage from '../../assets/images/contracts.svg';
import ContractsImageActive from '../../assets/images/contracts-active.svg';
import BillingsImage from '../../assets/images/billings.svg';
import BillingsImageActive from '../../assets/images/billings-active.svg';
import BotImage from '../../assets/images/bot.svg';
import PlanningImage from '../../assets/images/planning.svg';
import PlanningImageActive from '../../assets/images/planning-active.svg';
import SettingsImage from '../../assets/images/settings.svg';
import SettingsImageActive from '../../assets/images/settings-active.svg';
import MyDataImage from '../../assets/images/my-data.svg';
import MyDataImageActive from '../../assets/images/my-data-active.svg';
import WorkerCoursesImage from '../../assets/images/moodle.svg';
import WorkerCoursesImageActive from '../../assets/images/moodle-active.svg';
import LogoutImage from '../../assets/images/logout.svg';
import RideImage from '../../assets/images/ride.svg';
import RideImageActive from '../../assets/images/ride-active.svg';
import RideRouteImage from '../../assets/images/route.svg';
import RideRouteImageActive from '../../assets/images/route-active.svg';
import CarImage from '../../assets/images/car.svg';
import CarImageActive from '../../assets/images/car-active.svg';

import {useLocation} from "react-router-dom";

import {Browser} from "@capacitor/browser";
import {generateBenefitsToken, generateBotToken} from "../../services/profile.service";
import {ifNull} from "../../utils/tools/numbers";
import WorkerBadgeModal from "../worker/workerBadge.component";
import {useEvent} from "../../hooks/useEvent";
import {EventType} from "../../enums/eventType";
import WorkerCoursesInfoModal from "../worker/workerCoursesInfo.component";
import {setReadMoodleInfo} from "../../services/course.service";

type MenuModalProps = {
    isOpen: boolean;
    onClose: () => void;
    menuItems: MenuItem[];
    avatarUrl: string;
};

type MenuUserData = {
    name: string;
    email: string;
    avatar: string;
};

const MenuModal: React.FC<MenuModalProps> = (props: MenuModalProps) => {

    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const {listen} = useEvent();

    const [userData, updateUserData] = useState<MenuUserData | null>(null);
    const [showWorkerBadgeModal, updateShowWorkerBadgeModal] = useState<boolean>(false);
    const [showWorkerCoursesModal, updateShowWorkerCoursesModal] = useState<boolean>(false);
    const [readMoodleInfo, updateReadMoodleInfo] = useState<boolean>(false);

    const getUserData = async () => {
        let firstName = await Preferences.get({'key': 'first_name'});
        let lastName = await Preferences.get({'key': 'last_name'});
        let avatarUrl = await Preferences.get({'key': 'avatar_url'});
        let email = await Preferences.get({'key': 'email'});
        let readMoodleInfo = await Preferences.get({'key': 'read_moodle_info'});

        let userData = {
            name: ifNull(firstName.value) == null || firstName.value == '' || ifNull(lastName.value) == null || lastName.value == '' ? null : firstName.value + ' ' + lastName.value,
            email: email.value,
            avatar: avatarUrl.value
        } as MenuUserData;

        updateUserData(userData);
        updateReadMoodleInfo(readMoodleInfo.value == '1' ? true : false);
    };

    useEffect(() => {
        getUserData();
        listen(EventType["REFRESH.MENU.USER_DATA"], getUserData);
    }, []);

    const redirect = async (menuItem: MenuItem) => {
        let url = null;
        switch (menuItem.reference) {
            case 'app.mobile_menu.reports':
            case 'app.mobile_menu.reports_ko':
                url = Links.main + Links.reports;
                break;
            case 'app.mobile_menu.offers':
                url = Links.main + Links.offers;
                break;
            case 'app.mobile_menu.notifications':
                url = Links.main + Links.notifications;
                break;
            case 'app.mobile_menu.communicator':
                url = Links.main + Links.communicator;
                break;
            case 'app.mobile_menu.availability':
                url = Links.main + Links.availability;
                break;
            case 'app.mobile_menu.schedule':
                url = Links.main + Links.timetable;
                break;
            case 'app.mobile_menu.wallet':
                url = Links.main + Links.wallet;
                break;
            case 'app.mobile_menu.hours_register':
                url = Links.main + Links.hoursRegistry;
                break;
            case 'app.mobile_menu.contracts':
                url = Links.main + Links.contracts;
                break;
            case 'app.mobile_menu.exact_pay':
                url = Links.main + Links.loans;
                break;
            case 'app.mobile_menu.profile':
                url = Links.main + Links.profile;
                break;
            case 'app.mobile_menu.planning':
                url = Links.main + Links.planning;
                break;
            case 'app.mobile_menu.payout_billings':
                url = Links.main + Links.billings;
                break;
            case 'app.mobile_menu.my_data':
                url = Links.main + Links.myData;
                break;
            case 'app.mobile_menu.hours_worked':
                url = Links.main + Links.hoursWorked;
                break;
            case 'app.mobile_menu.worker_warehouse':
                url = Links.main + Links.workerWarehouse;
                break;
            case 'app.mobile_menu.worker_badge':
                updateShowWorkerBadgeModal(true);
                break;
            case 'app.mobile_menu.bot':
                let botTokenUrl = await generateBotToken().then((result) => {
                    return result.data.url;
                })

                if (botTokenUrl) {
                    await Browser.open({url: botTokenUrl})
                }
                break;
            case 'app.mobile_menu.benefits':
                let benefitTokenUrl = await generateBenefitsToken().then((result) => {
                    return result.data.url;
                })

                if (benefitTokenUrl) {
                    await Browser.open({url: benefitTokenUrl})
                }
                break;
            case 'app.mobile_menu.refer_friend':
                url = Links.main + Links.referFriends;
                break;
            case 'app.mobile_menu.recruitment':
                url = Links.main + Links.recruitment + '/DEFAULT';
                break;
            case 'app.mobile_menu.audit_reports':
                url = Links.main + Links.audits;
                break;
            case 'app.mobile_menu.audit_incompatibilities':
                url = Links.main + Links.inconsistencyModule;
                break;
            case 'app.mobile_menu.rides':
                url = Links.main + Links.travelAllowance.ride.list;
                break;
            case 'app.mobile_menu.ride_routes':
                url = Links.main + Links.travelAllowance.rideRoute.list;
                break;
            case 'app.mobile_menu.cars':
                url = Links.main + Links.travelAllowance.car.list;
                break;
            case 'app.mobile_menu.courses':
                updateShowWorkerCoursesModal(true);
                updateReadMoodleInfo(true);
                await setReadMoodleInfo();
                break;
        }

        if (url) {
            history.push(url);
        }
    }

    const getIcon = (menuItem: MenuItem) => {
        let pathname = location.pathname;

        switch (menuItem.reference) {
            case 'app.mobile_menu.reports':
            case 'app.mobile_menu.reports_ko':
                return pathname == '/main/reports' ?
                    <IonIcon src={ReportsImageActive} className="active"
                             style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={ReportsImage} className="non-active"
                             style={{"fontSize": "20px", "color": '#888A98'}}/>
                    ;
            case 'app.mobile_menu.offers':
                return pathname == '/main/offers' ?
                    <IonIcon src={JobOffersImageActive} className="active"
                             style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={JobOffersImage} className="non-active"
                             style={{"fontSize": "20px", "color": '#888A98'}}/>
                    ;
            case 'app.mobile_menu.notifications':
                return pathname == '/main/notifications' ?
                    <IonIcon src={NotificationsImageActive} className="active"
                             style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={NotificationsImage} className="non-active"
                             style={{"fontSize": "20px", "color": '#888A98'}}/>
                    ;
            // case 'app.mobile_menu.communicator':
            //     return <img className="non-active" src={BellImage}/>;
            case 'app.mobile_menu.availability':
                return pathname == '/main/availability' ?
                    <IonIcon src={AvailabilityImageActive} className="active"
                             style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={AvailabilityImage} className="non-active"
                             style={{"fontSize": "20px", "color": '#888A98'}}/>
                    ;
            case 'app.mobile_menu.schedule':
                return pathname == '/main/timetable' ?
                    <IonIcon src={ScheduleImageActive} className="active"
                             style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={ScheduleImage} className="non-active"
                             style={{"fontSize": "20px", "color": '#888A98'}}/>
                    ;
            case 'app.mobile_menu.wallet':
                return pathname == '/main/wallet' ?
                    <IonIcon src={WalletImageActive} className="active"
                             style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={WalletImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
                    ;
            case 'app.mobile_menu.hours_register':
                return pathname == '/main/hours-registry' ?
                    <IonIcon src={HoursRegistryImageActive} className="active"
                             style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={HoursRegistryImage} className="non-active"
                             style={{"fontSize": "20px", "color": '#888A98'}}/>
                    ;
            case 'app.mobile_menu.contracts':
                return pathname == '/main/contracts' ?
                    <IonIcon src={ContractsImageActive} className="active"
                             style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={ContractsImage} className="non-active"
                             style={{"fontSize": "20px", "color": '#888A98'}}/>
                    ;
            case 'app.mobile_menu.exact_pay':
                return pathname == '/main/loans' ?
                    <IonIcon src={ExactPayImageActive} className="active"
                             style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={ExactPayImage} className="non-active"
                             style={{"fontSize": "20px", "color": '#888A98'}}/>
                    ;
            case 'app.mobile_menu.profile':
                return pathname == '/main/profile' ?
                    <IonIcon src={AvatarImage} className="profile" style={{"fontSize": "20px", "color": '#888A98'}}/> :
                    <IonIcon src={AvatarImage} className="profile" style={{"fontSize": "20px", "color": '#888A98'}}/>
                    ;
            case 'app.mobile_menu.planning':
                return pathname == '/main/planning' ?
                    <IonIcon src={PlanningImageActive} className="active"
                             style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={PlanningImage} className="non-active"
                             style={{"fontSize": "20px", "color": '#888A98'}}/>
                    ;
            case 'app.mobile_menu.payout_billings':
                return pathname == '/main/billings' ?
                    <IonIcon src={BillingsImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={BillingsImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
                ;
                return <IonIcon src={BillingsImage} style={{"fontSize": "20px", "color": '#888A98'}}/>;
            case 'app.mobile_menu.my_data':
                return pathname == '/main/my-data' ?
                    <IonIcon src={MyDataImageActive} className="active"
                             style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={MyDataImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
                    ;
            case 'app.mobile_menu.hours_worked':
                return pathname == '/main/hours-worked' ?
                    <IonIcon src={HoursWorkedImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={HoursWorkedImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
                ;
            case 'app.mobile_menu.bot':
                return <IonIcon src={BotImage} style={{"fontSize": "20px", "color": '#888A98'}}/>;
            case 'app.mobile_menu.worker_badge':
                return <IonIcon src={qrCodeOutline} style={{"fontSize": "20px", "color": '#888A98'}}/>;
            case 'app.mobile_menu.worker_warehouse':
                return pathname == '/main/worker-warehouse' ?
                    <IonIcon src={bagOutline} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={bagOutline} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>;
            case 'app.mobile_menu.benefits':
                return <IonIcon src={BenefitsImage} style={{"fontSize": "20px", "color": '#888A98'}}/>;
            case 'app.mobile_menu.refer_friend':
                return <IonIcon src={ReferFriendImage} style={{"fontSize": "20px", "color": '#888A98'}}/>;
            case 'app.mobile_menu.recruitment':
                return pathname == '/main/recruitment/DEFAULT' ?
                    <IonIcon src={bagOutline} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={bagOutline} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>;
            case 'app.mobile_menu.audit_reports':
                return pathname == '/main/audits' ?
                    <IonIcon src={checkboxOutline} className="active"
                             style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={checkboxOutline} className="non-active"
                             style={{"fontSize": "20px", "color": '#888A98'}}/>;
            case 'app.mobile_menu.audit_incompatibilities':
                return pathname == '/main/inconsistency-module' ?
                    <IonIcon src={checkboxOutline} className="active"
                             style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={checkboxOutline} className="non-active"
                             style={{"fontSize": "20px", "color": '#888A98'}}/>;
            case 'app.mobile_menu.rides':
                return pathname.startsWith('/main/travel-allowance/ride/') ?
                    <IonIcon src={RideImageActive} className="active"
                             style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={RideImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
                    ;
            case 'app.mobile_menu.ride_routes':
                return pathname.startsWith('/main/travel-allowance/ride-route/') ?
                    <IonIcon src={RideRouteImageActive} className="active"
                             style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={RideRouteImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
                    ;
            case 'app.mobile_menu.cars':
                return pathname.startsWith('/main/travel-allowance/car/') ?
                    <IonIcon src={CarImageActive} className="active"
                             style={{"fontSize": "20px", "color": '#0085FF'}}/> :
                    <IonIcon src={CarImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
                    ;
            case 'app.mobile_menu.courses':
                return readMoodleInfo ?
                    <IonIcon src={WorkerCoursesImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/> :
                    <IonIcon src={WorkerCoursesImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/>;
        }
    }

    const getSettingsIcon = () => {
        let pathname = location.pathname;
        return pathname == '/main/settings' ?
            <IonIcon src={SettingsImageActive} className="active" style={{"fontSize": "20px", "color": '#0085FF'}}/> :
            <IonIcon src={SettingsImage} className="non-active" style={{"fontSize": "20px", "color": '#888A98'}}/>
            ;
    };

    const user = useProvideAuth();

    const logoutClick = async () => {
        await logout().then(() => {
            user.signOut();
            history.replace(Links.login)
            window.top.location.reload();
        });
    }

    return (
        <>
            <Modal
                isOpen={props.isOpen}
                maxHeight={props.menuItems ? (props.menuItems.length > 10 ? '96%' : (props.menuItems.length) + '6%') : '50%'}
                // initialBreakpoint={0.5}
                // breakpoints={[0, 0.5, 0.99]}
                onDidDismiss={() => props.onClose()}>
                <StyledModalContent>
                    <div className="content full-height">
                        <MenuContainer>
                            <IonItem onClick={e => {
                                props.onClose();
                                history.push(Links.main + Links.profile);
                            }}>
                                <IonCol size='2'>
                                    <div className="profile-avatar">
                                        <img src={userData?.avatar || ProfileImage} className="avatar"/>
                                    </div>
                                </IonCol>
                                <IonCol size='9'>
                                    {userData?.name &&
                                        <IonLabel>
                                            {userData?.name}<br/>
                                            <small>{userData?.email}</small>
                                        </IonLabel>
                                    }
                                    {userData?.name == null &&
                                        <IonLabel>
                                            {userData?.email}
                                        </IonLabel>
                                    }
                                </IonCol>
                                <IonCol size='1'>
                                    <IonIcon src={arrowForward} style={{"fontSize": "20px", "color": '#888A98'}}/>
                                </IonCol>
                            </IonItem>
                        </MenuContainer>
                        <IonList style={{'paddingRight': '20px', 'paddingTop': '10px'}}>
                            {
                                props.menuItems.filter((value, index) => {
                                    return value.reference != 'app.mobile_menu.profile';
                                }).map((item, key) => {
                                    return <IonItem id={item.reference} key={key} onClick={() => {
                                        props.onClose();
                                        redirect(item);
                                    }}>
                                        <IonCol size='2' style={{'textAlign': 'center'}}>
                                            {getIcon(item)}
                                        </IonCol>

                                        <IonLabel>
                                            {t(item.reference)}
                                        </IonLabel>
                                    </IonItem>
                                })
                            }
                            <IonItem onClick={e => {
                                history.push(Links.main + Links.settings)
                                props.onClose();
                            }}>
                                <IonCol size='2' style={{'textAlign': 'center'}}>
                                    {getSettingsIcon()}
                                </IonCol>
                                <IonLabel>
                                    {t("settingsTab.title")}
                                </IonLabel>
                            </IonItem>
                            <IonItem onClick={e => logoutClick()}>
                                <IonCol size='2' style={{'textAlign': 'center'}}>
                                    <IonIcon src={LogoutImage} style={{"fontSize": "20px", "color": '#888A98'}}/>
                                </IonCol>
                                <IonLabel>
                                    {t("settingsTab.logoff")}
                                </IonLabel>
                            </IonItem>
                        </IonList>
                    </div>
                </StyledModalContent>
            </Modal>
            <WorkerBadgeModal isOpen={showWorkerBadgeModal} onClose={() => updateShowWorkerBadgeModal(false)} />
            <WorkerCoursesInfoModal isOpen={showWorkerCoursesModal} onClose={() => updateShowWorkerCoursesModal(false)} />
        </>
    );
};

export default MenuModal;