import {travelAllowanceApi} from "@services/api.service";

import {RideDocumentType} from "@enums/travelAllowance/rideDocument";

const getRideDocumentPdfByType = async (rideReportId: number, type: RideDocumentType): Promise<any> => {
    const response: any = await travelAllowanceApi.get(`ride-documents/ride-report/${rideReportId}/generate/${type.toLowerCase()}`, {
        responseType: "blob"
    });

    return response.data;
}

export {
    getRideDocumentPdfByType,
};
