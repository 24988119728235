import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";
import moment from 'moment-timezone';
import axios from "axios";
import {IonCol, IonRow} from "@ionic/react";

import {
    StyledAccordion,
    StyledButton,
    StyledButtonOption,
    StyledOptions,
    StyledTravelAllowanceContent
} from '@app/travelAllowance/travelAllowance.style';
import {StyledEmptyContainer} from "@components/content/content.style";

import {ReactComponent as AddIcon} from '@assets/images/travelAllowance/add.svg';
import {ReactComponent as RideIcon} from '@assets/images/travelAllowance/ride.svg';

import {Ride} from "@models/travelAllowance/ride";

import {getRides} from "@services/travelAllowance/ride.service";

import Pane from "@components/pane/pane.component";
import MyDataSkeleton from "@app/myData/components/myDataSkeleton.component";
import RideListRow from "@app/travelAllowance/ride/components/rideListRow.component";
import CircleImage, {ImageTypes} from "@components/circleImage/circleImage.component";

import {Links} from "@app/links";

import {ReactComponent as ArrowLeftIcon} from "@assets/images/travelAllowance/arrow-left.svg"
import {ReactComponent as ArrowRightIcon} from "@assets/images/travelAllowance/arrow-right.svg"
import {RideStatus} from "@enums/travelAllowance/ride";

type RideListPaneProps = {
    topEdge?: number;
}

type SelectedDate = {
    basicFormat: {
        month: string,
        year: string,
    },
    apiFormat: string
}

const RideListPane: React.FC<RideListPaneProps> = ({topEdge}: RideListPaneProps) => {
    const cancelToken = axios.CancelToken.source();

    const {t} = useTranslation();
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [rides, setRides] = useState<Ride[]>([]);
    const [selectedDate, setSelectedDate] = useState<SelectedDate>();
    const [dateCounter, setDateCounter] = useState<number>(0);

    useEffect(() => {
        setSelectedDate(getDate);
    }, [dateCounter]);

    useEffect(() => {
        setLoading(true);

        fetchRides()
            .then(() => {
                setLoading(false);
            })
            .catch((e) => {
                console.error(e);
            });

        return () => {
            cancelToken.cancel();
        };
    }, [selectedDate, history.location.pathname]);

    const fetchRides = async () => {
        if (selectedDate?.apiFormat) {
            const ridesData = await getRides(selectedDate.apiFormat, cancelToken);
            ridesData.sort(sortByWarningStatus);

            setRides(ridesData);
        }
    }

    const getDate = (): SelectedDate => {
        return {
            basicFormat: {
                month: moment().subtract(dateCounter, 'months').format('MMMM').toLowerCase(),
                year: moment().subtract(dateCounter, 'months').format('YYYY'),
            },
            apiFormat: moment().subtract(dateCounter, 'months').format('YYYY-MM-DD'),
        };
    }

    const sortByWarningStatus = (a: Ride, b: Ride) => {
        if (a.status === RideStatus.QUARANTINE && b.status !== RideStatus.QUARANTINE) {
            return -1;
        } else if (a.status !== RideStatus.QUARANTINE && b.status === RideStatus.QUARANTINE) {
            return 1;
        } else {
            if (a.id > b.id) {
                return -1;
            } else if (a.id < b.id) {
                return 1;
            } else {
                return 0;
            }
        }
    }

    return (
        <Pane topEdge={topEdge} marginTop={40} paddingBottom={147}>
            {
                <StyledTravelAllowanceContent>
                    <StyledAccordion>
                        <IonRow className="row">
                            <IonCol style={{padding: 0, margin: 0}}
                                    className="title">
                                {t(`common.month.${selectedDate?.basicFormat.month}`)}&nbsp;{selectedDate?.basicFormat.year}
                            </IonCol>
                            <IonCol style={{display: "flex", justifyContent: "end", gap: "5px"}}>
                                <div className="arrow" onClick={() => setDateCounter(dateCounter + 1)}>
                                    <ArrowLeftIcon/>
                                </div>
                                <div className="arrow" onClick={() => setDateCounter(dateCounter + -1)}>
                                    <ArrowRightIcon/>
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <hr/>
                        </IonRow>
                    </StyledAccordion>
                    <div className="travel-division">
                        <section className="drive-box">
                            {
                                loading && <MyDataSkeleton></MyDataSkeleton>
                            }
                            {
                                !loading && rides.length > 0 && rides.map((ride) => {
                                    return <RideListRow key={ride.id} ride={ride}/>
                                })
                            }
                            {
                                !loading && rides.length === 0 &&
                                <StyledEmptyContainer>
                                    <CircleImage image={ImageTypes.T} color="grey"/>
                                    <h3>{t('travelAllowance.ride.empty')}</h3>
                                </StyledEmptyContainer>
                            }
                        </section>
                        <section className="button-box">
                            <StyledButton onClick={() => history.push(Links.main + Links.travelAllowance.ride.add)}>
                                <div className="btn center">
                                    <span>
                                        <RideIcon/>
                                    </span>
                                    <span>{t('travelAllowance.ride.add')}</span>
                                </div>
                            </StyledButton>
                            <StyledOptions>
                                <StyledButtonOption
                                    onClick={() => history.push(Links.main + Links.travelAllowance.car.add)}>
                                    <div className="btn-option center">
                                        <AddIcon/>
                                        <span>{t('travelAllowance.car.add')}</span>
                                    </div>
                                </StyledButtonOption>
                                <StyledButtonOption
                                    onClick={() => history.push(Links.main + Links.travelAllowance.rideRoute.add)}>
                                    <div className="btn-option center">
                                        <AddIcon/>
                                        <span>{t('travelAllowance.rideRoute.add')}</span>
                                    </div>
                                </StyledButtonOption>
                            </StyledOptions>
                        </section>
                    </div>
                </StyledTravelAllowanceContent>
            }
        </Pane>
    );
};

export default RideListPane;