import {appApi, authApi} from "./api.service";
import * as LoginFormFieldsTypes from '../app/login/form/loginForm.types';
import * as CreateAccountFormFieldsTypes from '../app/createAccount/form/createAccountForm.types';
import {CLIENT_ID, CLIENT_SECRET} from '../variables/variables';
import {AuthResult} from '../types/auth-result';
import {Preferences} from '@capacitor/preferences';
import moment from 'moment';
import {FacebookLoginResponse, FacebookLogin} from '@capacitor-community/facebook-login';
import {isPlatform} from "@ionic/react";
import React, {useContext, useState} from "react";
import {getPlatforms} from "@ionic/react/dist/types/components/utils";
import { registerPlugin } from "@capacitor/core";
import {registerWebPlugin} from "@capacitor/core";
import {FacebookLoginWeb} from "@capacitor-community/facebook-login/dist/esm/web";

registerPlugin<FacebookLoginWeb>('FacebookLoginWeb');

interface UserManager {
    isAuthenticated: boolean
    signIn: Function;
    signOut: Function;
}

const user: UserManager = {
    isAuthenticated: false,
    signIn() {
        user.isAuthenticated = true;
    },
    signOut() {
        user.isAuthenticated = false;
    }
};

export const UserContext = React.createContext<UserManager>(user);

export function useAuth() {
    return useContext(UserContext);
}

export function useProvideAuth() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const signIn = () => {
        user.signIn();
        setIsAuthenticated(true);
    };

    const signOut = () => {
        user.signOut();
        setIsAuthenticated(false);
    };

    return {
        isAuthenticated,
        signIn,
        signOut
    };
}

const storeToken = async (authResult: AuthResult) => {
	await Preferences.set({
        'key': 'access_token',
        'value': authResult.access_token
    });

    await Preferences.set({
        'key': 'refresh_token',
        'value': authResult.refresh_token
    });

    if (authResult.is_fb_user !== undefined) {
        await Preferences.set({
            'key': 'is_fb_user',
            'value': authResult.is_fb_user ? '1' : '0'
        });

        await Preferences.set({
            'key': 'is_already_fb_user',
            'value': authResult.is_fb_user ? '1' : '0'
        });
    }

    let expiresIn = moment().add(authResult.expires_in, 'second');
    await Preferences.set({
        'key': 'expires_in',
        'value': JSON.stringify(expiresIn.valueOf())
    });
}

const getToken = async () => {
    let tokenObject = await Preferences.get({
        'key': 'access_token',
    });

    return tokenObject.value;
}

const isEmployed = async () => {
    let tokenObject = await Preferences.get({
        'key': 'is_employed',
    });

    return tokenObject.value;
}

const termsAccepted = async () => {
    let terms = await Preferences.get({
        'key': 'terms_acceptance',
    });

    return terms.value;
}

const getPlatformName = () => {
    if (isPlatform('mobileweb')) {
        return 'web';
    } else if (isPlatform('ios')) {
        return 'ios';
    } else if (isPlatform('android')) {
        return 'android';
    }
}

const login = async (data: LoginFormFieldsTypes.FormFieldsType) => {
    let model = {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        username: data.email,
        password: data.password,
        grant_type: 'password',
        scope: isPlatform('mobileweb') ? 'web' : 'ep2',
        platform: getPlatformName()
    };

    return authApi.post('oauth/v2/token', model, {
        headers: {
            'No-Loader': true
        }
    });
};

const logoutHook = async () => {
    return appApi.post('user/tracker', {
        type: 'logout',
        scope: isPlatform('mobileweb') ? 'web' : 'ep2',
        platform: getPlatformName()
    })
}

const logout = async () => {
    await Preferences.remove({'key': 'access_token'});
    await Preferences.remove({'key': 'expires_in'});
    await Preferences.remove({'key': 'refresh_token'});
    await Preferences.remove({'key': 'first_name'});
    await Preferences.remove({'key': 'last_name'});
    await Preferences.remove({'key': 'full_name'});
    await Preferences.remove({'key': 'is_employed'});
    await Preferences.remove({'key': 'plant'});
    await Preferences.remove({'key': 'province'});
    await Preferences.remove({'key': 'username'});
    await Preferences.remove({'key': 'avatar_url'});
    await Preferences.remove({'key': 'email'});
    await Preferences.remove({'key': 'phone'});
    await Preferences.remove({'key': 'city'});
    await Preferences.remove({'key': 'languages'});
    await Preferences.remove({'key': 'courses'});
    await Preferences.remove({'key': 'date_of_birth'});
    await Preferences.remove({'key': 'pesel'});
    await Preferences.remove({'key': 'rcp'});
    await Preferences.remove({'key': 'time_availabilities_type'});
    await Preferences.remove({'key': 'employee_number'});
    await Preferences.remove({'key': 'is_fb_user'});
    await Preferences.remove({'key': 'coordinator'});
    await Preferences.remove({'key': 'coordinator_phone'});
    await Preferences.remove({'key': 'terms_acceptance'});
    await Preferences.remove({'key': 'policy_acceptance'});
    await Preferences.remove({'key': 'job_offer_contact_acceptance'});
    await Preferences.remove({'key': 'email_acceptance'});
    await Preferences.remove({'key': 'phone_acceptance'});
    await Preferences.remove({'key': 'worker_to_connect'});
    await Preferences.remove({'key': 'foreign_terms_sign'});
    await Preferences.remove({'key': 'tisax_need_sign'});
    await Preferences.remove({'key': 'is_coordinator'});
    await Preferences.remove({'key': 'height'});
    await Preferences.remove({'key': 'clothing_size'});
    await Preferences.remove({'key': 'shoe_size'});
    await Preferences.remove({'key': 'is_coordinator'});
    await Preferences.remove({'key': 'is_team_leader'});
    await Preferences.remove({'key': 'profile_id'});
    await Preferences.remove({'key': 'loans_available'});
    await Preferences.remove({'key': 'allowed_to_change_password'});
    await Preferences.remove({'key': 'countLastRefreshClicks'});
    await Preferences.remove({'key': 'recruitment_hash'});
    await Preferences.remove({'key': 'recruitment_path_completed'});
    await Preferences.remove({'key': 'contract_form_url'});
    await Preferences.remove({'key': 'contract_form_completed'});
    await Preferences.remove({'key': 'recruitment_path_flow_version'});
    await Preferences.remove({'key': 'recruitment_video_watched'});
    await Preferences.remove({'key': 'facebook_id'});
    await Preferences.remove({'key': 'workers'});
    await Preferences.remove({'key': 'worker_id'});
    await Preferences.remove({'key': 'recruitment_test_completed'});
    await Preferences.remove({'key': 'travel_allowance_enabled'});
    await Preferences.remove({'key': 'read_moodle_info'});
}

// store profile data in storage and fields
const storeProfile = async (result: any, updateSessionSelectedProfile:boolean = false) => {
	try {
		if (result.photos.thumbnail) {
			await Preferences.set({
				'key': 'avatar_url',
				'value': result.photos.thumbnail
			});
		} else {
			//throw 'No avatar from API';
		}
	} catch (e) {
		// await Preferences.set('avatar_url', DEFAULT_AVATAR_PATH);
	}

	await Preferences.set({
        'key': 'first_name',
        'value': result.first_name
    });

    await Preferences.set({
        'key': 'last_name',
        'value': result.last_name
    });

    await Preferences.set({
        'key': 'full_name',
        'value': result.first_name + ' ' + result.last_name
    });

    await Preferences.set({
        'key': 'is_employed',
        'value': result.number ? '1' : '0'
    });

    await Preferences.set({
        'key': 'employee_number',
        'value': result.number
    });

    await Preferences.set({
        'key': 'plant',
        'value': result.plant
    });

    await Preferences.set({
        'key': 'province',
        'value': result.province
    });

    await Preferences.set({
        'key': 'username',
        'value': result.email
    });

    await Preferences.set({
        'key': 'email',
        'value': result.email
    });

    await Preferences.set({
        'key': 'last_login_email',
        'value': result.email
    });

    await Preferences.set({
        'key': 'phone',
        'value': result.phone
    });

	await Preferences.set({
		'key': 'phone_code',
		'value': result.phone_code
	});

    await Preferences.set({
        'key': 'city',
        'value': result.city
    });

    await Preferences.set({
        'key': 'languages',
        'value': JSON.stringify(result.languages)
    });

    await Preferences.set({
        'key': 'date_of_birth',
        'value': result.date_of_birth
    });

   	await Preferences.set({
		'key': 'pesel',
		'value': result.pesel
	});

	await Preferences.set({
		'key': 'courses',
		'value': JSON.stringify(result.courses)
	});

	await Preferences.set({
		'key': 'coordinator',
		'value': result.coordinator
	});

	await Preferences.set({
		'key': 'coordinator_phone',
		'value': result.coordinator_phone
	});

	await Preferences.set({
		'key': 'terms_acceptance',
		'value': result.ep2_terms_acceptance ? '1' : '0'
	});

	await Preferences.set({
		'key': 'is_coordinator',
		'value': result.is_coordinator ? '1' : '0'
	});

    await Preferences.set({
		'key': 'is_team_leader',
		'value': result.is_team_leader ? '1' : '0'
	});

	await Preferences.set({
		'key': 'policy_acceptance',
		'value': result.ep2_policy_acceptance ? '1' : '0'
	});

	await Preferences.set({
		'key': 'job_offer_contact_acceptance',
		'value': result.ep2_job_offer_contact_acceptance ? '1' : '0'
	});

	await Preferences.set({
		'key': 'email_acceptance',
		'value': result.ep2_email_acceptance ? '1' : '0'
	});

	await Preferences.set({
		'key': 'phone_acceptance',
		'value': result.ep2_phone_acceptance ? '1' : '0'
	});

	await Preferences.set({
		'key': 'worker_to_connect',
		'value': result.worker_to_connect ? '1' : '0'
	});

    await Preferences.set({
        'key': 'height',
        'value': result.height
    });

    await Preferences.set({
        'key': 'clothing_size',
        'value': result.clothing_size
    });

    await Preferences.set({
        'key': 'shoe_size',
        'value': result.shoe_size
    });

    if ((result.is_coordinator || result.is_team_leader || result.is_quality) && updateSessionSelectedProfile) {
        await Preferences.set({
            'key': 'profile_id',
            'value': result.profiles[0].id.toString()
        });

        await Preferences.set({
            'key': 'profile_type',
            'value': result.profiles[0].type.toString()
        });

        await Preferences.set({
            'key': 'profile_company_name',
            'value': result.profiles[0].company?.name.toString()
        });
    }

    await Preferences.set({
        'key': 'workers',
        'value': JSON.stringify(result.workers)
    });

    await Preferences.set({
        'key': 'worker_id',
        'value': result.worker_id?.toString()
    });

    await Preferences.set({
        'key': 'loans_available',
        'value': result.loans_available ? '1' : '0'
    });

    await Preferences.set({
        'key': 'allowed_to_change_password',
        'value': result.allowedToChangePassword ? '1' : '0'
    });

    await Preferences.set({
        'key': 'recruitment_hash',
        'value': result.recruitment_hash
    });

    await Preferences.set({
        'key': 'recruitment_path_completed',
        'value': result.recruitment_path_completed ? '1' : '0'
    });

    await Preferences.set({
        'key': 'contract_form_url',
        'value': result.contract_form_url ? result.contract_form_url : null
    });

    await Preferences.set({
        'key': 'contract_form_completed',
        'value': result.contract_form_completed ? '1' : '0'
    });

    await Preferences.set({
        'key': 'facebook_id',
        'value': result.facebook_id ? '1' : '0'
    });

    await Preferences.set({
        'key': 'recruitment_path_flow_version',
        'value': result.recruitment_path_flow_version ? result.recruitment_path_flow_version.toString() : null
    });

    await Preferences.set({
        'key': 'recruitment_video_watched',
        'value': result.recruitment_video_watched ? '1' : '0'
    });

    await Preferences.set({
        'key': 'recruitment_test_completed',
        'value': result.recruitment_test_completed ? '1' : '0'
    });

    await Preferences.set({
        'key': 'read_moodle_info',
        'value': result.read_moodle_info ? '1' : '0'
    });
}

const createAccount = (data: CreateAccountFormFieldsTypes.FormFieldsType, lang: string) => {
    let model = {
        email: data.email,
        plainPassword: {
            first: data.password,
            second: data.password
        },
        ep2TermsAcceptance: data.terms,
        // ep2PolicyAcceptance: data.policy,
        ep2EmailAcceptance: data.mail,
        ep2PhoneAcceptance: data.phone
    };

    return authApi.post('api/v2/register?lang=' + lang, model);
};

const changePassword = (currentPassword: string, newPassword: string) => {
    return appApi.post('user/change-password', {
        user_password: {
            currentPassword: currentPassword,
            plainPassword: {
                first: newPassword,
                second: newPassword
            }
        }
    });
};

const fbConnect = async (fbToken: string, data: any = null) => {
    if (data != null) {
        let model = {
            fbToken: fbToken,
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            ep2TermsAcceptance: data.terms,
            // ep2PolicyAcceptance: data.policy,
            ep2EmailAcceptance: data.mail,
            ep2PhoneAcceptance: data.phone
        };

        return await authApi.post('api/v2/connect/facebook', model);
    } else {
        let model = {
            fbToken: fbToken,
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            ep2TermsAcceptance: true
        };

        return await authApi.post('api/v2/connect/facebook', model);
    }
};

const fbLogout = async () => {
    return await FacebookLogin.logout();
};

const getFbToken = async () => {
    let token = null;

    await FacebookLogin.getCurrentAccessToken().then(function (result) {
        token = result.accessToken?.token;
    }).catch(function (err) {
    });

    if (token === null) {
        const FACEBOOK_PERMISSIONS = ['public_profile', 'email'];
        const result = await FacebookLogin.login({permissions: FACEBOOK_PERMISSIONS});

        if (result && result.accessToken) {
            return result.accessToken.token;
        }
    }

    return token;
}

const resetPassword = (data: any) => {
    return authApi.post('api/v2/resetting/send-email', data)
};

const refreshAccessToken = async () => {

    const refreshToken = await Preferences.get({
        'key': 'refresh_token'
    });

    return authApi.get('oauth/v2/token?client_id=' + CLIENT_ID + '&client_secret=' + CLIENT_SECRET + '&grant_type=refresh_token&refresh_token=' + refreshToken.value);
};

const supportRequest = (username: string, description: string, deviceName?: string, deviceOS?: string, appVersion?: string) => {
    const body = {
        username: username,
        deviceName: deviceName,
        deviceOs: deviceOS,
        content: description,
        version: '[v2] ' + appVersion,
    };

    return authApi.post('api/v2/support-request/send', body);
};

const appVersion = () => {
    return authApi.get('api/v2/mobile-version')
};

export {
    login,
    createAccount,
    fbConnect,
    resetPassword,
    refreshAccessToken,
    storeToken,
    getToken,
    logout,
    getFbToken,
    fbLogout,
    storeProfile,
    isEmployed,
    termsAccepted,
    supportRequest,
    appVersion,
    logoutHook,
    getPlatformName,
    changePassword
};
