import React, { useEffect, useRef, useState } from 'react';
import { IonButtons, IonHeader, IonPage, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { StyledDataTable, StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../../../components/content/content.style';
import { StyledHeaderButton, StyledHeaderButtonImage } from '../../../../components/button/button.style';
import { useHistory, useLocation } from 'react-router-dom';
import ArrowLeftImage from '../../../../assets/images/arrow-left.svg';
import VectorImage from '../../../../assets/images/checkMarkGreen.svg';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../../../../components/form/input/input.style';
import {getCities, updateCourses, updateCity} from "../../../../services/profile.service";
import {updateStorage} from "../../../../utils/tools/storage";
import CheckImage from "../../../../assets/images/check.svg";
import {Keyboard} from "@capacitor/keyboard";
import StaticPane from '../../../../components/pane/static-pane.component';

interface LocationState {
	toastInit: string;
	showToastInit: boolean;
}

interface ICity {
	name: string;
	selected: boolean
}

const CitiesTab: React.FC = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const cityLocation = useLocation<string>();
    const header = useRef<HTMLIonHeaderElement>(null);
    const filterInput = useRef<HTMLInputElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

	const location = useLocation<LocationState>();
	const { toastInit, showToastInit } = location.state || {toastInit: "", showToastInit: false};
	const [ showToast, setShowToast ] = useState(showToastInit);
	const [ toast, setToast ] = useState<any>(toastInit);
	const [ focus, forceFocus ] = useState<boolean>(true);

    let allCities: ICity[] = [];
    let debounceTimeout: number;

	const [selectedCity, selectCity] = useState(cityLocation.state);
	const [cities, updateCities] = useState<ICity[]>([]);

	useIonViewWillEnter(() => { forceFocus(true); })

	const handleBack = () => {
        forceFocus(false);
		history.goBack();
	};

	const handleSave = async () => {
        let city: string = selectedCity;
        forceFocus(false);

		const response = await updateCity(city)
			.then(response => {
				updateStorage('city', city);
				history.replace('/main/profile?optionAvailability=0&profileSelectedOption=2&selectedOption=2&selectedOption=2');
				return true;
			})
			.catch(error => {
				setToast(t("common.serverErrorMsg"));
				setShowToast(true);
				return false;
			});
	};

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const onSearchInputChange = async (keyword:string) => {
    	if (keyword.length >= 3) {

            if (debounceTimeout) {
                clearTimeout(debounceTimeout)
            }

            debounceTimeout = window.setTimeout(async () => {
                const response = await getCities(keyword)
                    .then(response => {
                        response.data.map((city:any) => {
                            allCities.push({
                                name: city.name,
                                selected: city.name === selectedCity
                            });
                        });
                        updateCities(allCities);
                        return true;
                    })
                    .catch(error => {
                        setToast(t("common.serverErrorMsg"));
                        setShowToast(true);
                        return false;
                    });
            }, 350);
		}
	}

	const onCitySelect = (city:ICity) => {
		selectCity(city.name);

		if (filterInput.current) {
			filterInput.current.value = city.name;
			filterInput.current.focus();
		}
	}

    useEffect(() => {
		updateCities(allCities);
        forceFocus(true);
        updateHeight();
    }, []);

    useEffect(() => {
        if (filterInput.current && focus) {
            filterInput.current.focus();
        }
    }, [allCities, focus]);

    useIonViewWillEnter(() => {
        showKeyboard();
    });

    useIonViewWillLeave(() => {
        Keyboard.hide();
    });

    const showKeyboard = () => {
        Keyboard.show();
    };

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton>
                                <StyledHeaderButtonImage src={ArrowLeftImage}
														 onClick={() => handleBack()}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("citiesTab.title")}</StyledIonTitle>
						<IonButtons slot="end">
							<StyledHeaderButton className="save-btn" onClick={() => handleSave()}>
								<StyledHeaderButtonImage src={CheckImage}></StyledHeaderButtonImage>
							</StyledHeaderButton>
						</IonButtons>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} paddingBottom={95}>
                    <SearchInput ref={filterInput} name="searchInputCity" placeholder={t("citiesTab.searchPlaceholder")} onChange={(e) => onSearchInputChange(e.target.value)}></SearchInput>
                    <StyledDataTable className="no-margin">
                    { cities.map((city, i) => <div key={i} className="data-row" onClick={() => onCitySelect(city)}>
                                                        <div className="data-icon-title">{city.name}</div>
                                                        <div className="data-action">
                                                            {city.name === selectedCity && <img src={VectorImage} />}
                                                        </div>
                                                    </div>)
                    }
                    </StyledDataTable>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default CitiesTab;
