import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    IonCol,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    useIonViewDidEnter
} from '@ionic/react';
import { StyledHeaderButton, StyledHeaderButtonImage } from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import AvatarImage from '../../assets/images/avatar.svg';
import PhoneImage from '../../assets/images/phone.svg';
import SendImage from '../../assets/images/send.svg';
import ReadedImage from '../../assets/images/check-double.svg';
import { useForm } from 'react-hook-form';
import { StyledConversationIonContent } from './conversationPage.style';
import Form from '../../components/form';
import { useTranslation } from 'react-i18next';
import { StyledAvatar } from '../mainPage/tabs/notifications/notificationsTab.style';
import { Person } from '../mainPage/tabs/communicator/communicatorTab.component';
import { StyledInput } from '../../components/form/input/input.style';
import { StyledConversationContainer, StyledConversationMessages, StyledNewMessage } from '../../modals/conversation/conversation.style';
import { IConversationModalFormData } from '../../modals/conversation/conversation.component';
import { ContractComment } from '../../models/contractComment';
import nl2br from 'react-nl2br';
import StaticPane from '../../components/pane/static-pane.component';

type Comment = ContractComment & { timeSeparator?: string, readed?: string }

const ConversationPage: React.FC = () => {

    const fieldName = 'message';
    const history = useHistory();
    const { t } = useTranslation();

    const [person, setPerson] = useState<Person | undefined>({
        id: BigInt(1),
        active: false,
        city: '',
        email: '',
        first_name: 'Marta',
        last_name: 'Lempart',
        phone: '',
        preferences: '',
        department: 'Dział księgowy',
        photos: {
            original: '',
            thumbnail: ''
        }
    });

    const [conversation, setConversation] = useState<Comment[] | undefined>([
        {
            is_worker_author: false,
            comment: 'test'
        },
        {
            is_worker_author: false,
            comment: 'test'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: false,
            comment: 'test 2',
            timeSeparator: '13:15'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: false,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: false,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: false,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: false,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: false,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: false,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: false,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: false,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2'
        },
        {
            is_worker_author: true,
            comment: 'test 2',
            readed: '15:15'
        }
    ]);

    const handleBack = () => {
        history.goBack();
    };

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const { register, getValues, handleSubmit, formState, setValue } = useForm({
        mode: 'all'
    });

    useEffect(() => {
        updateHeight();
    });
    
    const onSubmit = (data: IConversationModalFormData) => {
        setValue(fieldName, '');
    };

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current ?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

	return (
        <IonPage>
            <StyledConversationIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <IonGrid className="header">
                        <IonRow>
                            <IonCol>
                                <StyledAvatar>
                                    { person?.photos?.thumbnail ? <img src={person.photos.thumbnail} /> : <img src={AvatarImage} />}
                                </StyledAvatar>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol offset="0.5" size="2">
                                <StyledHeaderButton onClick={() => handleBack()}>
                                    <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                                </StyledHeaderButton>
                            </IonCol>
                            <IonCol size="7">
                                <div className="name">
                                    { person?.first_name } { person?.last_name }
                                </div>
                                <div className="department">
                                    { person?.department }
                                </div>
                            </IonCol>
                            <IonCol size="2">
                                <StyledHeaderButton onClick={() => handleBack()}>
                                    <StyledHeaderButtonImage src={PhoneImage}></StyledHeaderButtonImage>
                                </StyledHeaderButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} paddingBottom={86}>
                    <StyledConversationContainer>
                        <StyledConversationMessages>
                            {conversation?.map((comment: Comment, key) => {
                                return <React.Fragment key={`message_${key}`}>
                                    <div className={comment.is_worker_author ? "message" : "message own"}>
                                        {!comment.is_worker_author &&
                                        <img src={person?.photos.thumbnail || AvatarImage}/>}{nl2br(comment.comment)}
                                    </div>
                                    {
                                        comment.timeSeparator && 
                                        <div className="separator">
                                            <span>{comment.timeSeparator}</span>
                                        </div>
                                    }
                                    {
                                        comment.readed && 
                                        <div className="readed">
                                            <img src={ReadedImage}/>{t("conversation.readed")} {comment.readed}
                                        </div>
                                    }
                                </React.Fragment>
                            })}
                        </StyledConversationMessages>
                    </StyledConversationContainer>
                </StaticPane>
                <Form.Container onSubmit={handleSubmit(onSubmit)}>
                        <StyledNewMessage>
                            <StyledInput placeholder={t("conversation.addNewMessage")}
                                        ref={register({required: true})}
                                        name={fieldName} type="text"/>
                            <button className="add-button" type={"submit"} disabled={!formState.isValid}>
                                <img src={SendImage}/>
                            </button>
                        </StyledNewMessage>
                    </Form.Container>
            </StyledConversationIonContent>
        </IonPage>
    );
};

export default ConversationPage;
