import {IonCol, IonGrid, IonRow, IonSkeletonText, useIonViewWillEnter} from '@ionic/react';
import React, {useContext, useEffect, useState} from 'react';
import {getI18n, useTranslation} from 'react-i18next';
import Pane from '../../../components/pane/pane.component';
import EmployerDetailsActionSheet from '../components/employerDetailsActionSheet/employerDetailsActionSheet.component';
import HoursWorkedetailsActionSheet from '../components/hoursWorkedDetailsActionSheet/hoursWorkedDetailsActionSheet.component';
import { StyledHoursWorkedGrid, StyledHoursWorkedOption } from './hoursWorked.style';
import {UserContext} from "../../../services/auth.service";
import {StyledEmptyContainer} from "../../../components/content/content.style";
import CircleImage, {ImageTypes} from "../../../components/circleImage/circleImage.component";
import StaticPane from "../../../components/pane/static-pane.component";
import {getHoursWorkedData} from "../../../services/worker.service";
import moment from "moment";
import useNavigation from "../../../services/navigation.service";
import { useHistory } from 'react-router-dom';
import {isEmployed} from "../../../services/auth.service";
import {minutesToHoursMinutes} from "../../../utils/tools/time";
import {log} from "../../../services/firebaseAnalytics.service";

type HoursWorkedPaneProps = {
    topEdge?: number;
}

export type MonthHoursWorked = {
    month: string;
    employers: EmployerHoursWorked[];
}

export type EmployerHoursWorked = {
    name: string;
    workingHours: WorkingHour[]
}

export type WorkingHour = {
    date: Date;
    quantity: number;
    rate: number;
}

enum HoursWorkedOption {
	CURRENT_MONTH,
	PREVIOUS_MONTH
}

const HoursWorkedPane: React.FC<HoursWorkedPaneProps> = (props: HoursWorkedPaneProps) => {
    const { t } = useTranslation();
	const i18 = getI18n();
	moment.locale(i18.language);

	const history = useHistory();
	const navigation = useNavigation(history);

	const [showDetails, updateShowDetails] = useState(false);
    const [currentHoursWorked, setCurrentHoursWorked] = useState<MonthHoursWorked>();
    const [hoursWorked, setHoursWorked] = useState<MonthHoursWorked[]>();
	const [option, updateOption] = useState<HoursWorkedOption>(parseInt(navigation.getParam<string>('optionHoursWorked')) || HoursWorkedOption.CURRENT_MONTH);

    const [showEmployerDetails, updateShowEmployerDetails] = useState(false);
	const [currentEmployerHoursWorked, setCurrentEmployerHoursWorked] = useState<EmployerHoursWorked>();

	const [loading, setLoading] = useState<boolean>(false);

	const [employed, setEmployed] = useState<boolean>(false);

	const startDate = (option == HoursWorkedOption.PREVIOUS_MONTH) ? moment().subtract(1, 'months').startOf('month') : moment().startOf('month');
	const endDate = (option == HoursWorkedOption.PREVIOUS_MONTH) ? moment().subtract(1, 'months').endOf('month') : moment().endOf('month');

	const selectHoursWorkedTab = (option: HoursWorkedOption) => {
		navigation.setParam('optionHoursWorked', option.toString());
		updateOption(option);

		if (option === HoursWorkedOption.PREVIOUS_MONTH) {
			let startDate = moment().subtract(1, 'months').startOf('month');
			let endDate = moment().subtract(1, 'months').endOf('month');
			fetchHoursWorkedData(startDate, endDate);
		} else {
			let startDate = moment().startOf('month');
			let endDate = moment().endOf('month');
			fetchHoursWorkedData(startDate, endDate);
		}
	}

	const isEmployee = async() => {
		const response = await isEmployed();
		let result:boolean = response == '1' ? true : false;
		return result;
	}

	const fetchHoursWorkedData = async (startDate: any, endDate: any) => {
		let isEmployed:boolean = await isEmployee();
		setEmployed(isEmployed);

		if (isEmployed == true) {
			setLoading(true);
			await getHoursWorkedData(startDate.format('DD-MM-YYYY'), endDate.format('DD-MM-YYYY'))
				.then(response => {
					let data = response.data;

					if (data) {

						// HOURS
						let employers = Array();
						data.hours.forEach((hours:any) => {
							let el : { [id: string]: any; } = {};
							el['name'] = hours.pos;
							el['workingHours'] = Array();

							hours.hours.forEach((hour:any) => {
								el['workingHours'].push({
									date: hour['date'],
									rate: hour['rate'],
									quantity: hour['quantity']
								});
							});

							employers.push(el);
						});

						let monthName = moment(startDate).locale(i18.language).format('MMMM');
						const hoursWorked: MonthHoursWorked[] = [
							{
								month: monthName.charAt(0).toUpperCase() + monthName.substr(1) + ' ' + startDate.format('YYYY'),
								employers: employers, //[]
							}
						];

						setHoursWorked(hoursWorked);
					}
				});
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchHoursWorkedData(startDate, endDate);
	}, []);

	useIonViewWillEnter(() => {
		fetchHoursWorkedData(startDate, endDate);
	});

    const getEmployerTotalHours = (employerHoursWorked: EmployerHoursWorked): number => {
        const sum = employerHoursWorked.workingHours.reduce((prev: number, curr: WorkingHour) => {
            return prev + curr.quantity;
        }, 0);

        return sum;
    };

    const getEmployerTotal = (employerHoursWorked: EmployerHoursWorked): number => {
        const sum = employerHoursWorked.workingHours.reduce((prev: number, curr: WorkingHour) => {
            return prev + (curr.quantity * curr.rate);
        }, 0);

        return sum;
    };

    const getMonthTotalHours = (monthHoursWorked: MonthHoursWorked): number => {
        const sum = monthHoursWorked.employers.reduce((prev: number, curr: EmployerHoursWorked) => {
            return prev + getEmployerTotalHours(curr);
        }, 0);

        return sum;
    };

    const getMonthTotal = (monthHoursWorked: MonthHoursWorked): number => {
        const sum = monthHoursWorked.employers.reduce((prev: number, curr: EmployerHoursWorked) => {
            return prev + getEmployerTotal(curr);
        }, 0);

        return sum;
    };

	useEffect(() => {
		log('page_visit', {
			page: 'Raporty -> Godziny'
		});
	}, []);

    return employed == false ? (
			<StaticPane topEdge={props.topEdge} marginTop={40} paddingBottom={97}>
				<StyledEmptyContainer>
					<CircleImage image={ImageTypes.NOTE} color="grey"/>
					<h3>{t('reportsTab.hoursWorked.soonAvailableNotWorker')}</h3>
				</StyledEmptyContainer>
			</StaticPane>
		) : (
			<Pane topEdge={props.topEdge} marginTop={40} paddingBottom={147}>
				<StyledHoursWorkedOption>
					<div onClick={() => selectHoursWorkedTab(HoursWorkedOption.PREVIOUS_MONTH)}
						 className={option === HoursWorkedOption.PREVIOUS_MONTH ? 'selected' : ''}>{t('reportsTab.hoursWorked.previousMonth')}</div>
					<div onClick={() => selectHoursWorkedTab(HoursWorkedOption.CURRENT_MONTH)}
						 className={option === HoursWorkedOption.CURRENT_MONTH ? 'selected' : ''}>{t('reportsTab.hoursWorked.currentMonth')}</div>
				</StyledHoursWorkedOption>
				{
					!loading && hoursWorked && hoursWorked.length > 0 &&
					hoursWorked.map((monthHoursWorked, key) =>
						<StyledHoursWorkedGrid key={key} onClick={() => {
							setCurrentHoursWorked(monthHoursWorked);
							updateShowDetails(true);
						}} className="hours-worked">
							<div className="title">{monthHoursWorked.month}</div>
							<IonGrid>
								<IonRow>
									<IonCol className="aligment header" size="8">
										{t("reportsTab.hoursWorked.workingPlace")}
									</IonCol>
									<IonCol className="aligment end header" size="4">
										{t("reportsTab.hoursWorked.workingHoursCount")}
									</IonCol>
								</IonRow>

								{monthHoursWorked.employers && monthHoursWorked.employers.length > 0 &&
								monthHoursWorked.employers.map((employerHoursWorked, employerKey) =>
									<IonRow key={employerKey}
											onClick={(e) => {
												setCurrentEmployerHoursWorked(employerHoursWorked);
												updateShowEmployerDetails(true);
												e.stopPropagation();
											}}>
										<IonCol className="aligment row" size="8">
											{employerHoursWorked.name}
										</IonCol>
										<IonCol className="aligment end row value" size="4">
											{minutesToHoursMinutes(getEmployerTotalHours(employerHoursWorked) * 60)}
										</IonCol>
									</IonRow>
								)
								}

								{monthHoursWorked.employers && monthHoursWorked.employers.length == 0 &&
								<IonRow>
									<IonCol className="aligment row" size="12">
										{t("reportsTab.hoursWorked.noHours")}
									</IonCol>
								</IonRow>
								}

								{monthHoursWorked.employers && monthHoursWorked.employers.length > 0 &&
								<IonRow className="footer">
									<IonCol className="aligment footer" size="8">
										{t("reportsTab.hoursWorked.sum")}
									</IonCol>
									<IonCol className="aligment end footer" size="4">
										{minutesToHoursMinutes(getMonthTotalHours(monthHoursWorked) * 60)}
									</IonCol>
								</IonRow>
								}
							</IonGrid>
						</StyledHoursWorkedGrid>
					)
				}

				{
					loading && <StyledHoursWorkedGrid>
						<div className="title">
							<IonSkeletonText animated style={{width: (Math.random() >= 0.5) ? '65%' : '55%'}}/>
						</div>
						<IonGrid>
							<IonRow>
								<IonCol className="aligment header" size="5.5">
									<IonSkeletonText animated style={{width: '100%'}}/>
								</IonCol>
								<IonCol className="aligment end  header" size="3">
									<IonSkeletonText animated style={{width: '60%'}}/>
								</IonCol>
								<IonCol className="aligment end header" size="3.5">
									<IonSkeletonText animated style={{width: '60%'}}/>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol className="aligment row" size="5.5">
									<IonSkeletonText animated style={{width: (Math.random() >= 0.5) ? '85%' : '65%'}}/>
								</IonCol>
								<IonCol className="aligment end row value" size="3">
									<IonSkeletonText animated style={{width: '50%'}}/>
								</IonCol>
								<IonCol className="aligment end row value" size="3.5">
									<IonSkeletonText animated style={{width: '50%'}}/>
								</IonCol>
							</IonRow>
						</IonGrid>
					</StyledHoursWorkedGrid>
				}

				<HoursWorkedetailsActionSheet isVisible={showDetails} onDismiss={() => updateShowDetails(false)}
										  hoursWorked={currentHoursWorked}></HoursWorkedetailsActionSheet>
				<EmployerDetailsActionSheet isVisible={showEmployerDetails}
											onDismiss={() => updateShowEmployerDetails(false)}
											hoursWorked={currentEmployerHoursWorked}></EmployerDetailsActionSheet>
			</Pane>
		);
};

export default HoursWorkedPane;
