import React, { useEffect, useRef, useState } from 'react';
import { IonButtons, IonHeader, IonPage } from '@ionic/react';
import { StyledDataTable, StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../components/content/content.style';
import Pane from "../../components/pane/pane.component";
import { StyledHeaderButton, StyledHeaderButtonImage} from "../../components/button/button.style";
import { useHistory } from 'react-router-dom';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import VectorImage from '../../assets/images/checkMarkGreen.svg';
import { useTranslation } from 'react-i18next';
import CheckImage from "../../assets/images/check.svg";
import {Preferences} from "@capacitor/preferences";
import {updateCourses} from "../../services/profile.service";
import {useLocation} from "react-router-dom";
import {updateStorage} from "../../utils/tools/storage";
import { StyledInput } from '../../components/form/input/input.style';
import Form from '../../components/form';
import { useForm } from 'react-hook-form';
import StaticPane from "../../components/pane/static-pane.component";

interface ICourses {
    name: string;
    key: string;
    selected: boolean,
	hasDetails: boolean,
	detailsTitle: ''
};

interface ILevel {
	name: string;
	value: string;
};

interface LocationState {
	toastInit: string;
	showToastInit: boolean;
}

// TODO: possible merge languageTab with appModeTab into select optionTab ??
const CoursesPage: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

	const { handleSubmit, register } = useForm();
	const location = useLocation<LocationState>();
	const { toastInit, showToastInit } = location.state || {toastInit: "", showToastInit: false};
	const [ showToast, setShowToast ] = useState(showToastInit);
	const [ toast, setToast ] = useState<any>(toastInit);

	const handleBack = () => {
		history.goBack();
	};

	const handleSave = async () => {
		let courses: string[] = [];
		let details: string[] = [];

		for (const [key, value] of Object.entries(selectedCourses)) {
			courses.push(key);
			details.push(value as string);
		}

		const response = await updateCourses(courses, details)
			.then(response => {
				updateStorage('courses', JSON.stringify(selectedCourses));
				history.goBack();

				return true;
			})
			.catch(error => {
				setToast(t("common.serverErrorMsg"));
				setShowToast(true);
				return false;
			});
	};

	const handleCheck = (e:any, key:string) => {
		if (selectedCourses.hasOwnProperty(key)) {
			delete selectedCourses[key];
		} else {
			selectedCourses[key] = '';
		}

		setSelectedCourses({...selectedCourses});
	}

	const handleSelectCourseLevel = (e:any, key:string, level:string) => {
		selectedCourses[key] = level;
		setSelectedCourses({...selectedCourses});
		e.stopPropagation();
	}

	const [coursesData, setCoursesData] = useState({});
	const [selectedCourses, setSelectedCourses] = useState<any>({});

	const getCoursesData = async () => {
		let courses:any = await Preferences.get({'key': 'courses'});
		let coursesObject = JSON.parse(courses.value);
		setCoursesData(coursesObject);

		let selectedCoursesValues : { [id: string]: string; } = {};
		let coursesValues:any = Object.entries(coursesObject);
		coursesValues.forEach((course:any) => {
			selectedCoursesValues[course[0]] = course[1];
		});

		setSelectedCourses(selectedCoursesValues);
	};

	const courses: ICourses[] = [{
        name: t('coursesPage.courses.certyfikat14001'),
		key: 'certyfikat_14001',
        selected: selectedCourses.hasOwnProperty('certyfikat_14001') ? true : false,
		hasDetails: false,
		detailsTitle: ''
    },{
        name: t('coursesPage.courses.certyfikat18801'),
		key: 'certyfikat_18801',
        selected: selectedCourses.hasOwnProperty('certyfikat_18801') ? true : false,
		hasDetails: false,
		detailsTitle: ''
	},{
        name: t('coursesPage.courses.certyfikatSpawalniczy'),
		key: 'certyfikat_spawalniczy',
        selected: selectedCourses.hasOwnProperty('certyfikat_spawalniczy') ? true : false,
		hasDetails: true,
		detailsTitle: t('coursesPage.courses.certyfikatSpawalniczyDetails')
	},{
        name: t('coursesPage.courses.certyfikatWozkowWidlowych'),
		key: 'certyfikat_wozkow_widlowych',
        selected: selectedCourses.hasOwnProperty('certyfikat_wozkow_widlowych') ? true : false,
		hasDetails: false,
		detailsTitle: ''
	},{
        name: t('coursesPage.courses.fmea'),
		key: 'fmea',
        selected: selectedCourses.hasOwnProperty('fmea') ? true : false,
		hasDetails: false,
		detailsTitle: ''
	},{
        name: t('coursesPage.courses.ksiazeczkaZdrowia'),
		key: 'ksiazeczka_zdrowia',
        selected: selectedCourses.hasOwnProperty('ksiazeczka_zdrowia') ? true : false,
		hasDetails: false,
		detailsTitle: ''
	},{
        name: t('coursesPage.courses.kurs9001'),
		key: 'kurs_9001',
        selected: selectedCourses.hasOwnProperty('kurs_9001') ? true : false,
		hasDetails: false,
		detailsTitle: ''
	},{
        name: t('coursesPage.courses.msoffice'),
		key: 'msoffice',
        selected: selectedCourses.hasOwnProperty('msoffice') ? true : false,
		hasDetails: false,
		detailsTitle: ''
	},{
        name: t('coursesPage.courses.prawoJazdyKatb'),
		key: 'prawo_jazdy_katb',
        selected: selectedCourses.hasOwnProperty('prawo_jazdy_katb') ? true : false,
		hasDetails: false,
		detailsTitle: ''
	},{
        name: t('coursesPage.courses.sixSigma'),
		key: 'six_sigma',
        selected: selectedCourses.hasOwnProperty('six_sigma') ? true : false,
		hasDetails: false,
		detailsTitle: ''
	},{
		name: t('coursesPage.courses.stopienInzyniera'),
		key: 'stopien_inzyniera',
		selected: selectedCourses.hasOwnProperty('stopien_inzyniera') ? true : false,
		hasDetails: true,
		detailsTitle: t('coursesPage.courses.stopienInzynieraDetails')
	},{
		name: t('coursesPage.courses.swiadectwoKwalifikacjiDozorPonizej1kvSepd'),
		key: 'swiadectwo_kwalifikacji_dozor_ponizej_1kv_sepd',
		selected: selectedCourses.hasOwnProperty('swiadectwo_kwalifikacji_dozor_ponizej_1kv_sepd') ? true : false,
		hasDetails: false,
		detailsTitle: ''
	},{
		name: t('coursesPage.courses.swiadectwoKwalifikacjiDozorPowyzej1kvSepd'),
		key: 'swiadectwo_kwalifikacji_dozor_powyzej_1kv_sepd',
		selected: selectedCourses.hasOwnProperty('swiadectwo_kwalifikacji_dozor_powyzej_1kv_sepd') ? true : false,
		hasDetails: false,
		detailsTitle: ''
	},{
		name: t('coursesPage.courses.swiadectwoKwalifikacjiEksploatacjaPonizej1kvSepe'),
		key: 'swiadectwo_kwalifikacji_eksploatacja_ponizej_1kv_sepe',
		selected: selectedCourses.hasOwnProperty('swiadectwo_kwalifikacji_eksploatacja_ponizej_1kv_sepe') ? true : false,
		hasDetails: false,
		detailsTitle: ''
	},{
		name: t('coursesPage.courses.swiadectwoKwalifikacjiEksploatacjaPowyzej1kvSepe'),
		key: 'swiadectwo_kwalifikacji_eksploatacja_powyzej_1kv_sepe',
		selected: selectedCourses.hasOwnProperty('swiadectwo_kwalifikacji_eksploatacja_powyzej_1kv_sepe') ? true : false,
		hasDetails: false,
		detailsTitle: ''
	},{
		name: t('coursesPage.courses.kursPierwszejPomocy'),
		key: 'kurs_pierwszej_pomocy',
		selected: selectedCourses.hasOwnProperty('kurs_pierwszej_pomocy') ? true : false,
		hasDetails: false,
		detailsTitle: ''
	},{
		name: t('coursesPage.courses.uprawnieniaDoBadanNdt'),
		key: 'uprawnienia_do_badan_ndt',
		selected: selectedCourses.hasOwnProperty('uprawnienia_do_badan_ndt') ? true : false,
		hasDetails: false,
		detailsTitle: ''
	},{
		name: t('coursesPage.courses.wyksztalcenieBhp'),
		key: 'wyksztalcenie_bhp',
		selected: selectedCourses.hasOwnProperty('wyksztalcenie_bhp') ? true : false,
		hasDetails: false,
		detailsTitle: ''
	},{
		name: t('coursesPage.courses.zaswiadczenieInspektoraPpoz'),
		key: 'zaswiadczenie_inspektora_ppoz',
		selected: selectedCourses.hasOwnProperty('zaswiadczenie_inspektora_ppoz') ? true : false,
		hasDetails: false,
		detailsTitle: ''
	},{
		name: t('coursesPage.courses.zaswiadczenieKwalifikacyjneUdt'),
		key: 'zaswiadczenie_kwalifikacyjne_udt',
		selected: selectedCourses.hasOwnProperty('zaswiadczenie_kwalifikacyjne_udt') ? true : false,
		hasDetails: true,
		detailsTitle: t('coursesPage.courses.zaswiadczenieKwalifikacyjneUdtDetails')
	},{
		name: t('coursesPage.courses.inne'),
		key: 'inne',
		selected: selectedCourses.hasOwnProperty('inne') ? true : false,
		hasDetails: true,
		detailsTitle: t('coursesPage.courses.inneDetails')
	}];

    useEffect(() => {
		getCoursesData();
        updateHeight();
	}, []);

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton>
                                <StyledHeaderButtonImage src={ArrowLeftImage}
                                                         onClick={() => handleBack()}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("coursesPage.title")}</StyledIonTitle>
						<IonButtons slot="end">
							<StyledHeaderButton className="save-btn" onClick={() => handleSave()}>
								<StyledHeaderButtonImage src={CheckImage}></StyledHeaderButtonImage>
							</StyledHeaderButton>
						</IonButtons>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40}>
					<Form.Container onSubmit={handleSubmit(handleSave)}>
						<StyledDataTable className="no-margin">
						{ courses.map((data, i) => <div key={i} className="data-row" onClick={(e) => handleCheck(e, data.key)}>
															<div className="data-icon-title min-height">{data.name}</div>
															<div className="data-action">
																{selectedCourses.hasOwnProperty(data.key) && <img src={VectorImage} />}
															</div>
															{selectedCourses[data.key] !== undefined && data.hasDetails &&
																<StyledInput placeholder={data.detailsTitle}
																	onClick={e => e.stopPropagation()}
																	className="data-details extended bolded"
																	ref={register()}
																	name={`${data.key}_${i}`}
																	id={`${data.key}_${i}`}
																	value={selectedCourses[data.key]}
																	onChange={e => { handleSelectCourseLevel(e, data.key, e.target.value); }}>
																</StyledInput>
															}
														</div>)
						}
						</StyledDataTable>
					</Form.Container>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default CoursesPage;
