import React, {useContext, useEffect, useRef, useState} from 'react';
import {IonButtons, IonHeader, IonPage} from '@ionic/react';
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '../../../../components/content/content.style';
import {useTranslation} from 'react-i18next';
import SubNavigation, {SubNavigationOption} from '../../../../components/sub-navigation/subNavigation.component';
import {useHistory, useLocation} from 'react-router-dom';
import useNavigation from '../../../../services/navigation.service';
import {ReportStatus} from "../../../../enums/reportStatus";
import {
    StyledButtonBadge,
    StyledHeaderButton,
    StyledHeaderButtonImage
} from "../../../../components/button/button.style";
import FilterActionSheet, {
    Filter,
    FilterParam,
    FilterType,
    ReportsFilterActionSheetContext
} from "../../../reportPage/components/filter/filterActionSheet.component";
import {Profile} from "../../../../models/profile";
import {Preferences} from "@capacitor/preferences";
import {filtersToParamsObjects, getFilterTypeForParamName} from "../../../../services/report.service";
import PreferencesImage from "../../../../assets/images/system-preferences.svg";
import ReportsPane from "./panes/reportsPane.component";
import RideReportsPane from "@app/travelAllowance/rideReport/panes/rideReportsPane.component";
import {isTravelAllowanceEnabled} from "@services/travelAllowance/travelAllowance";

let timeout: number;

export enum ReportsTabOptions {
    DRAFT,
    NOT_VERIFIED,
    OPEN,
    RIDES,
}

export interface IReportsTabState {
    activeTab: ReportsTabOptions;
}

const ReportTab: React.FC = () => {
    const {t} = useTranslation();
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    const history = useHistory();
    const navigation = useNavigation(history);

    const [isCoordinator, setIsCoordinator] = useState<any>();
    const [selectedProfile, updateSelectedProfile] = useState<Profile | undefined>();
    const [options, setOptions] = useState<SubNavigationOption[]>([
        {
            name: t("reportsTab.draft")
        },
        {
            name: t("reportsTab.not_verified")
        },
        {
            name: t("reportsTab.open")
        },
    ]);

    const filterActionSheet = useContext(ReportsFilterActionSheetContext);

    const filtersName = [
        'orderNumber',
        'componentName',
        'componentNumber',
        'placeOfService',
        'placeOfServiceDepartment'
    ];

    const getProfileData = async () => {

        let isCoordinator = await Preferences.get({'key': 'is_coordinator'});
        setIsCoordinator(isCoordinator.value);

        let profileId = await Preferences.get({'key': 'profile_id'});
        let profileType = await Preferences.get({'key': 'profile_type'});
        let profileCompanyName = await Preferences.get({'key': 'profile_company_name'});

        if (profileId.value !== null && profileType.value !== null && profileCompanyName.value !== null) {
            updateSelectedProfile({
                id: parseInt(profileId.value),
                type: profileType.value,
                company: {
                    name: profileCompanyName.value
                }
            })
        }
    };

    useEffect(() => {
        getProfileData();
        navigationToFilters();
        getAdditionalOptions();
    }, []);

    const [selectedOption, updateSelectedOption] = useState<number>(parseInt(navigation.getParam<string>('selectedOption')) || ReportsTabOptions.DRAFT);
    const handleSelect = (option: SubNavigationOption) => {
        const optionIndex = options.indexOf(option);
        if (optionIndex === selectedOption) {
            return;
        }

        navigation.setParam('selectedOption', optionIndex.toString());
        updateSelectedOption(optionIndex);
        updateFilters([]);
        handleFiltersNavigation([]);
    };

    useEffect(() => {
        updateHeight();

        return () => {
            clearTimeout(timeout);
        };
    });

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            timeout = setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

    const [filters, updateFilters] = useState<Filter[]>([]);
    const [showFilters, updateShowFilters] = useState(false);

    const navigationToFilters = () => {
        const filters: Filter[] = [];

        filtersName.forEach(value => {
            if (navigation.getParam(value)) {
                let type = getFilterTypeForParamName(value);
                if (type !== undefined) {
                    filters.push({
                        type: type,
                        description: navigation.getParam(value),
                        value: type === (FilterType.PLACE_OF_SERVICE || type === FilterType.PLACE_OF_SERVICE_DEPARTMENT) ? parseInt(navigation.getParam(value)) : navigation.getParam(value)
                    });
                }
            }
        });

        updateFilters(filters);
    }

    const handleFiltersNavigation = async (filters: Filter[]) => {
        let params: FilterParam[] = filtersToParamsObjects(filters);
        filtersName.forEach(value => navigation.setParam(value, ''));

        params.forEach((value, index) => {
            navigation.setParam(value.name, value.value);
        })
    }

    const handleSelectFilter = async (filters: Filter[]) => {
        updateFilters(filters);
        handleFiltersNavigation(filters)
        updateShowFilters(false);
    }

    const handleClearFilter = (filter: Filter) => {
        const index = filters.indexOf(filter);

        if (index !== -1) {
            filters.splice(index, 1);
            updateFilters([...filters]);
        }

        handleSelectFilter(filters);
        handleFiltersNavigation(filters);

        if (filter.type === FilterType.ORDER_NUMBER) {
            filterActionSheet.updateSelectedOrderNumber('');
        }

        if (filter.type === FilterType.COMPONENT_NUMBER) {
            filterActionSheet.updateSelectedComponentNumber(undefined);
        }

        if (filter.type === FilterType.COMPONENT_NAME) {
            filterActionSheet.updateSelectedComponentName(undefined);
        }

        if (filter.type === FilterType.PLACE_OF_SERVICE) {
            filterActionSheet.updateSelectedPlaceOfServiceObject(undefined);
        }

        if (filter.type === FilterType.PLACE_OF_SERVICE_DEPARTMENT) {
            filterActionSheet.updateSelectedPlaceOfServiceDepartmentObject(undefined);
        }
    }

    const getAdditionalOptions = async () => {
        const showRidesTab = await isTravelAllowanceEnabled();

        if (showRidesTab) {
            setOptions(options => [...options, {name: t("reportsTab.rides")}]);
        }
    }

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <StyledIonTitle className="left">{t("reportsTab.title")}</StyledIonTitle>

                        {isCoordinator === '1' && <IonButtons slot="end">
                            <StyledHeaderButton id="reports-filters">
                                <StyledHeaderButtonImage src={PreferencesImage} onClick={() => updateShowFilters(true)}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        }

                    </StyledIonToolbar>
                    {isCoordinator === '1' && filters && filters.length > 0 &&
                        <StyledButtonBadge className="header-right-badge">{filters.length}</StyledButtonBadge>}
                    {/*{ isCoordinator === '1' && <FilterChips filters={filters} onFilterClear={handleClearFilter}/> }*/}
                    <SubNavigation options={options} defaultValue={selectedOption || ReportsTabOptions.DRAFT} onSelect={handleSelect}/>
                </IonHeader>
                {
                    selectedOption === ReportsTabOptions.DRAFT &&
                    <ReportsPane status={ReportStatus.DRAFT} topEdge={topEdge} filters={filters} filtersName={filtersName} profile={selectedProfile}/>
                }
                {
                    selectedOption === ReportsTabOptions.NOT_VERIFIED &&
                    <ReportsPane status={ReportStatus.NOT_VERIFIED} topEdge={topEdge} filters={filters} filtersName={filtersName} profile={selectedProfile}/>
                }
                {
                    selectedOption === ReportsTabOptions.OPEN &&
                    <ReportsPane status={ReportStatus.OPEN} topEdge={topEdge} filters={filters} filtersName={filtersName} profile={selectedProfile}/>
                }
                {
                    selectedOption === ReportsTabOptions.RIDES &&
                    <RideReportsPane topEdge={topEdge}/>
                }
            </StyledIonContent>
            {isCoordinator === '1' &&
                <FilterActionSheet status={selectedOption} isVisible={showFilters} filters={filters} onDismiss={() => updateShowFilters(false)}
                                   onSave={(filters) => handleSelectFilter(filters)}/>}
        </IonPage>
    );
};

export default ReportTab;
