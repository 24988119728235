import styled from "styled-components";

export const StyledNotification = styled.div`
    border-bottom: 1px solid #F6F6F8;
    padding: 12px 0;

    display: flex;
    flex-direction: row;

    position: relative;

    &.center {
        align-items: center;
    }

    .content {

        margin-left: 16px;
        width: 100%;
        max-width: calc(100% - 76px);
        text-align: left;

        .title {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
    
            color: #000000;
        }
    
        .description {
            padding-top: 6px;
            font-size: 12px;
            line-height: 140%;
    
            color: #888A98;

            &.padding-right {
                width: calc(100% - 32px);
            }
        }
    }

    button.action-button {
        min-height: 26px;
        background: #0085FF;
        border-radius: 32px;

        padding: 5px 12px;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;

        color: #FFFFFF;

        float: right;
    }

    .time {
        padding: 0 12px;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;

        #888A98;

        float: right;
    }
`;

export const StyledAvatar = styled.div`
    position: relative;
    display: inline-block;
    min-width: 56px;
    min-height: 56px;
    border-radius: 28px;

    &.active::before {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        left: 40px;
        top: 40px;
        z-index: 1;
        border-radius: 10px;

        background: #7FDE35;
        border: 2px solid #FFFFFF;
    }

    &.active.dark::before {
        border-color: #262532;
    }

    img {
        width: 56px;
        height: 56px;
        border-radius: 28px;
    }
`;

export const StyledCategory = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .category-name {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }

    .category-place {
        font-weight: normal;
        font-size: 12px;
        line-height: 140%;
        color: #888A98;
        margin-top: 6px;
        margin-bottom: -6px;

        img {
            height: 12px;
            margin-right: 8px;
        }
    }
`;