import React, {useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
    IonAlert,
    IonButtons,
    IonCol,
    IonGrid,
    IonHeader,
    IonPage, IonRow, isPlatform,
    useIonViewWillEnter,
    useIonViewWillLeave
} from '@ionic/react';
import {
    StyledEmptyContainer, StyledIonContent,
    StyledIonContentCameraPreview,
    StyledIonTitle,
    StyledIonToolbar
} from '../../components/content/content.style';
import {StyledButton, StyledHeaderButton, StyledHeaderButtonImage} from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import CameraPreviewImage from '../../assets/images/takePhoto-small.png';
import {useTranslation} from 'react-i18next';
import {BarcodeScanner, SupportedFormat} from "@capacitor-community/barcode-scanner"
import {StyledContent} from '../takePhotoPage/takePhotoPage.style';
import CircleImage, {ImageTypes} from "../../components/circleImage/circleImage.component";
import {Links} from "../links";
import {findOrder, findSpecification, getPlaceOfServices} from "../../services/audit.service";
import {AuditReportContext, AuditReportProps} from "./auditReport.component";
import {AuditType} from "../../enums/audit";
import {AuditReportFormModel} from "../../models/auditReport";
import {DataSelectable} from "../../models/common";
import PaginatedListModal from "./modals/paginatedListModal.component";
import CheckImage from "../../assets/images/check.svg";
import StaticPane from "../../components/pane/static-pane.component";
import Form from '../../components/form';
import {StyledInput} from "../../components/form/input/input.style";
import {useForm} from "react-hook-form";
import ArrowDownImage from "../../assets/images/down-arrow.svg";
import CrossImage from "../../assets/images/e-remove.svg";

const AuditReportStart: React.FC<AuditReportProps> = ({match}) => {

    const {t} = useTranslation();
    const history = useHistory();

    const {params: {type}} = match;

    const handleBack = () => {
        history.goBack();
    };

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [showError, setShowError] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);
    const [showPlaceOfServiceModal, updateShowPlaceOfServicesModal] = useState<boolean>(false);
    const [selectedPlaceOfService, setSelectedPlaceOfService] = useState<DataSelectable>();

    const contextAuditReport = useContext(AuditReportContext);
    contextAuditReport.setPlaceOfService = setSelectedPlaceOfService;
    contextAuditReport.getPlaceOfService = () => {
        return selectedPlaceOfService;
    };

    const { formState: {isSubmitted}, handleSubmit } = useForm({
        mode: 'all'
    });

    useEffect(() => {
        updateHeight();
    })

    const startScan = async () => {
        BarcodeScanner.hideBackground(); // make background of WebView transparent

        const result = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.QR_CODE] }); // start scanning and wait for a result

        if (result.hasContent) {
            let isOrder = result.content?.includes('order_');
            let error = await handleScan(result.content?.replace('order_', ''), isOrder);
            if (typeof error == 'string') {
                if (error == 'SPECIFICATION_NOT_FOUND') {
                    setError(t('auditReport.find.error.specification_not_found'))
                } else if (error == 'ORDER_NOT_FOUND') {
                    setError(t('auditReport.find.error.order_not_found'))
                } else {
                    setError(t('auditReport.find.error.common'))
                }

                stopScan()
                setShowError(true);
            }
        }
    };

    const handleScan = async (val: any, isOrder: boolean = false) => {
        if (isOrder) {
            let data:any = await findOrder(val)
                .then(response => {
                    if (response.status === 200) {
                        return response.data.id as bigint;
                    } else {
                        return 'COMMON';
                    }
                }).catch(reason => {
                    try {
                        let message = reason.response.data?.message;
                        return message !== undefined ? message : 'COMMON';
                    } catch (e) {
                        return 'COMMON';
                    }
                });

            if (typeof data == 'string') {
                return data;
            }

            if (data) {
                history.push(`${Links.audit}/${type}/${data}/0`);
            }
        } else {
            let data:any = await findSpecification(val)
                .then(response => {
                    if (response.status === 200) {
                        return response.data.order.id;
                    } else {
                        return 'COMMON';
                    }
                }).catch(reason => {
                    try {
                        let message = reason.response.data?.message;
                        return message !== undefined ? message : 'COMMON';
                    } catch (e) {
                        return 'COMMON';
                    }
                });

            if (typeof data == 'string') {
                return data;
            }

            if (data) {
                history.push(`${Links.audit}/${type}/${data}/${val}`);
            }
        }
    }

    const checkPermission = async () => {
        // check or request permission
        const status = await BarcodeScanner.checkPermission({force: false});

        if (status.granted) {
            // the user granted permission
            return true;
        }

        if (status.denied) {
            // user denied permission
            return false;
        }

        if (status.restricted || status.unknown) {
            // ios only
            // probably means the permission has been denied
            return false;
        }

        if (status.neverAsked) {
            const statusRequest = await BarcodeScanner.checkPermission({force: true});
            if (statusRequest.granted) {
                // the user did grant the permission now
                return true;
            }
        }

        return false;
    }

    const start = async () => {
        let hasPermission = await checkPermission();
        if (!hasPermission) {
            setShowPermissionAlert(true);
        }
        startScan();
        setShowError(false);
        setError('');
    }

    useIonViewWillEnter( () => {
        if (type === AuditType.QUALITY && (isPlatform('ios') || isPlatform('android'))) {
            start();
        }
    });

    const stopScan = () => {
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
    };

    useIonViewWillLeave(() => {
        if (type === AuditType.QUALITY && (isPlatform('ios') || isPlatform('android'))) {
            stopScan();
        }
    });

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

    const loadPlaceOfServices = async (page: number, searchText?: string) => {
        let data:DataSelectable[] = await getPlaceOfServices(page, searchText).then(response => {
            return response.data as DataSelectable[];
        });

        return data;
    }

    const handleBhpSave = async () => {
        history.push(`${Links.audit}/${type}/0/0`);
    }

    return (
        <IonPage>
            {type === AuditType.QUALITY &&
                <StyledIonContentCameraPreview>
                    <img className="background" src={CameraPreviewImage}/>
                    <IonHeader ref={header} className="ion-no-border">
                        <StyledIonToolbar>
                            <IonButtons slot="start">
                                <StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
                                    <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                                </StyledHeaderButton>
                            </IonButtons>
                            <StyledIonTitle>{t('auditReport.start.title')}</StyledIonTitle>
                        </StyledIonToolbar>
                        <div className="sub-title-light">{t('auditReport.start.subtitle')}</div>
                    </IonHeader>
                    <StyledContent>
                        <div className="target">
                            {
                                showError &&
                                <StyledEmptyContainer className="preview">
                                    <CircleImage onClick={async (e) => {
                                        start();
                                    }} image={ImageTypes.QR} color="red" />
                                </StyledEmptyContainer>
                            }
                        </div>
                        <div className={'action-buttons empty multiple-buttons'}>
                            <IonGrid>
                                {showError && <IonRow>
                                    <IonCol size="12">
                                        <p className="errorMessage">{error}</p>
                                    </IonCol>
                                </IonRow>}
                                <IonRow>
                                    <IonCol size="6"><StyledButton onClick={() => history.push(`${Links.auditManualSpecificationFind}/${type}`)}>{t('auditReport.find.manualSpecification')}</StyledButton></IonCol>
                                    <IonCol size="6"><StyledButton onClick={() => history.push(`${Links.auditManualOrderFind}/${type}`)}>{t('auditReport.find.manualOrder')}</StyledButton></IonCol>
                                    <IonCol size="12"><StyledButton onClick={() => history.push(`${Links.audit}/${type}/0/0`)}>{t('auditReport.find.skip')}</StyledButton></IonCol>
                                </IonRow>
                            </IonGrid>
                        </div>
                    </StyledContent>
                </StyledIonContentCameraPreview>
            }
            {
                type === AuditType.BHP &&
                <StyledIonContent>
                    <IonHeader ref={header} className="ion-no-border">
                        <StyledIonToolbar>
                            <IonButtons slot="start">
                                <StyledHeaderButton onClick={() => handleBack()}>
                                    <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                                </StyledHeaderButton>
                            </IonButtons>
                            <StyledIonTitle className="wrap-text">
                                <div className="wrap-text">
                                    {t('auditReport.bhpStart.title')}
                                </div>
                            </StyledIonTitle>
                        </StyledIonToolbar>
                    </IonHeader>
                    <StaticPane topEdge={topEdge} marginTop={40} hideGrabber={true}>
                        <Form.Container onSubmit={handleSubmit(handleBhpSave)}>
                            <p className="desc">{t("auditReport.bhpStart.place_of_service")}</p>
                            <IonRow>
                                <IonCol size="12" className="select" onClick={() => {
                                    updateShowPlaceOfServicesModal(true)
                                }}>
                                    <StyledInput readOnly={true}
                                                 className={isSubmitted && !selectedPlaceOfService ? 'hasErrors' : ''}
                                                 value={selectedPlaceOfService?.name}
                                                 placeholder={t("common.select")}/>
                                </IonCol>
                            </IonRow>
                            <Form.Button type="submit"
                                         disabled={!selectedPlaceOfService}>{t("auditReport.bhpStart.addButton")}</Form.Button>
                        </Form.Container>
                    </StaticPane>
                </StyledIonContent>
            }
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            BarcodeScanner.openAppSettings();
                        }
                    }
                ]}
            />

            {showPlaceOfServiceModal &&
                <PaginatedListModal
                    search={true}
                    report={{} as AuditReportFormModel} // hack
                    isOpen={showPlaceOfServiceModal}
                    onFetchGeneric={(page: number, searchText?: string) => {
                        let data = loadPlaceOfServices(page, searchText);
                        return data;
                    }}
                    onSubmit={(selected) => {
                        setSelectedPlaceOfService(selected);
                        updateShowPlaceOfServicesModal(false);
                    }}
                    onClose={() => {
                        updateShowPlaceOfServicesModal(false);
                    }}
                />
            }
        </IonPage>
    );
};

export default AuditReportStart;
