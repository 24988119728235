import {CancelTokenSource} from "axios";

import {appApi, travelAllowanceApi} from "@services/api.service";
import {getWorkerId} from "@utils/tools/worker";

import {CalculateDistance, PlaceOfService, RideRoute, RideRouteForm} from "@models/travelAllowance/rideRoute";
import {Coordinator} from "@models/travelAllowance/ride";

const getRideRoutes = async (cancelToken?: CancelTokenSource): Promise<RideRoute[]> => {
    const workerId = await getWorkerId();
    const response: any = await travelAllowanceApi.get(`ride-routes/worker/${workerId}`, {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const getRideRoute = async (rideRouteId: number, cancelToken?: CancelTokenSource): Promise<RideRoute> => {
    const workerId = await getWorkerId();
    const response: any = await travelAllowanceApi.get(`ride-routes/${rideRouteId}/worker/${workerId}`, {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const addRideRoute = async (rideRouteData: RideRouteForm) => {
    const workerId = await getWorkerId();

    return travelAllowanceApi.post(`ride-routes/worker/${workerId}`, rideRouteData)
}

const editRideRoute = async (rideRouteId: number, rideRouteData: RideRouteForm) => {
    const workerId = await getWorkerId();

    return travelAllowanceApi.put(`ride-routes/${rideRouteId}/worker/${workerId}`, rideRouteData)
}

const removeRideRoute = async (rideRouteId: number) => {
    const workerId = await getWorkerId();

    return travelAllowanceApi.patch(`ride-routes/${rideRouteId}/worker/${workerId}/unlink-worker`)
}

const getPossibleCoordinators = async (cancelToken?: CancelTokenSource, page: number = 1, searchText: string = ''): Promise<Coordinator[]> => {
    const response: any = await appApi.get(`route/possible-coordinators?page=${page}&searchText=${searchText}`, {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const getPlacesOfService = async (coordinatorId: number, cancelToken?: CancelTokenSource, page: number = 1, searchText: string = ''): Promise<PlaceOfService[]> => {
    const response: any = await appApi.get(`route/possible-places-of-service?coordinatorId=${coordinatorId}&page=${page}&searchText=${searchText}`, {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const calculateDistance = async (calculateDistanceData: CalculateDistance, cancelToken?: CancelTokenSource) => {
    return travelAllowanceApi.get(`ride-routes/calculate-distance`, {
        params: calculateDistanceData,
        cancelToken: cancelToken?.token
    });
}

export {
    getRideRoutes,
    getRideRoute,
    addRideRoute,
    editRideRoute,
    removeRideRoute,
    getPossibleCoordinators,
    getPlacesOfService,
    calculateDistance,
};
