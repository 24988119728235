import styled from 'styled-components';
import { StyledIonContent } from '../../components/content/content.style';

export const StyledConversationIonContent = styled(StyledIonContent)`
  .header {
    --ion-safe-area-top: 35px;
    padding-top: var(--ion-safe-area-top, 0);

    .name {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #FFFFFF;
    }

    .department {
      font-size: 12px;
      line-height: 140%;
      text-align: center;
      color: #FFFFFF;
      opacity: 0.8;
    }
  }

  form {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 12;
    background: #ffffff;
    padding: 0 24px;
    padding-bottom: 16px;
  }

  .readed {
    font-size: 11px;
    line-height: 140%;
    color: #888A98;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
      margin-right: 6px;
    }
  }

  .separator {
    margin: 16px 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    position: relative;
    color: #949494;

    span {
      background: #ffffff;
      padding: 0 35px;
      z-index: 1;
    }

    :before {
      content: ' ';
      display: block;
      width: calc(100% - 24px);
      border-bottom: 1px solid #F4F4F9;
      position: absolute;
    }
  }
`;