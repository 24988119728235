import React, {useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {IonButtons, IonHeader, IonItem, IonPage, IonRow, IonSelectOption} from '@ionic/react';
import { StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../components/content/content.style';
import { StyledHeaderButton, StyledHeaderButtonImage } from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import { useForm } from 'react-hook-form';
import { StyledFooter } from '../../components/pane/pane.style';
import Form from '../../components/form';
import { useTranslation } from 'react-i18next';
import { StyledField } from './becameTeamMemberPage.style';
import {FORM_FIELDS, FormFieldsType} from './becameTeamMember.types';
import { IonToast } from '@ionic/react';
import {connect, checkFormType, signVerify} from "../../services/teamMember.service";
import { updateStorage } from "../../utils/tools/storage";
import StaticPane from "../../components/pane/static-pane.component";
import {ifNull} from "../../utils/tools/numbers";
import {Preferences} from "@capacitor/preferences";
import {hasUnsignedContracts} from "../../services/contract.service";
import {Links} from "../links";
import ExactPhoneInput from '../../components/form/input/phoneInput.component';
import WorkerAlreadyConnectedModal from "../../modals/becameTeamMember/workerAlreadyConnected.component";
import {MainPageContext} from "../mainPage/mainPage.component";
import {getMenu} from "../../services/menu.service";
import Info from "../../components/info/info.component";
import SignModal, {ISignModalConfigration} from "../../modals/sign/sign.component";
import {getProfile} from "../../services/profile.service";
import {storeProfile} from "../../services/auth.service";
import {StyledIonSelect} from "../../components/form/input/select.style";
import {validateEmail} from "../../services/validation.service";

enum EditType {
    INPUT,
	PHONE
}

enum MatchingType {
	PHONE = 'PHONE',
	EMAIL = 'EMAIL'
}

const DEFAULT_PHONE_PREFIX = '48';

interface IBecameTeamMemberField {
    label: string,
    required: boolean,
    name: FORM_FIELDS,
    type: EditType,
    options?: any[],
	value: any,
	help: string
}

interface IPersonalDataStorage {
	phone: any;
	rcp: any;
	number: any;
	isEmployed: any;
}

const BecameTeamMemberPage: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
    const [storageData, setStorageData] = useState<IPersonalDataStorage>();
	const [showWorkerAlreadyConnecteModal, updateWorkerAlreadyConnecteModal] = useState<boolean>(false);
	const [waitForAccept, setWaitForAccept] = useState<boolean>(false);
	const [needPhone, setNeedPhone] = useState<boolean>(true);
	const [needEmail, setNeedEmail] = useState<boolean>(true);
	const [matchingField, setMatchingField] = useState<string>();
	const [showSignModal, updateShowSignModal] = useState<boolean>(false);
	const [signModalConfiguration, updateSignModalConfiguration] = useState<ISignModalConfigration>();

	const mainPageManager = useContext(MainPageContext);

	const handleBack = () => {
        history.goBack();
    };

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const { register, handleSubmit, formState, errors } = useForm(
		{
			mode: 'onChange'
		}
	);

	const [phoneNumber, updatePhoneNumber] = useState(storageData?.phone);
	const [missingPhone, setMissingPhone] = useState(!!storageData?.phone);
	const [isPhoneValid, setIsPhoneValid] = useState(true);
	const [isPhonePl, setIsPhonePl] = useState(true);
	const [email, updateEmail] = useState('');
	const [matchingType, updateMatchingType] = useState(null);

	const { isValid } = formState;

	const [showToast, setShowToast] = useState(false);
	const [toast, setToast] = useState<any>('');
	const [step, setStep] = useState<number>(1);
	const [rcp, setRcp] = useState<string>('');

	let invalidEmailError = t("login.invalidEmail");

	const getPersonalData = async () => {
		let phone = await Preferences.get({'key': 'phone'});
		let phoneCode = await Preferences.get({'key': 'phone_code'});
		//let email = await Preferences.get({'key': 'email'});

		let personalDataStorage:IPersonalDataStorage = {
			phone: `${ifNull(phoneCode?.value) || DEFAULT_PHONE_PREFIX} ${ifNull(phone?.value) || ''}`,
			number: '',
			rcp: '',
			isEmployed: 0
		};

		updatePhoneNumber(personalDataStorage.phone);
		setStorageData(personalDataStorage);
	};

	useEffect(() => {
		getPersonalData();
		updateHeight();
		setStep(1);
	}, []);

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current ?.clientHeight);
        }
    };

	const onCheck = async () => {
		if (rcp) {
			const response = await checkFormType(rcp)
				.then(response => {
					setNeedPhone(response.data.data.phone_needed);
					setNeedEmail(response.data.data.email_needed);
					updateEmail(response.data.data.email);
					setMatchingField(response.data.data.matching_field);
					setStep(2);
					return true;
				})
				.catch((error) => {
					if (error.response.status === 404) {
						setToast(t("becameTeamMember.workerHasNotBeFound"));
						setShowToast(true);
					} else if (error.response.status === 424) {
						setToast(t("becameTeamMember.missingWorkerPhoneNumber"));
						setShowToast(true);
					} else if (error.response.status === 400) {
						setToast(t("becameTeamMember.matchingIsNotAllowed"));
						setShowToast(true);
					} else if (error.response.status === 409) {
						// setToast(t("becameTeamMember.workerAlreadyConnected"));
						// setShowToast(true);

						setWaitForAccept(true);
					} else {
						setToast(t("common.serverErrorMsg"));
						setShowToast(true);
					}
					return false;
				});


			if (!response && waitForAccept) {
				updateWorkerAlreadyConnecteModal(true);
			}
		}
	}

	const onSubmit = async (data: FormFieldsType) => {
		if (matchingField && matchingType && rcp && isValid && ((matchingType === MatchingType.PHONE && (!needPhone || (needPhone && !missingPhone))) || (matchingType === MatchingType.EMAIL && email))) {
			data.rcp = rcp;
			data.matchingField = matchingField;
			data.phone = phoneNumber;
			data.email = email;
			data.matchingType = matchingType;

			const response = await connect(data)
				.then(response => {
					return true;
				})
				.catch(error => {
					if (error.response.status === 404) {
						setToast(t("becameTeamMember.workerHasNotBeFound"));
						setShowToast(true);
					} else if (error.response.status === 400) {
						setToast(t("becameTeamMember.matchingIsNotAllowed"));
						setShowToast(true);
					} else if (error.response.status === 403) {
						if (MatchingType.PHONE == matchingType) {
							setToast(t("becameTeamMember.phoneNumberNotValid"));
						} else if (MatchingType.EMAIL == matchingType) {
							setToast(t("becameTeamMember.emailNotValid"));
						} else {
							setToast(t("becameTeamMember.invalidValue"));
						}
						setShowToast(true);
					} else if (error.response.status === 409) {
						// setToast(t("becameTeamMember.workerAlreadyConnected"));
						// setShowToast(true);
						setWaitForAccept(true);
					} else {
						setToast(t("common.serverErrorMsg"));
						setShowToast(true);
					}
					return false;
				});

			if (response) {
				let configuration:ISignModalConfigration = {
					title: t("becameTeamMember.sign.title"),
					buttonText: t("becameTeamMember.sign.signButton"),
				}

				if (matchingType == MatchingType.EMAIL) {
					configuration.email = email;
					configuration.info = t("becameTeamMember.sign.info_email");
				} else {
					configuration.phone = phoneNumber;
					configuration.info = t("becameTeamMember.sign.info");
				}

				updateSignModalConfiguration(configuration);
				updateShowSignModal(true);
			} else {
				if (waitForAccept) {
					updateWorkerAlreadyConnecteModal(true);
				}
			}
		}
	}

	const header = useRef<HTMLIonHeaderElement>(null);

    const onFocus = (el: HTMLElement) => {
		setTimeout(() => {
            const element = el.parentElement;
            const pane = el.parentElement?.parentElement?.parentElement;
            const scrollBy = (element?.getBoundingClientRect().y ?? 0) - (pane?.getBoundingClientRect().y ?? 0);

            if (pane && pane.scrollHeight - pane.scrollTop - scrollBy < pane.offsetHeight) {
                pane?.scrollBy({
                    top:  pane.scrollHeight - pane.scrollTop,
                    behavior: 'smooth'
                });
            } else {
                pane?.scrollBy({
                    top: scrollBy,
                    behavior: 'smooth'
                });
            }

		}, 400);
    };

	const signSave = async (code: string) => {
		const response = await signVerify(code)
			.then(response => {
				return true;
			})
			.catch(error => {
				if (error.response && error.response.status === 400) {
					setToast(t("common.invalidVerifyCode"));
					setShowToast(true);
				}

				return false;
			});

		if (response) {
			await getProfile(true).then(response => {
				storeProfile(response.data, true);
				return true;
			})

			await getMenu().then(response => {
				mainPageManager.setMenuItems(response.data);
			});

			let hasUnsigned = await hasUnsignedContracts();
			let redirect = null;

			if (hasUnsigned) {
				redirect = Links.main + Links.contracts;
			} else {
				redirect = Links.main + Links.profile;
			}

			if (redirect) {
				history.replace(redirect);
			}
		} else {
			if (waitForAccept) {
				updateWorkerAlreadyConnecteModal(true);
			}
		}
	}

	return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("becameTeamMember.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
				<StaticPane topEdge={topEdge} marginTop={40} paddingBottom={105} hasForm={true}>
					<IonToast
						isOpen={showToast}
						onDidDismiss={() => setShowToast(false)}
						message={toast}
						duration={6000}
						position="top"
						color="danger"
					/>
					{step === 1 && <Form.Container onSubmit={handleSubmit(onCheck)}>
						<StyledField>
							<label className={'required'}>{ t("becameTeamMember.rcp") }</label>
							{
								<Form.Input className="bolded no-margin" name={rcp}
											defaultValue={storageData?.number}
											onFocus={(e) => onFocus(e.target)}
											onChange={(e) => {
												setRcp(e.target.value);
											}}
											type="tel"
								/>
							}
						</StyledField>
						<br />
						<Info>{t('becameTeamMember.contactWithCoordinatorInfo')}</Info>
						<StyledFooter className="footer animated-footer no-shadow">
							<Form.Button type="submit" disabled={!rcp}>{t("becameTeamMember.checkButton")}</Form.Button>
						</StyledFooter>
					</Form.Container>}

					{step === 2 && <Form.Container>
						<StyledField>
							<label>{t("becameTeamMember.matchingType.label")}</label>
							<StyledIonSelect className="bolded" okText={t('common.selectOk')}
											 cancelText={t('common.selectCancel')}
											 name="matchingType"
											 justify="end"
											 interfaceOptions={
												 {
													 header: t("becameTeamMember.matchingType.label")
												 }
											 }
											 onIonChange={e => {
												 updateMatchingType(e.detail.value);
												 setStep(3);
											 }}>
												{ needPhone && <IonSelectOption key="phoneNumber"
													 value={MatchingType.PHONE}>{t("becameTeamMember.matchingType.phone")}</IonSelectOption> }
												{ needEmail && <IonSelectOption key="email"
																  value={MatchingType.EMAIL}>{t("becameTeamMember.matchingType.email")}</IonSelectOption> }
							</StyledIonSelect>
						</StyledField>
					</Form.Container>}

					{step === 3 && <Form.Container onSubmit={handleSubmit(onSubmit)}>
						<>{matchingType === MatchingType.PHONE && <StyledField>
							<label className={'required'}>{ t("becameTeamMember.phone") }</label>
							<div>
								<ExactPhoneInput
									phoneNumer={phoneNumber}
									phoneNumerChanged={(phoneNumber) => { updatePhoneNumber(phoneNumber) }}
									isValid={(isValid) => { setIsPhoneValid(isValid) }}
									isPhonePl={(isPhonePl) => { setIsPhonePl(isPhonePl) }}
									errorChanged={setMissingPhone}
								></ExactPhoneInput>

								{
									needPhone && !isPhoneValid && isPhonePl &&
									<p className="errorMessage">{t("becameTeamMember.phoneValidation.pl")}</p>
								}
								{
									needPhone && !isPhoneValid && !isPhonePl &&
									<p className="errorMessage">{t("becameTeamMember.phoneValidation.other")}</p>
								}
							</div></StyledField>}
						</>

						<>{matchingType === MatchingType.EMAIL && <StyledField>
							<label className={'required'}>{ t("becameTeamMember.email") }</label>
							<div>
								<Form.Input name="email"
											ref={register({
												required: true,
												validate: value =>
													validateEmail(value) || invalidEmailError
											})}
											placeholder={t("login.inputEmailPlaceholder")}
											className={errors.email && 'hasErrors'}
											type="email"
											onChange={(e) => updateEmail(e.target.value)}
								/>
								{errors.email && errors.email.message && <p className="errorMessage">{errors.email.message}</p>}
							</div></StyledField>}
						</>

						<StyledFooter className="footer animated-footer no-shadow">
							<Form.Button type="submit" disabled={(matchingType === MatchingType.PHONE && missingPhone) || (matchingType === MatchingType.EMAIL && !isValid)}>{t("becameTeamMember.sendButton")}</Form.Button>
						</StyledFooter>
					</Form.Container>}

				</StaticPane>
            </StyledIonContent>
			<WorkerAlreadyConnectedModal isOpen={showWorkerAlreadyConnecteModal} onClose={() => updateWorkerAlreadyConnecteModal(false)} />

			{signModalConfiguration && <SignModal configuration={signModalConfiguration}
												  isOpen={showSignModal}
												  onClose={() => updateShowSignModal(false)}
												  onSave={(code: string) => signSave(code)} />}
		</IonPage>
    );
};

export default BecameTeamMemberPage;
