import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    IonButtons,
    IonHeader,
    IonPage,
    useIonViewDidEnter
} from '@ionic/react';
import { StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../components/content/content.style';
import { StyledHeaderButton, StyledHeaderButtonImage } from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import CheckImage from '../../assets/images/check.svg';
import { ClearableInput } from '../../components/form/input/input.style';
import { useForm } from 'react-hook-form';
import { StyledContent } from './addEditValuePage.style';
import { getConfiguration } from '../../services/addEditValuePage.service';
import { Links } from '../links';
import Form from '../../components/form';
import StaticPane from '../../components/pane/static-pane.component';

const AddEditValuePage: React.FC = () => {

    const fieldName = "field";
    const history = useHistory();
	const [error, setError] = useState<string>();
    const [submitted, setSubmitted] = useState(false);

    if (!getConfiguration()) {
        history.goBack();
    }

    const handleBack = () => {
        const handleDismiss = getConfiguration()?.handleDismiss;

        if (typeof handleDismiss === 'function') {
            handleDismiss();
        } else {
            getConfiguration()?.url ? history.replace(getConfiguration()?.url || Links.login, history.location.state) : history.goBack();
        }
    };

    const handleSave = async () => {
        const handleSave = getConfiguration()?.handleSave;

        if (typeof handleSave === 'function') {
            setSubmitted(true);
            if (!submitted) {
                let response:any = await handleSave(getValues(fieldName));
                if (response !== undefined) {
                    setSubmitted(false);
                    setError(response);
                }
            }
	    } else {
            setSubmitted(false);
			getConfiguration()?.url ? history.replace(getConfiguration()?.url || Links.login, history.location.state) : history.goBack();
        }
    };

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const { register, getValues, setValue, handleSubmit } = useForm({
        mode: 'all'
    });

    useEffect(() => {
        setValue(fieldName, getConfiguration()?.defaultValue);
    }, []);

    useEffect(() => {
        updateHeight();
    });

    useIonViewDidEnter(() => {
        document.getElementsByName(fieldName)[0].focus();
    });

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current ?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

	return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{getConfiguration()?.title}</StyledIonTitle>
                        <IonButtons slot="end">
                            <StyledHeaderButton className="save-btn" onClick={() => handleSave()}>
                                <StyledHeaderButtonImage src={CheckImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} hideGrabber={true}>
                    <Form.Container onSubmit={handleSubmit(handleSave)}>
                        <StyledContent>
                            <ClearableInput name={fieldName} className={error && 'hasErrors'} ref={register()}
                                            placeholder={getConfiguration()?.placeholder} setValue={setValue}
                                            type={getConfiguration()?.type}
                            ></ClearableInput>
                            {error && <p className="errorMessage">{error}</p>}
                        </StyledContent>
                    </Form.Container>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default AddEditValuePage;
