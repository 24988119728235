import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {IonButtons, IonHeader, IonPage, IonSelectOption, IonToast} from '@ionic/react';
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '../../components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage} from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import {useForm, Controller} from 'react-hook-form';
import {StyledFooter} from '../../components/pane/pane.style';
import Form from '../../components/form';
import {useTranslation} from 'react-i18next';
import SignModal, {ISignModalConfigration} from '../../modals/sign/sign.component';
import {FORM_FIELDS} from './contractData.types';
import {StyledIonSelect} from '../../components/form/input/select.style';
import {getContractData, saveContractData, signSaveContractData} from "../../services/worker.service";
import {ContractData, ContractDataSelectable} from "../../models/contractData";
import moment from 'moment';
import {
    getCities,
    getNationalities,
    getTaxOffices
} from "../../services/possibleValues.service";
import {StyledIonDatetime} from "../../components/form/input/date.style";
import {StyledField, StyledInput} from "../../components/form/input/input.style";
import {Links} from "../links";
import StaticPane from '../../components/pane/static-pane.component';

enum EditType {
    INPUT,
    SELECT,
    DATE,
    PHONE,
    EMAIL,
    NUMBER,
}

interface IContractDataField {
    label: string,
    required: boolean,
    name: FORM_FIELDS,
    type: EditType,
    value: string | bigint | Date | undefined,
    disabled?: boolean,
    options?: any[],
    onChange?: (value: string) => void;
}

const ContractDataPage: React.FC = () => {

    const history = useHistory();
    const {t} = useTranslation();

    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');

    const [contractData, setContractData] = useState<ContractData>();
    const [cities, setCities] = useState<ContractDataSelectable[]>();
    const [selectedCity, setSelectedCity] = useState<string>();
    const [taxOffices, setTaxOffices] = useState<ContractDataSelectable[]>();
    const [selectedTaxOffice, setSelectedTaxOffice] = useState<string>();
    const [nationalities, setNationalities] = useState<ContractDataSelectable[]>();
    const [manualIsDirty, updateManualIsDirty] = useState<boolean>(false); // to emulate isDirty like functionality for select fields

    const setValues = (): any => {
        let defaultObject: any = {};

        contractDataFields.forEach((field) => {
            setValue(field.name, field.value);
        });

        return defaultObject;
    };

    const fetchContractData = async () => {
        if (!contractData) {
            await getContractData()
                .then(response => {
                    let contractData = response.data as ContractData;
                    setContractData(contractData);
                    setSelectedCity(contractData.city.id.toString());
                    setSelectedTaxOffice(contractData.tax_office.id.toString());
                });
        }
    };

    const fetchCities = async (text: string | bigint) => {
        await getCities(text)
            .then(response => {
                setCities(response.data);
            });
    };

    const fetchNationalities = async () => {
        await getNationalities()
            .then(response => {
                setNationalities(response.data);
            });
    };

    const fetchTaxOffices = async () => {
        await getTaxOffices()
            .then(response => {
                setTaxOffices(response.data);
            });
    };

    useEffect(() => {
        fetchContractData();
        setValues();
    }, [contractData]);

    useEffect(() => {
        fetchNationalities();
        fetchTaxOffices();
    }, []);

    useEffect(() => {
        fetchCities('');
    }, []);

    const handleBack = () => {
        history.goBack();
    };

    const [showSignModal, updateShowSignModal] = useState<boolean>(false);
    const [signModalConfiguration, updateSignModalConfiguration] = useState<ISignModalConfigration>();

    const {register, errors, setValue, handleSubmit, formState} = useForm({
        mode: 'onChange'
    });

    const { isValid, isDirty } = formState;

    const onSubmit = async (data: any) => {
        data.city = selectedCity;
        data.taxOffice = selectedTaxOffice;
        if (isValid) {
            await saveContractData(data).then(response => {
                updateSignModalConfiguration({
                    title: t("contractDataPage.sign.title"),
                    info: t("contractDataPage.sign.info"),
                    buttonText: t("contractDataPage.sign.signButton")
                });

                updateShowSignModal(true);
            }).catch(error => {
                if (error.response && error.response.status === 400) {
                    setToast(t("common.serverValidationErrorMsg"));
                    setShowToast(true);
                }
            });
        }
    };

    const signSave = async (code: string) => {
        await signSaveContractData(code)
            .then(response => {
                updateShowSignModal(false);
                history.push(Links.main + Links.settings)
            }).catch(error => {
                if (error.response && error.response.status === 400) {
                    setToast(t("common.invalidVerifyCode"));
                    setShowToast(true);
                }
            });
    };

    const contractDataFields: IContractDataField[] = [
        {
            label: t("contractDataPage.firstName"),
            required: true,
            name: FORM_FIELDS.FIRST_NAME,
            type: EditType.INPUT,
            value: contractData?.first_name
        },
        {
            label: t("contractDataPage.lastName"),
            required: true,
            name: FORM_FIELDS.LAST_NAME,
            type: EditType.INPUT,
            value: contractData?.last_name
        },
        {
            label: t("contractDataPage.pesel"),
            required: true,
            disabled: true,
            name: FORM_FIELDS.PESEL,
            type: EditType.NUMBER,
            value: contractData?.pesel
        },
        {
            label: t("contractDataPage.dateOfBirth"),
            required: true,
            disabled: true,
            name: FORM_FIELDS.DATE_OF_BIRTH,
            type: EditType.DATE,
            value: contractData?.date_of_birth ? moment(contractData?.date_of_birth).format('YYYY-MM-DD') : ''
        },
        {
            label: t("contractDataPage.nationality"),
            required: true,
            disabled: true,
            name: FORM_FIELDS.NATIONALITY,
            type: EditType.SELECT,
            value: contractData?.nationality.id,
            options: contractData ? [{
                id: contractData?.nationality.id,
                name: contractData?.nationality.name
            }] : []
        },
        {
            label: t("contractDataPage.street"),
            required: true,
            name: FORM_FIELDS.STREET,
            type: EditType.INPUT,
            value: contractData?.street
        },
        {
            label: t("contractDataPage.houseNumber"),
            required: true,
            name: FORM_FIELDS.HOUSE_NUMBER,
            type: EditType.INPUT,
            value: contractData?.house_number
        },
        {
            label: t("contractDataPage.apartmentNumber"),
            required: false,
            name: FORM_FIELDS.APARTMENT_NUMBER,
            type: EditType.INPUT,
            value: contractData?.apartment_number
        },
        {
            label: t("contractDataPage.zipCode"),
            required: true,
            name: FORM_FIELDS.ZIP_CODE,
            type: EditType.INPUT,
            value: contractData?.zip_code
        },
        {
            label: t("contractDataPage.postOffice"),
            required: true,
            name: FORM_FIELDS.POST_OFFICE,
            type: EditType.INPUT,
            value: contractData?.post_office
        },
        {
            label: t("contractDataPage.city"),
            required: true,
            name: FORM_FIELDS.CITY,
            type: EditType.SELECT,
            value: selectedCity,
            options: cities ? cities : (contractData ? [{
                id: contractData?.city.id,
                name: contractData?.city.name
            }] : []),
            onChange: (value: string) => {
                setSelectedCity(value);
            }
        },
        {
            label: t("contractDataPage.taxOffice"),
            required: true,
            name: FORM_FIELDS.TAX_OFFICE,
            type: EditType.SELECT,
            value: selectedTaxOffice,
            options: taxOffices ? taxOffices : (contractData ? [{
                id: contractData?.tax_office.id,
                name: contractData?.tax_office.name
            }] : []),
            onChange: (value: string) => {
                if (value !== selectedTaxOffice) {
                    setSelectedTaxOffice(value);
                }
            }
        },
        {
            label: t("contractDataPage.bankAccountNumber"),
            required: true,
            name: FORM_FIELDS.BANK_ACCOUNT_NUMBER,
            type: EditType.INPUT,
            value: contractData?.bank_account_number
        },
        {
            label: t("contractDataPage.email"),
            required: true,
            name: FORM_FIELDS.EMAIL,
            type: EditType.EMAIL,
            value: contractData?.email
        },
        {
            label: t("contractDataPage.phone"),
            required: true,
            name: FORM_FIELDS.PHONE,
            type: EditType.PHONE,
            value: contractData?.phone
        }];

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    useEffect(() => {
        updateHeight();
    });

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);
    const updateValue = (field: IContractDataField, value: any) => {
        if (field.onChange) {
            field.onChange(value);
        }
    };

    const onFocus = (el: HTMLElement) => {
		setTimeout(() => {
            const element = el.parentElement;
            const pane = el.parentElement?.parentElement?.parentElement;
            const scrollBy = (element?.getBoundingClientRect().y ?? 0) - (pane?.getBoundingClientRect().y ?? 0);

            if (pane && pane.scrollHeight - pane.scrollTop - scrollBy < pane.offsetHeight) {
                pane?.scrollBy({
                    top:  pane.scrollHeight - pane.scrollTop,
                    behavior: 'smooth'
                });
            } else {
                pane?.scrollBy({
                    top: scrollBy,
                    behavior: 'smooth'
                });
            }

		}, 400);
    };

    return (
        <IonPage>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("contractDataPage.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} paddingBottom={105} hasForm={true}>
                            <Form.Container onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    {
                                        contractDataFields.map((field, key) =>
                                            <StyledField key={key}>
                                                <label className={field.required ? 'required' : ''}>{field.label}</label>
                                                {field.type === EditType.INPUT &&
                                                <StyledInput className={errors[field.name] ? 'bolded no-margin hasErrors' : 'bolded no-margin'} name={field.name}
                                                                disabled={field.disabled}
                                                                ref={register({
                                                                    required: field.required
                                                                })}
                                                                type="text"
                                                                onFocus={e => onFocus(e.target)}
                                                                onChange={e => { updateValue(field, e.target.value) }}
                                                ></StyledInput>
                                                }
                                                {field.type === EditType.PHONE &&
                                                <StyledInput className={errors[field.name] ? 'bolded no-margin hasErrors' : 'bolded no-margin'} name={field.name}
                                                                disabled={field.disabled}
                                                                ref={register({
                                                                    required: field.required
                                                                })}
                                                                type="tel"
                                                                onFocus={e => onFocus(e.target)}
                                                                onChange={e => { updateValue(field, e.target.value) }}
                                                ></StyledInput>
                                                }
                                                {field.type === EditType.EMAIL &&
                                                <StyledInput className={errors[field.name] ? 'bolded no-margin hasErrors' : 'bolded no-margin'} name={field.name}
                                                                disabled={field.disabled}
                                                                ref={register({
                                                                    required: field.required
                                                                })}
                                                                type="email"
                                                                onFocus={e => onFocus(e.target)}
                                                                onChange={e => { updateValue(field, e.target.value) }}
                                                ></StyledInput>
                                                }
                                                {field.type === EditType.NUMBER &&
                                                <StyledInput className={errors[field.name] ? 'bolded no-margin hasErrors' : 'bolded no-margin'} name={field.name}
                                                                disabled={field.disabled}
                                                                ref={register({
                                                                    required: field.required
                                                                })}
                                                                type="number"
                                                                onFocus={e => onFocus(e.target)}
                                                                onChange={e => { updateValue(field, e.target.value) }}
                                                ></StyledInput>
                                                }
                                                {
                                                    field.type === EditType.SELECT &&
                                                    <StyledIonSelect className="bolded" okText={t('common.selectOk')}
                                                                    cancelText={t('common.selectCancel')}
                                                                    value={field.value?.toString()}
                                                                    disabled={field.disabled}
                                                                    name={field.name}
                                                                    justify="end"
                                                                    interfaceOptions={
                                                                        {
                                                                            header: field.label
                                                                        }
                                                                    }
                                                                    onIonChange={e => {
                                                                        if (field.onChange) {
                                                                            field.onChange(e.detail.value);
                                                                        }
                                                                    }}
                                                                    onFocus={e => onFocus(e.target)}
                                                                    onIonFocus={e => {
                                                                        updateManualIsDirty(true);
                                                                    }}>
                                                        {field.options?.map((value: ContractDataSelectable, key) =>
                                                            <IonSelectOption key={key}
                                                                            value={value.id.toString()}>{value.name}</IonSelectOption>)}
                                                    </StyledIonSelect>
                                                }
                                                {
                                                    field.type === EditType.DATE &&
                                                    <StyledIonDatetime
                                                        className={errors[field.name] ? 'hasErrors' : ''}
                                                        name={field.name}
                                                        onFocus={e => onFocus(e.target)}
                                                        // displayFormat="DD.MM.YYYY"
                                                        disabled={field.disabled}
                                                        value={field.value?.toString()}
                                                        doneText={t('common.dateDone')}
                                                        cancelText={t('common.dateCancel')}></StyledIonDatetime>
                                                }

                                                {errors[field.name] && errors[field.name].message && <p className="errorMessage">{errors[field.name].message}</p>}
                                            </StyledField>
                                        )
                                    }
                                </div>
                        <StyledFooter className="footer animated-footer no-shadow">
                            <Form.Button type="submit" disabled={!isDirty && !manualIsDirty}>{t("contractDataPage.acceptButton")}</Form.Button>
                        </StyledFooter>
                    </Form.Container>
                </StaticPane>
            </StyledIonContent>
            {signModalConfiguration && <SignModal configuration={signModalConfiguration}
                                                  isOpen={showSignModal}
                                                  onClose={() => updateShowSignModal(false)}
                                                  onSave={(code: string) => signSave(code)}>

            </SignModal>}
        </IonPage>
    );
};

export default ContractDataPage;
