import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IonButtons, IonHeader, IonPage } from '@ionic/react';
import { StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../components/content/content.style';
import { StyledHeaderButton, StyledHeaderButtonImage } from '../../components/button/button.style';
import Pane from '../../components/pane/pane.component';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import { StyledContent, StyledStatusContainer } from './statusPage.style';
import { StyledOption } from '../../components/options/options.style';
import { useTranslation } from 'react-i18next';
import Form from '../../components/form';
import { StyledFooter } from '../../components/pane/pane.style';
import Info from '../../components/info/info.component';
import SignModal, { ISignModalConfigration } from '../../modals/sign/sign.component';

interface IStatus {
    name: string;
    description: string;
}

const StatusPage: React.FC = () => {

    const history = useHistory();
    const { t } = useTranslation();

    const savedOption = 0;
    
    const handleBack = () => {
        history.goBack();
    };

    const handleSave = () => {
        updateShowSignModal(false);
    };

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [selectedOption, updateSelectedOption] = useState<number>(savedOption);
    const [showSignModal, updateShowSignModal] = useState<boolean>(false);
    const [signModalConfiguration, updateSignModalConfiguration] = useState<ISignModalConfigration>();
    const sign = () => {
        updateSignModalConfiguration({
            title: t("statusPage.sign.title"),
            info: t("statusPage.sign.info"),
            approvementText: t("statusPage.sign.accept"),
            buttonText: t("statusPage.sign.signButton")
        });

        updateShowSignModal(true);
    };

    useEffect(() => {
        updateHeight();
    });

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current ?.clientHeight);
        }
    };

    const statuses: IStatus[] = [
        { name: 'Status', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra metus ut turpis ullamcorper, et dolor.' },
        { name: 'Status', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra metus ut turpis ullamcorper, et dolor.' },
        { name: 'Status', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra metus ut turpis ullamcorper, et dolor.' },
        { name: 'Status', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra metus ut turpis ullamcorper, et dolor.' },
        { name: 'Status', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra metus ut turpis ullamcorper, et dolor.' },
        { name: 'Status', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra metus ut turpis ullamcorper, et dolor.' },
        { name: 'Status', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra metus ut turpis ullamcorper, et dolor.' },
        { name: 'Status', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra metus ut turpis ullamcorper, et dolor.' },
        { name: 'Status', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra metus ut turpis ullamcorper, et dolor.' }
    ];

    const header = useRef<HTMLIonHeaderElement>(null);

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{ t('statusPage.title') }</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                <Pane topEdge={topEdge} marginTop={40} hideGrabber={true}>
                    <StyledContent>
                        <Info className="info">{t('statusPage.info')}</Info>
                        { statuses.map((status, i)=> 
                            <StyledOption key={i} className={i === selectedOption ? 'selected' : ''}>
                                <StyledStatusContainer onClick={() => updateSelectedOption(i)}>
                                    <div className="status-title-row">
                                        <div>{ status.name }</div>
                                        { i === selectedOption && selectedOption === savedOption && <div className="statement">{ t('statusPage.downloadStatement') }</div> }
                                    </div>
                                    
                                    { status.description }
                                </StyledStatusContainer>
                            </StyledOption>
                        )}
                    </StyledContent>
                    <StyledFooter className="no-shadow">
                        <Form.Button type="submit" disabled={selectedOption === savedOption} onClick={() => sign()}>{t("statusPage.saveButton")}</Form.Button>
                    </StyledFooter>
                </Pane>
                { signModalConfiguration && <SignModal configuration={signModalConfiguration} isOpen={showSignModal} onClose={() => updateShowSignModal(false) } onSave={() => handleSave() }></SignModal> }
            </StyledIonContent>
        </IonPage>
    );
};

export default StatusPage;
