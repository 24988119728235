import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Loan as LoanModel} from "../../../models/loan";
import Info from '../../../components/info/info.component';
import Pane from '../../../components/pane/pane.component';
import {Links} from '../../links';
import {
    cancelLoan,
    checkLoanAvailability, getDocuments, getLoanDrafts,
    getLoans,
    signLoan,
    signLoanVerify
} from "../../../services/loan.service";
import {StyledEmptyContainer} from '../../../components/content/content.style';
import CircleImage, {ImageTypes} from '../../../components/circleImage/circleImage.component';
import {IonAlert, IonList, IonSkeletonText, IonToast, useIonViewWillEnter} from "@ionic/react";
import StaticPane from '../../../components/pane/static-pane.component';
import {UserContext, isEmployed} from "../../../services/auth.service";
import {log} from "../../../services/firebaseAnalytics.service";
import LoanSkeleton from "../../../components/loan/loanSkeleton.component";
import Loan from "../../../components/loan/loan.component";
import LoansGuideModal from "../../loanPage/modals/loansGuideModal.component";
import {StyledButton, StyledSocialButton} from "../../../components/button/button.style";
import NewLoanModal, {NewLoanContext} from "../../loanPage/modals/newLoanModal.component";
import ApprovalsModal from "../../loanPage/modals/approvalsModal.component";
import {hasPhone} from "../../../services/teamMember.service";
import {LoanStatus} from "../../../enums/loanStatus";
import SignModal, {ISignModalConfigration} from "../../../modals/sign/sign.component";
import TeamMemberPhoneModal from "../../../modals/teamMemberPhone/teamMemberPhone.component";
import {Preferences} from "@capacitor/preferences";
import NewLoanTakenModal from "../../loanPage/modals/newLoanTakenModal.component";
import PreconditionsModal from "../../loanPage/modals/preconditionsModal.component";
import Notice from "../../../components/notice/notice.component";
import useNavigation from "../../../services/navigation.service";
import {SubNavigationContext, SubNavigationOption} from "../../../components/sub-navigation/subNavigation.component";
import {LoansDataPageTabOptions, LoansPageContext} from "../loansPage.coponent";
import {ContractPageContext, ContractPageOptions} from "../../contractPage/contractPage.component";

type LoansPaneProps = {
    topEdge?: number;
    handleNavigation: (option: SubNavigationOption) => void
}

interface LoansManager {
    setLoans: Function;
}

const contextLoans: LoansManager = {
    setLoans: () => {

    }
};

export const LoansContext = React.createContext<LoansManager>(contextLoans);

const LoansPane: React.FC<LoansPaneProps> = (props: LoansPaneProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);

    const user = useContext(UserContext);
    const contextLoansPage = useContext(LoansPageContext);
    const contextSubNavigation = useContext(SubNavigationContext);

    const [loans, setLoans] = useState<[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [showGuide, updateShowGuide] = useState<boolean>(false);
    const [showNew, updateShowNew] = useState<boolean>(false);
    const [amount, setAmount] = useState<number>(0);
    const [availabilityMessage, setAvailabilityMessage] = useState<string>('');
    const [minAmount, setMinAmount] = useState<number>(0);
    const [maxAmount, setMaxAmount] = useState<number>(0);
    const [currency, setCurrency] = useState<string>('');
    const [bankAccountNumber, setBankAccountNumber] = useState<number>(0);
    const [showPreconditions, updateShowPreconditions] = useState<boolean>(false);
    const [showApprovals, updateShowApprovals] = useState<boolean>(false);
    const [showPhoneModal, updateShowPhoneModal] = useState<boolean>(false);
    const [showSignModal, updateShowSignModal] = useState<boolean>(false);
    const [currentLoan, updateCurrentLoan] = useState<LoanModel | undefined>();
    const [signModalConfiguration, updateSignModalConfiguration] = useState<ISignModalConfigration>();
    const [showNewLoanTaken, updateShowNewLoanTaken] = useState<boolean>(false);
    const [newLoanTakenResult, updateNewLoanTakenResult] = useState<string>('');
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');
    const [showAlert, setShowAlert] = useState(false);
    const [signFormSubmitted, updateSignFormSubmitted] = useState(false);
    const [instructionContent, updateInstructionContent] = useState<string>('');

    const fetchLoanAvailability = async (showNew:boolean = true) => {
        //setLoading(true);
        const response = await checkLoanAvailability()
            .then(response => {
                if (response.data?.result == "not_available") {
                    setAmount(0);
                    setAvailabilityMessage(response.data.message);
                    contextNewLoan.setMaxAmount(0);
                    contextNewLoan.setAvailabilityMessage(response.data.message);
                } else if (response.data?.amount) {
                    setAmount(response.data.amount.available);
                    setMaxAmount(response.data.amount.max);
                    setMinAmount(response.data.amount.min);
                    setCurrency(response.data.currency);
                    setBankAccountNumber(response.data.bank_account_number);
                    contextNewLoan.setMaxAmount(response.data.amount.max);
                    contextNewLoan.setAvailabilityMessage('');
                }

                updateShowNew(showNew); //showNew;
                updateShowPreconditions(false);
            })
            .catch(error => {
                // todo what we show??
            });

        //setLoading(false);
    };

    // useEffect(() => {
    //     fetchLoanAvailability();
    // }, []);

    const fetchDocuments = async () => {
        setLoading(true);
        const response = await getDocuments()
            .then(response => {
                if (response.data?.result && response.data.result === 'error') {
                    updateInstructionContent(t("loanPage.instructionNotAvailable"));
                } else {
                    updateInstructionContent(response.data.instruction_content);
                }
            })
            .catch(error => {
                // todo what we show??
            });
        setLoading(false);
    };

    useEffect(() => {
        fetchDocuments();
    }, []);

    const updateLoans = async (showNew:boolean = true) => {
        fetchLoanAvailability(showNew);
    }

    const contextLoans = useContext(LoansContext);
    contextLoans.setLoans = setLoans;

    const contextNewLoan = useContext(NewLoanContext);
    contextNewLoan.setMaxAmount = setMaxAmount;
    contextNewLoan.setAvailabilityMessage = setAvailabilityMessage;

    const [employed, setEmployed] = useState<boolean>(false);
    const isEmployee = async() => {
        const response = await isEmployed();
        let result:boolean = response == '1' ? true : false;
        return result;
    }

    const fetchUserData = async () => {
        let isEmployed: boolean = await isEmployee();
        setEmployed(isEmployed);
    }

    useEffect(() => {
        fetchUserData();
    }, []);

    useIonViewWillEnter(() => {
        fetchUserData();
    });

	useEffect(() => {
		log('page_visit', {
			page: 'Profil -> Pożyczki'
		});
	}, []);

    const newLoan = async () => {
        fetchLoanAvailability();
    }

    const preSign = async () => {
        updateShowPreconditions(false);
        
        let hasPhoneNumber = null;
        await hasPhone().then(value => {
            hasPhoneNumber = value;
        });

        if (hasPhoneNumber === false) {
            updateShowPhoneModal(true);
        } else {
            updateShowApprovals(true)
        }
    }

    const sign = async () => {
        await signLoan(currentLoan?.id)
            .then(response => {
                updateSignModalConfiguration({
                    title: t("loanPage.sign.title"),
                    info: t("loanPage.sign.info"),
                    buttonText: t("loanPage.sign.signButton"),
                    afterCodeText: t("loanPage.sign.afterCodeText", {amount: currentLoan?.amount, currency: currentLoan?.currency}),
                });

                updateShowSignModal(true);
            });

        updateShowApprovals(false);
    };

    const signSave = async (code: string) => {
        updateSignFormSubmitted(true);
        await signLoanVerify(currentLoan?.id, code)
            .then(response => {
                if (response.data?.result && response.data?.result == 'error') {
                    updateNewLoanTakenResult('error');
                } else {
                    updateNewLoanTakenResult('success');
                }
                updateShowNewLoanTaken(true);
                updateShowSignModal(false);
                updateList();
                updateSignFormSubmitted(false);
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    setToast(t("common.invalidVerifyCode"));
                    setShowToast(true);
                }
                updateSignFormSubmitted(false);
            });
    };

    const updateList = async () => {
        await getLoans()
            .then(response => {
                setLoans(response.data);
            })
            .catch(error => {
                // todo what we show??
            });
    }

    const updateLoansAndPreSign = async () => {
        await fetchLoanAvailability();
        await getLoans()
            .then(response => {
                setLoans(response.data);
                updateCurrentLoan(response.data[0]);
                preSign();
            })
            .catch(error => {
                // todo what we show??
            });
    }

    const updateLoansAndShowApprovals = async () => {
        await getLoanDrafts()
            .then(response => {
                //setLoans(response.data);
                updateCurrentLoan(response.data[0]);
                updateShowApprovals(true);
            })
            .catch(error => {
                // todo what we show??
            });
    }

    const cancel = async () => {
        if (currentLoan?.id) {
            await cancelLoan(currentLoan?.id)
                .then(response => {
                    updateShowApprovals(false);
                    setShowAlert(false);
                    //updateList();
                    updateLoans(false);
                });
        }
    };

    return (
        <LoansContext.Provider value={contextLoans}>
            {(!loading && (!loans || loans.length === 0)) && !employed &&
                <StaticPane topEdge={props.topEdge} marginTop={35} paddingBottom={97}>
                    <StyledEmptyContainer>
                        <CircleImage image={ImageTypes.NOTE} color="grey"/>
                            <h3>{t('profileTab.loans.notWorker')}</h3>
                    </StyledEmptyContainer>
                </StaticPane>
            }

            {loading &&
                <StaticPane topEdge={props.topEdge} marginTop={35} paddingBottom={97}>
                    <IonList>
                        <StyledSocialButton className="greyedout">
                            <small style={{ width: '80%' }}><IonSkeletonText animated /></small>
                        </StyledSocialButton>
                        <StyledSocialButton className="greyedout">
                            <small style={{ width: '50%' }}><IonSkeletonText animated /></small>
                        </StyledSocialButton>
                    </IonList>
                </StaticPane>
            }

            {!loading && employed &&
            <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={147}>
                <span onClick={() => updateShowGuide(true)}><Info>{t('profileTab.loans.info')}</Info></span>
                <StyledButton /*disabled={amount == 0}*/ onClick={() => updateShowPreconditions(true)}>
                    {t('profileTab.loans.new')}
                </StyledButton>

                {
                    loans && loans.length == 0 && <Notice className="transparent justify">{t('profileTab.loans.intro')}</Notice>
                }
            </Pane>
            }

            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />

            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={t('loanPage.cancelAlertTitle')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowAlert(false)
                        }
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: () => {
                            cancel();
                        }
                    }
                ]}
            />

            <LoansGuideModal isOpen={showGuide}
                             onClose={() => updateShowGuide(false)}
                             content={instructionContent} />

            <PreconditionsModal loan={currentLoan}
                            isOpen={showPreconditions}
                            onClose={() => updateShowPreconditions(false)}
                            onAccept={() => newLoan()}></PreconditionsModal>

            <NewLoanModal isOpen={showNew}
                          onClose={() => updateShowNew(false)}
                          minAmount={minAmount}
                          maxAmount={maxAmount}
                          currency={currency}
                          bankAccountNumber={bankAccountNumber}
                          updateLoans={updateLoans}
                          availabilityMessage={availabilityMessage}
                          updateLoansAndShowApprovals={updateLoansAndShowApprovals}
            />

            <ApprovalsModal loan={currentLoan}
                            isOpen={showApprovals}
                            onSign={() => sign()}
                            onClose={() => {
                                updateShowApprovals(false);
                                updateShowNew(false);
                                // navigation.setParams({
                                //     'selectedOption': LoansDataPageTabOptions.LOANS_HISTORY
                                // });
                                // contextLoansPage.setSelectedTab(LoansDataPageTabOptions.LOANS_HISTORY);
                                // contextSubNavigation.setSelected(LoansDataPageTabOptions.LOANS_HISTORY);
                            }}
                            onReject={() => setShowAlert(true)}></ApprovalsModal>

            <TeamMemberPhoneModal isOpen={showPhoneModal}
                                  onClose={() => {updateShowPhoneModal(false)}}
                                  onSubmit={ async (phone) => {updateShowPhoneModal(false);
                                  await Preferences.set({'key': 'phone', 'value': phone});
                                  updateShowApprovals(true)}}></TeamMemberPhoneModal>

            {signModalConfiguration && <SignModal configuration={signModalConfiguration}
                                                  isOpen={showSignModal}
                                                  onClose={() => updateShowSignModal(false)}
                                                  onSave={(code: string) => signSave(code)}
                                                  formSubmitted={signFormSubmitted}>
            </SignModal>}

            <NewLoanTakenModal loan={currentLoan}
                               result={newLoanTakenResult}
                               isOpen={showNewLoanTaken}
                               onClose={() => updateShowNewLoanTaken(false)}>
            </NewLoanTakenModal>
        </LoansContext.Provider>
    );
};

export default LoansPane;
