import React, {useEffect, useState} from 'react';
import {IonIcon} from "@ionic/react";
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";

import {StyledButton, StyledRedButton, StyledSocialButton} from '@components/button/button.style';
import {StyledNoOrders, StyledOrders} from "@app/mainPage/tabs/reports/panes/reports.style";

import Pane from '@components/pane/pane.component';
import ReportsSkeleton from "@app/mainPage/tabs/reports/components/reportsSkeleton.component";

import ClockImage from '@assets/images/time-clock.svg';
import AddImage from "@assets/images/e-add.svg";
import NoOrdersImage from "@assets/images/no-orders.svg"
import ReportsImage from "@assets/images/chart-pie-36.svg";
import AvatarImage from "@assets/images/avatar.svg";
import ArrowRightImage from "@assets/images/arrow-right-black.svg";

import {Links} from "@app/links";

import {RideReport} from "@models/travelAllowance/rideReport";

import {getRideReports} from "@services/travelAllowance/rideReport.service";

type RideReportsPaneProps = {
    topEdge?: number;
}

const RideReportsPane: React.FC<RideReportsPaneProps> = ({topEdge}: RideReportsPaneProps) => {
    const {t} = useTranslation();
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [reports, setReports] = useState<RideReport[]>([]);

    useEffect(() => {
        setLoading(true);

        fetchRideReports()
            .then(() => {
                setLoading(false);
            });
    }, [history.location.pathname]);

    const fetchRideReports = async () => {
        const rideReportsData = await getRideReports();

        setReports(rideReportsData);
    }

    return (
        <Pane topEdge={topEdge} marginTop={40} paddingBottom={147}>
            <StyledSocialButton className="inverted"
                                onClick={() => history.push(Links.main + Links.travelAllowance.ride.add)}>
                <img src={AddImage}/>
                {t('travelAllowance.ride.add')}
            </StyledSocialButton>
            {
                !loading && reports && reports.length === 0 &&
                <StyledNoOrders>
                    <img src={NoOrdersImage}/>
                    <img className="reports" src={ReportsImage}/>
                    <span className="message">{t('reportsTab.orders.noReports')}</span>
                    <StyledSocialButton className="auto-width"
                                        onClick={() => history.push(Links.main + Links.travelAllowance.ride.list)}>
                        {t('travelAllowance.ride.list')}
                    </StyledSocialButton>
                </StyledNoOrders>
            }
            {
                !loading && reports && reports.length !== 0 &&
                <StyledOrders>
                    {
                        reports.map((report: RideReport, i) => {
                                return (
                                    <div key={i} className="ride-report">
                                        <div className="ride-report-small">
                                            <IonIcon src={ClockImage}/>
                                            {t('travelAllowance.report.billingMonth')}&nbsp;{report.billingMonth}
                                        </div>
                                        <div className="ride-report-number">
                                            {t('travelAllowance.report.reportNumber')}&nbsp;{report.number}
                                            <img src={ArrowRightImage}/>
                                        </div>
                                        <div className="members"
                                             style={{
                                                 display: "flex",
                                                 alignItems: "center",
                                                 gap: "10px",
                                                 margin: "0",
                                                 marginTop: "16px"
                                             }}>
                                            <img src={AvatarImage}/>
                                            <span style={{
                                                fontWeight: "bold",
                                                fontSize: "14px"
                                            }}>{report.company.name}</span>
                                        </div>
                                        {
                                            report.status === 'WAITING' &&
                                            <StyledButton
                                                onClick={() => {
                                                    history.push(Links.travelAllowance.rideReport.preview + '/' + report.id);
                                                }}
                                            >
                                                {t('travelAllowance.report.sign')}
                                            </StyledButton>
                                        }
                                        {
                                            report.status === 'ACCEPTED' &&
                                            <StyledButton disabled={true}>
                                                {t('travelAllowance.report.status.accepted')}
                                            </StyledButton>
                                        }
                                        {
                                            report.status === 'REJECTED' &&
                                            <StyledRedButton>
                                                {t('travelAllowance.report.status.rejected')}
                                            </StyledRedButton>
                                        }
                                    </div>
                                )
                            }
                        )
                    }
                </StyledOrders>
            }
            {
                loading && <ReportsSkeleton/>
            }
        </Pane>
    );
};

export default RideReportsPane;
