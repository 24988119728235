import React from 'react';
import {IonCol, IonIcon, IonRow, IonSelect} from '@ionic/react';
import {useTranslation} from 'react-i18next';

import Accordion from "@components/accordion/accordion";
import DateInput from '@components/form/input/date.component';
import CityAutocomplete from "@components/google/cityAutocomplete/cityAutocomplete.component";

import CalendarIcon from '@assets/images/travelAllowance/calendar.svg';

import {Passenger, Ride} from "@models/travelAllowance/ride";

import {StyledInput, StyledInputGroup, StyledInputUnit} from "@components/form/input/input.style";

type RidePreviewFormFieldsProps = {
    ride: Ride
}

const RidePreviewFormFields: React.FC<RidePreviewFormFieldsProps> = ({ride}: RidePreviewFormFieldsProps) => {
    const {t} = useTranslation();

    return (
        <>
            <Accordion isOpen={true} allowToggle={true}
                       title={t('travelAllowance.ride.generalData')}>
                <IonRow>
                    <IonCol size="12" className="label mt-8 required">
                        {t('travelAllowance.ride.startDate')}
                    </IonCol>
                </IonRow>
                <StyledInputGroup>
                    <IonCol size="10" className="label">
                        <DateInput
                            disabled={true}
                            presentation="date"
                            format="DD.MM.YYYY"
                            onChange={() => {
                            }}
                            value={ride.dateStart}
                        />
                    </IonCol>
                    <IonCol size="2" className="label">
                        <StyledInputUnit icon={true}>
                            <IonIcon slot="icon-only" icon={CalendarIcon}/>
                        </StyledInputUnit>
                    </IonCol>
                </StyledInputGroup>
                <IonRow>
                    <IonCol size="12" className="label required">
                        {t('travelAllowance.ride.startingPlace')}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" className="label">
                        <CityAutocomplete disabled={true} defaultValue={ride.rideRoute.startingPlace}/>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" className="label mt-8 required">
                        {t('travelAllowance.ride.destinationPlace')}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" className="label">
                        <CityAutocomplete disabled={true} defaultValue={ride.rideRoute.destinationPlace}/>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" className="label mt-8 required">
                        {t('travelAllowance.ride.distance')}
                    </IonCol>
                </IonRow>
                <StyledInputGroup>
                    <IonCol size="10" className="label">
                        <StyledInput disabled={true} defaultValue={ride.rideRoute.distance}/>
                    </IonCol>
                    <IonCol size="2" className="label">
                        <StyledInputUnit>km</StyledInputUnit>
                    </IonCol>
                </StyledInputGroup>
                <IonRow>
                    <IonCol size="12" className="label mt-8 required">
                        {t('travelAllowance.ride.coordinator')}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" className="select-full-width">
                        <IonSelect disabled={true}
                                   placeholder={ride.rideRoute.coordinator.name}></IonSelect>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" className="label mt-8 required">
                        {t('travelAllowance.ride.placeOfService')}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" className="select-full-width">
                        <IonSelect disabled={true}
                                   placeholder={ride.rideRoute.placeOfService.name}></IonSelect>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" className="label mt-8 required">
                        {t('travelAllowance.ride.car')}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" className="select-full-width">
                        <IonSelect disabled={true}
                                   placeholder={`${ride.car.brand} ${ride.car.model} ${ride.car.licensePlate}`}></IonSelect>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" className="label required">
                        {t('travelAllowance.ride.rate')}
                    </IonCol>
                </IonRow>
                <StyledInputGroup>
                    <IonCol size="10" className="label">
                        <StyledInput disabled={true} value={ride.rideCost?.rate}/>
                    </IonCol>
                    <IonCol size="2" className="label">
                        <StyledInputUnit>{ride.rideCost?.currency}/km</StyledInputUnit>
                    </IonCol>
                </StyledInputGroup>
                <IonRow>
                    <IonCol size="12" className="label required">
                        {t('travelAllowance.ride.additionalCost')}
                    </IonCol>
                </IonRow>
                <StyledInputGroup>
                    <IonCol size="10" className="label">
                        <StyledInput value={ride.rideCost?.additionalCost || '-'}
                                     disabled={true}/>
                    </IonCol>
                    <IonCol size="2" className="label">
                        <StyledInputUnit>{ride.rideCost?.currency || '-'}</StyledInputUnit>
                    </IonCol>
                </StyledInputGroup>
                <IonRow>
                    <IonCol size="12" className="label required">
                        {t('travelAllowance.ride.cost')}
                    </IonCol>
                </IonRow>
                <StyledInputGroup>
                    <IonCol size="10" className="label">
                        <StyledInput disabled={true} value={ride.rideCost?.amount}/>
                    </IonCol>
                    <IonCol size="2" className="label">
                        <StyledInputUnit>{ride.rideCost?.currency}</StyledInputUnit>
                    </IonCol>
                </StyledInputGroup>
            </Accordion>
            <Accordion isOpen={true} allowToggle={true} title={t('travelAllowance.ride.passengers')}>
                {
                    ride.passengers.map((passenger: Passenger, index: number) => {
                        return (
                            <div key={passenger.id}>
                                <IonRow>
                                    <IonCol size="12" className="label mt-8 required">
                                        {t('travelAllowance.ride.passenger')} {index + 1}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12" className="label">
                                        <StyledInput disabled={true} value={passenger.name}/>
                                    </IonCol>
                                </IonRow>
                            </div>
                        )
                    })
                }
            </Accordion>
            <Accordion isOpen={true} allowToggle={true} title={t('travelAllowance.ride.commentForCoordinator')}>
                {
                    <section>
                        <IonRow>
                            <IonCol size="12" className="label mt-8 required">
                                {t('travelAllowance.ride.commentForCoordinator')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="label">
                                <StyledInput disabled={true} defaultValue={ride.commentForCoordinator}/>
                            </IonCol>
                        </IonRow>
                    </section>
                }
            </Accordion>
        </>
    );
};

export default RidePreviewFormFields;
