import React, { useEffect, useRef, useState } from 'react';
import { IonCol, IonHeader, IonPage, IonRow} from '@ionic/react';
import { StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../../../components/content/content.style';
import SubNavigation, { SubNavigationOption } from '../../../../components/sub-navigation/subNavigation.component';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useNavigation from '../../../../services/navigation.service';
import Pane from '../../../../components/pane/pane.component';
import { SearchInput } from '../../../../components/form/input/input.style';
import { StyledAvatar, StyledNotification } from '../notifications/notificationsTab.style';
import AvatarImage from '../../../../assets/images/avatar.svg';
import AddImage from '../../../../assets/images/add.svg';
import EditImage from '../../../../assets/images/s-edit.svg';
import PhoneImage from '../../../../assets/images/phone.svg';
import TruncateText from "../../../../components/truncateText/truncateText.component";
import { StyledChip, StyledDetailsView, StyledEditButton, StyledPeopleBar } from './communicatorTab.style';
import { StyledButton, StyledButtonBadge } from '../../../../components/button/button.style';
import { Links } from '../../../links';

export enum CommunicatorTabOptions {
    MESSAGES = 0,
    PEOPLE = 1
}

enum ContractStatus {
    ACTIVE = 1,
    EXPIRING,
    EXPIRED
}

export type Person = {
    id: bigint,
    email: string,
    first_name: string,
    last_name: string,
    preferences: string,
    active: boolean,
    city: string,
    phone: string,
    department: string,
    contractStatus?: ContractStatus,
    photos: {
        thumbnail: string,
        original: string,
    }
};

type Message = {
    author: Person,
    lastMessage: {
        time: string,
        value: string
    },
    unreadCount: number
};

const CommunicatorTab: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);
    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [selectedPerson, setSelectedPerson] = useState<number | undefined>(undefined);

    const options = [{
        name: t("communicatorTab.messagesOption")
    },
    {
        name: t("communicatorTab.peopleOption")
    }];

    useEffect(() => {
        updateHeight();
    });

    const people: Person[] = [
        {
            id: BigInt(0),
            active: true,
            city: '',
            email: '',
            first_name: 'Andrzej',
            last_name: 'Dudek',
            phone: '',
            preferences: '',
            department: 'Dział księgowy',
            contractStatus: ContractStatus.ACTIVE,
            photos: {
                original: '',
                thumbnail: ''
            }
        },
        {
            id: BigInt(1),
            active: false,
            city: '',
            email: '',
            first_name: 'Marta',
            last_name: 'Lempart',
            phone: '',
            preferences: '',
            department: 'Dział prawny',
            photos: {
                original: '',
                thumbnail: ''
            }
        },
        {
            id: BigInt(0),
            active: true,
            city: '',
            email: '',
            first_name: 'Krystyna',
            last_name: 'Pawłowska',
            phone: '',
            preferences: '',
            department: 'Kierownik zmiany',
            photos: {
                original: '',
                thumbnail: ''
            }
        },
        {
            id: BigInt(0),
            active: true,
            city: '',
            email: '',
            first_name: 'Andrzej',
            last_name: 'Dudek',
            phone: '',
            preferences: '',
            department: 'Dział księgowy',
            photos: {
                original: '',
                thumbnail: ''
            }
        },
        {
            id: BigInt(1),
            active: false,
            city: '',
            email: '',
            first_name: 'Marta',
            last_name: 'Lempart',
            phone: '',
            preferences: '',
            department: 'Dział księgowy',
            contractStatus: ContractStatus.EXPIRED,
            photos: {
                original: '',
                thumbnail: ''
            }
        },
        {
            id: BigInt(0),
            active: true,
            city: '',
            email: '',
            first_name: 'Krystyna',
            last_name: 'Pawłowska',
            phone: '',
            preferences: '',
            department: 'Dział księgowy',
            contractStatus: ContractStatus.EXPIRING,
            photos: {
                original: '',
                thumbnail: ''
            }
        },
        {
            id: BigInt(0),
            active: true,
            city: '',
            email: '',
            first_name: 'Andrzej',
            last_name: 'Dudek',
            phone: '',
            preferences: '',
            department: 'Dział księgowy',
            photos: {
                original: '',
                thumbnail: ''
            }
        },
        {
            id: BigInt(1),
            active: false,
            city: '',
            email: '',
            first_name: 'Marta',
            last_name: 'Lempart',
            phone: '',
            preferences: '',
            department: 'Dział księgowy',
            photos: {
                original: '',
                thumbnail: ''
            }
        },
        {
            id: BigInt(0),
            active: true,
            city: '',
            email: '',
            first_name: 'Krystyna',
            last_name: 'Pawłowska',
            phone: '',
            preferences: '',
            department: 'Dział księgowy',
            photos: {
                original: '',
                thumbnail: ''
            }
        },
        {
            id: BigInt(0),
            active: true,
            city: '',
            email: '',
            first_name: 'Andrzej',
            last_name: 'Dudek',
            phone: '',
            preferences: '',
            department: 'Dział księgowy',
            photos: {
                original: '',
                thumbnail: ''
            }
        },
        {
            id: BigInt(1),
            active: false,
            city: '',
            email: '',
            first_name: 'Marta',
            last_name: 'Lempart',
            phone: '',
            preferences: '',
            department: 'Dział księgowy',
            photos: {
                original: '',
                thumbnail: ''
            }
        },
        {
            id: BigInt(0),
            active: true,
            city: '',
            email: '',
            first_name: 'Krystyna',
            last_name: 'Pawłowska',
            phone: '',
            preferences: '',
            department: 'Dział księgowy',
            photos: {
                original: '',
                thumbnail: ''
            }
        }
    ];

    const messages: Message[] = [
        {
            author: {
                id: BigInt(0),
                active: true,
                city: '',
                email: '',
                first_name: 'Andrzej',
                last_name: 'Dudek',
                phone: '',
                preferences: '',
                department: 'Dział księgowy',
                photos: {
                    original: '',
                    thumbnail: ''
                }
            },
            lastMessage: {
                time: '22:05',
                value: 'Dokumenty zostały wysłane do Pana wczoraj kurierem'
            },
            unreadCount: 5
        },
        {
            author: {
                id: BigInt(1),
                active: false,
                city: '',
                email: '',
                first_name: 'Marta',
                last_name: 'Lempart',
                phone: '',
                preferences: '',
                department: 'Kierownik zmiany',
                photos: {
                    original: '',
                    thumbnail: ''
                }
            },
            lastMessage: {
                time: '21:35',
                value: 'Proszę pamiętać o zaraportowaniu godzin'
            },
            unreadCount: 2
        },
        {
            author: {
                id: BigInt(0),
                active: true,
                city: '',
                email: '',
                first_name: 'Krystyna',
                last_name: 'Pawłowska',
                phone: '',
                preferences: '',
                department: 'Dział prawny',
                photos: {
                    original: '',
                    thumbnail: ''
                }
            },
            lastMessage: {
                time: '19:05',
                value: 'Tak, jutro podpiszę umowę o pracę tymczasową z Panem...'
            },
            unreadCount: 0
        }
    ];

    const [selectedOption, updateSelectedOption] = useState<number>(parseInt(navigation.getParam<string>('selectedOption')) || CommunicatorTabOptions.MESSAGES);
    const handleSelect = (option: SubNavigationOption) => {
        const optionIndex = options.indexOf(option);
        if (optionIndex === selectedOption) {
            return;
        }

        navigation.setParam('selectedOption', optionIndex.toString());
        updateSelectedOption(optionIndex);
    };

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const getStatus = (person: Person) => {
        return <>
            {!person.contractStatus && 
                <StyledChip>
                    {person.department}
                </StyledChip>
            }
            {person.contractStatus === ContractStatus.ACTIVE && 
                <StyledChip className="green">
                    {t("communicatorTab.contractActive")}
                </StyledChip>
            }
            {person.contractStatus === ContractStatus.EXPIRING && 
                <StyledChip className="orange">
                    {t("communicatorTab.contractExpiring")}
                </StyledChip>
            }
            {person.contractStatus === ContractStatus.EXPIRED && 
                <StyledChip className="red">
                    {t("communicatorTab.contractExpired")}
                </StyledChip>
            }
        </>;
    }
    
    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <StyledIonTitle className="left">{t("communicatorTab.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                    <StyledPeopleBar>
                        <StyledAvatar>
                            <img src={AddImage} />
                        </StyledAvatar>
                        { people.map((person, key) => 
                            <StyledAvatar key={key} className={'dark ' + (person.active ? 'active' : '')}>
                                { person.photos.thumbnail ? <img src={person.photos.thumbnail} /> : <img src={AvatarImage} />}
                            </StyledAvatar>
                            )
                        }
                    </StyledPeopleBar>
                    <SearchInput margin={24} color="dark" placeholder={t("communicatorTab.search")}></SearchInput>
                </IonHeader>
                <Pane topEdge={topEdge} marginTop={40} paddingBottom={97}>
                    <SubNavigation options={options} light={true} marginTop={0}
                                    defaultValue={selectedOption || CommunicatorTabOptions.MESSAGES}
                                    onSelect={handleSelect}></SubNavigation>

                    { selectedOption === CommunicatorTabOptions.MESSAGES && messages.map((message, key) =>  
                        <StyledNotification key={key}>
                            <StyledAvatar className={(message.author.active ? 'active' : '')}>
                                { message.author?.photos.thumbnail ? <img src={message.author.photos.thumbnail} /> : <img src={AvatarImage} />}
                            </StyledAvatar>
                            <div className="content">
                                <div className="title">
                                    <span className="time" >{message.lastMessage.time}</span>
                                    {message.author.first_name} {message.author.last_name}
                                </div>
                                <div className={"description " + (message.unreadCount > 0 ? "padding-right" : "")}>
                                    <TruncateText lines={2}>{message.lastMessage.value}</TruncateText>
                                </div>
                            </div>
                            {message.unreadCount > 0 && <StyledButtonBadge className="top-margin">{message.unreadCount}</StyledButtonBadge>}
                        </StyledNotification>
                    )}
                    { selectedOption === CommunicatorTabOptions.PEOPLE && 
                        <>
                        <StyledButton className="margin-bottom" onClick={() => history.push(Links.sendPushMessage)}>{t("communicatorTab.sendPushMessage")}</StyledButton>
                        { people.map((person, key) =>  
                            <React.Fragment key={key}>
                                {selectedPerson !== key &&
                                    <StyledNotification className="center" key={`not_${key}`} onClick={() => setSelectedPerson(key)}>
                                        <StyledAvatar className={(person.active ? 'active' : '')}>
                                            { person.photos.thumbnail ? <img src={person.photos.thumbnail} /> : <img src={AvatarImage} />}
                                        </StyledAvatar>
                                        <div className="content">
                                            <div className="title">
                                                {person.first_name} {person.last_name}
                                            </div>
                                            <div className="description padding-right">
                                                {getStatus(person)}
                                            </div>
                                        </div>
                                        <StyledEditButton>
                                            <img src={EditImage} />
                                        </StyledEditButton>
                                    </StyledNotification>
                                }
                                {
                                    selectedPerson === key &&
                                    <StyledDetailsView key={`det_${key}`}>
                                        <IonRow>
                                            <IonCol size="2" offset="2">
                                                <StyledEditButton>
                                                    <img src={PhoneImage} />
                                                </StyledEditButton>
                                            </IonCol>
                                            <IonCol size="4">
                                                <StyledAvatar className={(person.active ? 'active' : '')}>
                                                    { person.photos.thumbnail ? <img src={person.photos.thumbnail} /> : <img src={AvatarImage} />}
                                                </StyledAvatar>
                                            </IonCol>
                                            <IonCol size="2">
                                                <StyledEditButton>
                                                    <img src={EditImage} />
                                                </StyledEditButton>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol size="8" offset="2">
                                                {person.first_name} {person.last_name}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol size="8" offset="2">
                                                {getStatus(person)}
                                            </IonCol>
                                        </IonRow>
                                    </StyledDetailsView>
                                }
                            </React.Fragment>
                        )}
                    </>}
                </Pane>
            </StyledIonContent>
        </IonPage>
    );
};

export default CommunicatorTab;