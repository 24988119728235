import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {IonButtons, IonHeader, IonPage, useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';
import {StyledIonContentCameraPreview, StyledIonTitle, StyledIonToolbar} from '../../components/content/content.style';
import {StyledButton, StyledHeaderButton, StyledHeaderButtonImage, StyledWiteButton} from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import TakePhotoImage from '../../assets/images/camera-2.svg';
import CameraPreviewImage from '../../assets/images/takePhoto3.png';
import {useTranslation} from 'react-i18next';
import {StyledContent} from './takePhotoPage.style';
// import {CameraPreviewOptions, CameraPreviewPictureOptions, CameraPreview} from '@capacitor-community/camera-preview';
import {ContractAttachmentFolder} from "../../models/contractAttachmentFolder";
import {changeBase46ToBlob, generateFileName} from "../../services/file.service";
import {uploadPhoto} from "../../services/contract.service";

// const cameraPreviewOpts: CameraPreviewOptions = {
//     x: 0,
//     y: window.screen.height * 0.24,
//     width: window.screen.width,
//     height: window.screen.height - (window.screen.height * 0.24),
//     position: 'rear',
//     toBack: true,
//     rotateWhenOrientationChanged: false
// }

interface TakePhotoStateInterface {
    contractId: bigint,
    folder: ContractAttachmentFolder,
}
// deprecated
const TakePhotoPage: React.FC = () => {

    const {t} = useTranslation();
    const history = useHistory();

    const handleBack = () => {
        history.goBack();
    };

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [cameraStarted, updateCameraStarted] = useState<boolean>(false);
    const [preview, updatePreview] = useState<string>('');

    const location = useLocation();
    const state = location.state as TakePhotoStateInterface;
    useEffect(() => {
        updateHeight();
    })

    const startCamera = () => {
        if (!cameraStarted) {
            updateCameraStarted(true);
            // start camera
            // CameraPreview.start(cameraPreviewOpts);
        }
    };
    
    const stopCamera = async () => {
        // await CameraPreview.stop();
        updateCameraStarted(false);
    };

    useIonViewWillEnter(() => {
        startCamera();
    });

    useIonViewWillLeave(() => {
        stopCamera();
    });

    const takePicture = async () => {
        // const cameraPreviewPictureOptions: CameraPreviewPictureOptions = {
        //     quality: 90,
        //     width: cameraPreviewOpts.width,
        //     height: cameraPreviewOpts.height,
        // };
        // const result = await CameraPreview.capture(cameraPreviewPictureOptions);
        //
        // if (result.value && state.contractId && state.folder) {
        //     updatePreview(result.value);
        // }
    };

    const addFile = async () => {
        let blob = changeBase46ToBlob(preview);
        await uploadPhoto(state.contractId, state.folder.id, blob, generateFileName(state.folder.name))
            .then(response => {
                history.goBack();
            });
    };

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

    return (
        <IonPage>
            <StyledIonContentCameraPreview>
                <img className="background" src={CameraPreviewImage}/>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("takePhotoPage.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                    <div className="sub-title-light">{t("takePhotoPage.subTitle")}</div>
                </IonHeader>
                <StyledContent>
                    <div className="target">
                    </div>
                    {preview && <img className="preview" src={'data:image/jpeg;base64,' + preview}/>}
                    <div className={'action-buttons ' + (preview ? 'preview' : '')}>
                        {!preview && <button className="take-photo" onClick={() => takePicture()}>
                            <img src={TakePhotoImage}/>
                        </button>}
                        {!preview && <span className="description">{t("takePhotoPage.buttonDescription")}</span>}
                        {preview && <StyledWiteButton onClick={() => updatePreview('')}>{t("takePhotoPage.retry")}</StyledWiteButton>}
                        {preview && <StyledButton onClick={() => addFile()}>{t("takePhotoPage.addFile")}</StyledButton>}
                    </div>
                </StyledContent>
            </StyledIonContentCameraPreview>
        </IonPage>
    );
};

export default TakePhotoPage;
