import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyledIonModal, StyledModalContent} from '../../../components/modal/modal.style';
import Form from '../../../components/form';
import VectorImage from '../../../assets/images/checkMarkGreen.svg';
import {StyledDataTable, StyledEmptyContainer} from '../../../components/content/content.style';
import {IonContent, IonSearchbar} from '@ionic/react';
import ManualModal from './manualModal.component';
import {DataSelectable} from "../components/report/reportRowForm.component";
import CloseImage from "../../../assets/images/e-remove.svg";
import {useForm} from "react-hook-form";
import CircleImage, {ImageTypes} from "../../../components/circleImage/circleImage.component";

type ListModalProps = {
    isOpen: boolean;
    list?: DataSelectable[];
    moreList?: DataSelectable[];
    allowManual?: boolean;
    selected?: string;
    onClose: () => void;
    onSubmit: (selected: DataSelectable) => void;
    translate?: boolean,
    search?: boolean,
    emptyState?: boolean,
};

const ListModal: React.FC<ListModalProps> = (props: ListModalProps) => {

    const {t} = useTranslation();

    const [showList, updateShowList] = useState<boolean>(false);
    const [showManualModal, updateShowManualModal] = useState<boolean>(false);
    const [selected, setSelected] = useState<string | undefined>(props.selected);
    const [list, setList] = useState<DataSelectable[] | undefined>(props.list);
    const [initList, setInitList] = useState<DataSelectable[] | undefined>(props.list);
    const [moreList, setMoreList] = useState<DataSelectable[] | undefined>(props.moreList);
    const [initMoreList, setInitMoreList] = useState<DataSelectable[] | undefined>(props.moreList);
    const [showMoreList, setShowMoreList] = useState<boolean|undefined>();

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();

        if (props.isOpen) {
            updateShowManualModal(false);
            setSelected(props.selected);
            setList(props.list);
            setInitList(props.list);
            setMoreList(props.moreList);
            setInitMoreList(props.moreList);
        }
    }, [props.isOpen]);

    useEffect(() => {
        setShowMoreList(props.list && props.list.length === 0)
        if (props.moreList) {
            props.moreList.forEach((item: DataSelectable) => {
                if (!showMoreList) {
                    setShowMoreList(item.id === selected);
                }
            });
        }
    }, [props.list, props.moreList]);

    const handleOpen = () => {
        updateShowList(props.isOpen);
    };

    const [searchText, setSearchText] = useState('');
    const searchbar = useRef<HTMLIonSearchbarElement>(null);

    useEffect(() => {
        if (searchText) {
            if (initList) {
                let tempSearchResult = initList?.filter(ele => ele.name.toLowerCase().includes(searchText.toLowerCase()));
                setList([...tempSearchResult]);
            }
        } else {
            setList(initList);
        }

        if (initMoreList) {
            if (searchText) {
                let tempMoreSearchResult = initMoreList?.filter(ele => ele.name.toLowerCase().includes(searchText.toLowerCase()));
                setMoreList([...tempMoreSearchResult]);
            } else {
                setMoreList(initMoreList);
            }
        }

    }, [searchText])

    const {handleSubmit} = useForm({
        mode: 'onChange'
    });

    const onSubmit = () => {
        if (searchbar && searchbar.current?.value) {
            setSearchText(searchbar.current.value);
        }
    };

    return (
        <>
            <StyledIonModal
                isOpen={showList}
                onDidDismiss={() => handleClose()}
                initialBreakpoint={0.99}
                breakpoints={[0, 0.65, 0.99]}
            >
                <Form.Container className="modalForm" onSubmit={handleSubmit(onSubmit)}>
                    <StyledModalContent className="modal-wrap t-p-50 scrollable">
                            <img src={CloseImage} className="close" onClick={() => handleClose() } />
                            {props.search && <IonSearchbar
                                    className={'listSearch'}
                                    ref={searchbar}
                                    debounce={500}
                                    onIonChange={e => setSearchText(e.detail.value!)}
                                    onIonInput={e => setSearchText(e.detail.value!)}
                                    placeholder={t('reportPage.listModal.search')}
                                />
                            }

                            <StyledDataTable className="no-margin">
                                { list && list.map((data, i) => <div key={i} className="data-row" onClick={() => props.onSubmit(data)}>
                                                                    <div className="data-icon-title">{props.translate ? t(data.name) : data.name}</div>
                                                                    <div className="data-action">
                                                                        {selected === data.id && <img src={VectorImage} />}
                                                                    </div>
                                                                </div>)
                                }
                                {
                                    !showMoreList && moreList && moreList.length > 0 &&
                                    <div className="data-row" onClick={() => {setShowMoreList(true)}}>
                                        <div className="data-icon-title blue">{t('reportPage.listModal.showMoreList')}</div>
                                    </div>
                                }
                                { showMoreList && moreList && moreList.map((data, i) => <div key={i} className="data-row" onClick={() => props.onSubmit(data)}>
                                                                    <div className="data-icon-title">{props.translate ? t(data.name) : data.name}</div>
                                                                    <div className="data-action">
                                                                        {selected === data.id && <img src={VectorImage} />}
                                                                    </div>
                                                                </div>)
                                }
                                {props.emptyState && list?.length == 0 &&
                                    <StyledEmptyContainer>
                                        <CircleImage image={ImageTypes.NOTE} color="grey"/>
                                        <h3>{t('reportPage.listModal.empty')}</h3>
                                    </StyledEmptyContainer>
                                }
                                { props.allowManual &&
                                    <div className="data-row" onClick={() => {updateShowManualModal(true)}}>
                                        <div className="data-icon-title blue">{t('reportPage.listModal.manualInput')}</div>
                                    </div>
                            }
                            </StyledDataTable>
                    </StyledModalContent>
                </Form.Container>
            </StyledIonModal>
            <ManualModal isOpen={showManualModal} onClose={() => updateShowManualModal(false)} onSubmit={(data) => {
                updateShowManualModal(false);
                let manual:DataSelectable = {
                    id: data,
                    name: data,
                    manual: true
                };
                props.onSubmit(manual)
            }} />
        </>
    );
};

export default ListModal;