import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IonButtons, IonHeader, IonPage} from "@ionic/react";
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from "../../components/content/content.style";
import {RouteComponentProps, useHistory} from "react-router-dom";
import InconsistencyModuleDetailsPane from "./panes/inconcistencyModuleDetailsPane.component";
import {StyledHeaderButton, StyledHeaderButtonImage} from "../../components/button/button.style";
import ArrowLeftImage from "../../assets/images/arrow-left.svg";

interface RouterProps {
	id: string;
}

interface ReportProps extends RouteComponentProps<RouterProps> {
}

const InconsistencyDetailsPage: React.FC<ReportProps> = ({match}) => {
	const { t } = useTranslation();
	const history = useHistory();

	const header = useRef<HTMLIonHeaderElement>(null);
	const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
	const [showSummary, setShowSummary] = useState<boolean>()

	const {params: {id}} = match;

	useEffect(() => {
		updateHeight();
	},[]);

	const updateHeight = () => {
		if (header.current ?.clientHeight == 0) {
			setTimeout(updateHeight);
		} else {
			updateTopEdge(header.current?.clientHeight);
		}
	};

	const handleBack = () => {
		history.goBack();
	};

	return (
		<IonPage>
			<StyledIonContent>
				<IonHeader ref={header} className="ion-no-border">
					<StyledIonToolbar>
						<IonButtons slot="start">
							<StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
								<StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
							</StyledHeaderButton>
						</IonButtons>
						<StyledIonTitle className="wrap-text">
							<div className="wrap-text">
								{t('inconsistencyTab.title')}
							</div>
						</StyledIonTitle>
					</StyledIonToolbar>
				</IonHeader>
				<InconsistencyModuleDetailsPane topEdge={topEdge} id={id} />
			</StyledIonContent>
		</IonPage>
	);
};

export default InconsistencyDetailsPage;
