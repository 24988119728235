import {appApi} from "./api.service";
import {ContractData} from "../models/contractData";
import {Preferences} from "@capacitor/preferences";
import {WorkerWarehouseTransfer} from "../models/worker";

let endpointPrefix = 'worker/courses';

const getCoursesLoginLink = async () => {
	return appApi.get(endpointPrefix + '/get-login-link');
}

const setReadMoodleInfo = async () => {
	await Preferences.set({
		'key': 'read_moodle_info',
		'value': '1'
	});
	return appApi.post(endpointPrefix + '/set-read-moodle-info');
}

export {
	getCoursesLoginLink,
	setReadMoodleInfo
};
