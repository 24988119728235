import React, {useContext, useEffect, useRef, useState} from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {IonButtons, IonHeader, IonSkeletonText, IonPage, useIonViewDidEnter} from '@ionic/react';
import SubNavigation, {SubNavigationOption} from '../../components/sub-navigation/subNavigation.component';
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '../../components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage,} from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import ClockImage from '../../assets/images/time-clock.svg';
import {useTranslation} from 'react-i18next';
import {getLoan} from "../../services/loan.service";
import {Contract as ContractModel} from '../../models/contract';
import {Loan as LoanModel} from "../../models/loan";
import moment from "moment";
import {LoanStatus} from "../../enums/loanStatus";
import ContentPane from "./panes/contentPane.component";
import useNavigation from '../../services/navigation.service';
import { Links } from '../links';
import RegulationsPane from "./panes/regulationsPane.component";
import StaticPane from "../../components/pane/static-pane.component";

export enum LoanPageOptions {
    LOAN_CONTENT = 0,
    REGULATIONS = 1
}

interface RouterProps {
    loanId?: string | undefined;
}

interface LoanProps extends RouteComponentProps<RouterProps> {
}

interface LoanPageManager {
    setStatus: Function;
}

const contextLoanPage: LoanPageManager = {
    setStatus: () => {

    }
};

export const LoanPageContext = React.createContext<LoanPageManager>(contextLoanPage);

const LoanPage: React.FC<LoanProps> = ({match}) => {
        const {t} = useTranslation();
        const history = useHistory();
        const [loading, setLoading] = useState<boolean>(false);
        const navigation = useNavigation(history);
        const [loan, setLoan] = useState<LoanModel>();
        const [status, setStatus] = useState<LoanStatus>();
        const [selectedOption, updateSelectedOption] = useState<number>(parseInt(navigation.getParam<string>('selectedOption')) || 0);

        const {params: {loanId}} = match;

        const contextLoanPage = useContext(LoanPageContext);
        contextLoanPage.setStatus = setStatus;

        const fetchLoan = async () => {
            if (match.params.loanId) {
                setLoading(true);
                await getLoan(match.params.loanId)
                    .then(response => {
                        let loan = response.data.loan as LoanModel;
                        loan.content = response.data.loan_content;
                        loan.regulations_content = response.data.regulations_content;
                        setLoan(loan);
                        setStatus(loan.sign_status);
                    })
                    .catch(error => {
                        // todo what we show??
                    });

                setLoading(false);
            }
        };

        useEffect(() => {
            fetchLoan();
        }, [match.params.loanId]);

        useIonViewDidEnter(() => {
            fetchLoan();
        })

        const handleSelect = (option: SubNavigationOption) => {
            const optionIndex = options.indexOf(option);
            navigation.setParams({
                'selectedOption': optionIndex.toString()
            });
            updateSelectedOption(optionIndex);
        };

        // @TODO: trzeba kliknac 2x zeby wrocic na historie pozyczek
        const handleBack = () => {
            history.goBack();
        };

        let [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

        useEffect(() => {
            updateHeight();
        });

        const updateHeight = () => {
            if (header.current?.clientHeight == 0) {
                setTimeout(updateHeight);
            } else {
                updateTopEdge(header.current?.clientHeight);
            }
        };

        const header = useRef<HTMLIonHeaderElement>(null);

        const options = [
            {
                name: t("loanPage.optionLoanContent"),
            },
            {
                 name: t("loanPage.optionRegulations"),
            }
        ];

        return (
            <IonPage>
                <StyledIonContent>
                    <IonHeader ref={header} className="ion-no-border">
                        <StyledIonToolbar>
                            <IonButtons slot="start">
                                <StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
                                    <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                                </StyledHeaderButton>
                            </IonButtons>
                            <StyledIonTitle>{loan?.number} </StyledIonTitle>
                        </StyledIonToolbar>
                            <div className="sub-title">
                                <small>
                                    <img src={ClockImage}/>
                                    <span>{loan?.date ? moment(loan?.date).format('DD.MM.YYYY') : ''}</span>
                                </small>
                            </div>
                            <SubNavigation options={options}
                                        defaultValue={selectedOption || 0}
                                        onSelect={handleSelect}></SubNavigation>
                    </IonHeader>
                    {
                        !loading && selectedOption === LoanPageOptions.LOAN_CONTENT &&
                        <ContentPane topEdge={topEdge} loan={loan} status={status} />
                    }
                    {
                        selectedOption === LoanPageOptions.REGULATIONS &&
                        <RegulationsPane topEdge={topEdge} loan={loan} />
                    }
                    {
                        loading && selectedOption === LoanPageOptions.LOAN_CONTENT &&
                        <StaticPane topEdge={topEdge}>
                            <div>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                                <IonSkeletonText animated />
                                <IonSkeletonText animated style={{ width: '88%' }} />
                                <IonSkeletonText animated style={{ width: '80%' }} />
                                <IonSkeletonText animated style={{ width: '80%' }} />
                                <IonSkeletonText animated style={{ width: '90%' }} />
                                <IonSkeletonText animated />
                                <IonSkeletonText animated style={{ width: '98%' }} />
                                <IonSkeletonText animated style={{ width: '80%' }} />
                                <IonSkeletonText animated style={{ width: '81%' }} />
                                <IonSkeletonText animated style={{ width: '85%' }} />
                                <IonSkeletonText animated />
                                <IonSkeletonText animated style={{ width: '88%' }} />
                                <IonSkeletonText animated style={{ width: '85%' }} />
                                <IonSkeletonText animated style={{ width: '83%' }} />
                                <IonSkeletonText animated style={{ width: '83%' }} />
                                <IonSkeletonText animated />
                                <IonSkeletonText animated style={{ width: '86%' }} />
                                <IonSkeletonText animated style={{ width: '80%' }} />
                                <IonSkeletonText animated style={{ width: '90%' }} />
                                <IonSkeletonText animated style={{ width: '88%' }} />
                                <IonSkeletonText animated style={{ width: '80%' }} />
                                <IonSkeletonText animated style={{ width: '80%' }} />
                                <IonSkeletonText animated style={{ width: '90%' }} />
                                <IonSkeletonText animated />
                                <IonSkeletonText animated style={{ width: '98%' }} />
                                <IonSkeletonText animated style={{ width: '80%' }} />
                                <IonSkeletonText animated style={{ width: '81%' }} />
                                <IonSkeletonText animated style={{ width: '85%' }} />
                            </div>
                        </StaticPane>
                    }
                </StyledIonContent>
            </IonPage>
        );
    }
;

export default LoanPage;
