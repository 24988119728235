import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    IonButtons,
    IonHeader,
    IonPage,
    useIonViewDidEnter
} from '@ionic/react';
import { StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../components/content/content.style';
import { StyledButton, StyledHeaderButton, StyledHeaderButtonImage } from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import { useForm } from 'react-hook-form';
import { StyledContent } from './sendPushMessagePage.style';
import Form from '../../components/form';
import StaticPane from '../../components/pane/static-pane.component';
import { StyledTextarea } from '../../components/form/input/textarea.style';
import { useTranslation } from 'react-i18next';

const SendPushMessagePage: React.FC = () => {

    const fieldName = 'message';
    const history = useHistory();
    const { t } = useTranslation();

    const handleBack = () => {
        history.goBack();
    };

    const handleSave = async () => {
        const message = getValues(fieldName);
		history.goBack();
    };

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const { register, getValues, handleSubmit } = useForm({
        mode: 'all'
    });

    useEffect(() => {
        updateHeight();
    });

    useIonViewDidEnter(() => {
        document.getElementsByName(fieldName)[0].focus();
    });

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current ?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

	return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle className="one-button">{t("sendPushMessagePage.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} hideGrabber={true}>
                    <Form.Container onSubmit={handleSubmit(handleSave)}>
                        <StyledContent>
                            <div className="label">{t("sendPushMessagePage.message")}</div>
                            <StyledTextarea className="full-width" name={fieldName} ref={register()}></StyledTextarea>
                            <StyledButton onClick={() => handleSave()}>{t("sendPushMessagePage.sendButton")}</StyledButton>
                        </StyledContent>
                    </Form.Container>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default SendPushMessagePage;
