import React, {useEffect, useRef, useState} from 'react';
import {
    IonButtons,
    IonHeader,
    IonItem,
    IonPage,
    IonToast,
    useIonViewDidEnter,
    useIonViewWillEnter
} from '@ionic/react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {RouteComponentProps} from "react-router-dom";
import {
    StyledDataTable,
    StyledIonContent,
    StyledIonTitle,
    StyledIonToolbar
} from '../../../../components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage} from "../../../../components/button/button.style";
import {StyledIonLabel} from "../../../../components/form/input/label.style";
import {StyledIonCheckbox} from "../../../../components/form/input/checkbox.style";
import ArrowLeftImage from '../../../../assets/images/arrow-left.svg';
import CheckImage from "../../../../assets/images/check.svg";
import AvatarImage from "../../../../assets/images/avatar-blue.svg";
import VectorImage from '../../../../assets/images/checkMarkGreen.svg';
import {ProposalUser} from "../../../../models/proposal";
import {getWorkers, postProposal} from "../../../../services/proposal.service";
import {Links} from "../../../links";
import StaticPane from "../../../../components/pane/static-pane.component";
import InvitationUserSkeleton from "./components/invitationUserSkeleton.component";
import WorkerPreviewModal from "../../../../modals/worker/workerPreview.component";
import {Invitation} from "./panes/newInvitationPane.component";
import {b64DecodeUnicode} from "../../../../utils/tools/string";

interface RouterProps {
    data?: string | undefined;
}

interface SelectPeopleProps extends RouteComponentProps<RouterProps> {
}

const SelectPeoplePage: React.FC<SelectPeopleProps> = ({match}) => {
    const {t} = useTranslation();
    const history = useHistory();

    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [showToast, setShowToast] = useState(false);

    const [workers, setWorkers] = useState<ProposalUser[]>();
    const [selectedPeople, updateSelectedPeople] = useState<bigint[]>([]);
    const [selectedWorker, setSelectedWorker] = useState<ProposalUser | null>(null);
    const [onlyActiveParam, setOnlyActiveParam] = useState<boolean>(true);

    const handleBack = () => {
        history.goBack();
    };

    const choosePerson = (personId: bigint) => {
        const personIndex = selectedPeople.indexOf(personId);

        if (personIndex === -1) {
            updateSelectedPeople([...selectedPeople, personId]);
        } else {
            selectedPeople.splice(personIndex, 1);
            updateSelectedPeople([...selectedPeople]);
        }
    };

    const handleSave = async () => {
        if (selectedPeople.length > 0) {
            if (match.params.data) {
                let data = JSON.parse(b64DecodeUnicode(match.params.data)) as Invitation;
                data.workers = selectedPeople;

                await postProposal(data)
                    .then(() => {
                        history.push(Links.main + Links.planning + '?planningSelectedOption=1')
                    });
            }
        } else {
            setShowToast(true);
        }
    };

    useEffect(() => {
        updateHeight();
        updateSelectedPeople([]);
    }, []);

    useIonViewWillEnter(() => {
        updateSelectedPeople([]);
    })

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const fetchWorkers = async () => {
        if (match.params.data) {
            let data = JSON.parse(atob(match.params.data)) as Invitation;

            if (data.project) {
                setLoading(true);
                setWorkers([]);
                await getWorkers(data, onlyActiveParam)
                    .then(response => {
                        setWorkers(response.data);
                    });

                setLoading(false);
            }
        }
    }

    const getUserPhoto = (data: ProposalUser): string => {
        return data.user && (data.user?.photos || data.user?.avatar) ? (data.user.photos?.thumbnail ? data.user.photos.thumbnail : data.user.avatar) : AvatarImage;
    }

    useIonViewDidEnter(() => {
        fetchWorkers()
            .catch((error) => {
                console.error(error);
            });
    });

    useEffect(() => {
        fetchWorkers()
            .catch((error) => {
                console.error(error);
            });
    }, [match.params.data, onlyActiveParam])

    return (
        <IonPage>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={t('planningTab.newInvitationPane.selectAtLeastOneWorker')}
                duration={6000}
                position="top"
                color="danger"
            />
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("selectPeoplePage.title")}</StyledIonTitle>
						<IonButtons slot="end">
							<StyledHeaderButton className="save-btn" onClick={() => handleSave()}>
								<StyledHeaderButtonImage src={CheckImage}></StyledHeaderButtonImage>
							</StyledHeaderButton>
						</IonButtons>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} paddingBottom={52}>
                    <IonItem lines="none">
                        <StyledIonLabel
                            className="ion-text-wrap">{t('selectPeoplePage.onlyActive')}</StyledIonLabel>
                        <StyledIonCheckbox name="onlyActive"
                                           slot="start"
                                           checked={onlyActiveParam}
                                           value={onlyActiveParam}
                                           onIonChange={() => setOnlyActiveParam((prevState) => !prevState)}
                        />
                    </IonItem>
                    <StyledDataTable className="no-margin">
                        {loading &&
                            <>
                                <InvitationUserSkeleton/>
                                <InvitationUserSkeleton/>
                                <InvitationUserSkeleton/>
                                <InvitationUserSkeleton/>
                                <InvitationUserSkeleton/>
                                <InvitationUserSkeleton/>
                                <InvitationUserSkeleton/>
                                <InvitationUserSkeleton/>
                                <InvitationUserSkeleton/>
                                <InvitationUserSkeleton/>
                                <InvitationUserSkeleton/>
                                <InvitationUserSkeleton/>
                                <InvitationUserSkeleton/>
                                <InvitationUserSkeleton/>
                                <InvitationUserSkeleton/>
                            </>
                        }
                        {!loading && workers && workers.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1).map((data, i) =>
                            <div key={i}
                                 className="data-row"
                                 onClick={() => choosePerson(data.id)}
                            >
                                <div className={"data-icon-title " + data.status}>
                                    <img className="avatar"
                                         onClick={(e) => {
                                             e.stopPropagation();
                                             setSelectedWorker(data);
                                         }}
                                         src={getUserPhoto(data)}
                                    />{data.name}
                                </div>
                                <div className="data-action">
                                    {
                                        selectedPeople.includes(data.id) && <img src={VectorImage}/>
                                    }
                                </div>
                            </div>)
                        }
                    </StyledDataTable>
                </StaticPane>
            </StyledIonContent>
            <WorkerPreviewModal worker={selectedWorker}
                                isOpen={!!selectedWorker}
                                onClose={() => setSelectedWorker(null)}
            />
        </IonPage>
    );
};

export default SelectPeoplePage;
