import {CancelTokenSource} from "axios";

import {appApi, travelAllowanceApi} from "@services/api.service";

import {Passenger, Ride, RideFormDataForApi} from "@models/travelAllowance/ride";

import {getWorkerId} from "@utils/tools/worker";

const getRides = async (date: string, cancelToken?: CancelTokenSource): Promise<Ride[]> => {
    const workerId = await getWorkerId();
    const response: any = await travelAllowanceApi.get(`rides/worker/${workerId}`, {
        params: {date: date},
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const getRide = async (rideId: number, cancelToken?: CancelTokenSource) => {
    const workerId = await getWorkerId();
    const response: any = await travelAllowanceApi.get(`rides/${rideId}/worker/${workerId}`, {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const addRide = async (rideData: RideFormDataForApi) => {
    const workerId = await getWorkerId();

    return travelAllowanceApi.post(`rides/worker/${workerId}`, rideData)
}

const editRide = async (rideId: number, rideData: RideFormDataForApi) => {
    const workerId = await getWorkerId();

    return travelAllowanceApi.put(`rides/${rideId}/worker/${workerId}`, rideData)
}

const getPossiblePassengers = async (cancelToken?: CancelTokenSource, page: number = 1, searchText: string = ''): Promise<Passenger[]> => {
    const response: any = await appApi.get(`ride/possible-passengers?page=${page}&searchText=${searchText}`, {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

export {
    getRides,
    getRide,
    addRide,
    editRide,
    getPossiblePassengers,
};
