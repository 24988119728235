import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Pane from '../../../components/pane/pane.component';
import {StyledFooter} from '../../../components/pane/pane.style';
import {ContractStatus} from '../../../enums/contractStatus';
import {
    StyledButton,
    StyledButtonBadge,
    StyledLightBlueButton,
    StyledWiteButton
} from '../../../components/button/button.style';
import {Contract as ContractModel} from '../../../models/contract'
import {IonAlert, IonCol, IonGrid, IonRow, IonToast, getPlatforms, isPlatform} from '@ionic/react';
import TerminateModal from '../modals/terminateModal.component';
import SignModal, {ISignModalConfigration} from '../../../modals/sign/sign.component';
import {
    documentsStatementSign, documentsStatementVerify,
    getContract, getContractAgreementPdf, getContractChangeTypePdf,
    getContractPdf,
    getContractCertificatePdf,
    getContracts, isStarted, rejectContract,
    saveContractComments, saveExitInterview,
    signContract,
    signContractVerify,
    terminateContract,
    terminateContractVerify
} from '../../../services/contract.service';
import RemarksModal from '../modals/remarksModal.component';
import ApprovalsModal from '../modals/approvalsModal.component';
import {ContractComment} from "../../../models/contractComment";
import {ContractsContext} from "./contractsPane.component";
import {Directory, Filesystem, FilesystemDirectory} from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import {blobToBase64, downloadFile} from "../../../services/file.service";
// import { FileOpener } from '@ionic-native/file-opener';
import RemarksListModal from "../modals/remarksListModal.component";
import {ContractPageContext, ContractPageOptions} from "../contractPage.component";
import {ContractCommentsStatus} from "../../../enums/contractCommentsStatus";
import {useHistory} from "react-router-dom";
import useNavigation from "../../../services/navigation.service";
import {SubNavigationContext} from "../../../components/sub-navigation/subNavigation.component";
import TerminationPreviewModal from "../modals/terminationPreviewModal.component";
import {Links} from "../../links";
import {hasPhone} from "../../../services/teamMember.service";
import TeamMemberPhoneModal from "../../../modals/teamMemberPhone/teamMemberPhone.component";
import DocumentStatementGenerateModal from "../modals/documentsStatementGenerateModal.component";
import DocumentsStatementPreviewModal from "../modals/documentsStatementPreviewModal.component";
import ExitInterviewModal from "../modals/exitInterviewModal.component";
import ContractDataApprovalsModal from "../modals/contractDataApprovalModal.component";
import ContractAgreementModal from "../modals/contractAgreementModal.component";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";
import {FileOpener} from "@capacitor-community/file-opener";
import {Device} from "@capacitor/device";
import {ContractExitInterviewQuestion} from "../../../models/contractExitInterview";

type ContentPaneProps = {
    topEdge?: number;
    status?: ContractStatus;
    commentsStatus?: ContractCommentsStatus | null | undefined;
    contract?: ContractModel;
}

const ContentPane: React.FC<ContentPaneProps> = (props: ContentPaneProps) => {
    const context = useContext(ContractsContext);
    const {t} = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);

    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');
    const [showConversation, updateShowConversation] = useState<boolean>(false);
    const [status, setStatus] = useState('');
    const [commentsStatus, setCommentsStatus] = useState<ContractCommentsStatus | null>();
    const [contract, setContract] = useState<ContractModel>();
    const [hasNewComment, setHasNewComment] = useState<boolean>(false);
    const [contentHeight, setContentHeight] = useState<string>('auto');
    const [showTerminate, updateShowTerminate] = useState<boolean>(false);
    const [showTerminateSignModal, updateTerminateSignModal] = useState<boolean>(false);
    const [terminateSignModalConfiguration, updateTerminateSignModalConfiguration] = useState<ISignModalConfigration>();
    const [showDocumentsStatementGenerateModal, updateShowDocumentsStatementGenerateModal] = useState<boolean>(false);
    const [showDocumentsStatementSignModal, updateShowDocumentsStatementSignModal] = useState<boolean>(false);
    const [documentsStatementSignModalConfiguration, updateDocumentsStatementSignModalConfiguration] = useState<ISignModalConfigration>();
    const [showApprovals, updateShowApprovals] = useState<boolean>(false);
    const [showSignModal, updateShowSignModal] = useState<boolean>(false);
    const [showTerminationPreviewModal, updateShowTerminationPreviewModal] = useState<boolean>(false);
    const [showDocumentsStatementPreviewModal, updateShowDocumentsStatementPreviewModal] = useState<boolean>(false);
    const [showPhoneModal, updateShowPhoneModal] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertAgreement, setShowAlertAgreement] = useState(false);
    const [signModalConfiguration, updateSignModalConfiguration] = useState<ISignModalConfigration>();
    const [showAddRemarks, updateShowAddRemarks] = useState<boolean>(false);
    const [showContractDataApprovalModal, updateShowContractDataApprovalModal] = useState<boolean>(false);
    const [showContractAgreementModal, updateShowContractAgreementModal] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    const contextContractPage = useContext(ContractPageContext);
    const contextSubNavigation = useContext(SubNavigationContext);

    useEffect(() => {
        if (props.status !== undefined && !status) {
            setStatus(props.status);
        }

        if (props.commentsStatus !== undefined && !commentsStatus) {
            setCommentsStatus(props.commentsStatus);
        }

        if (props.contract !== undefined && !contract) {
            setContract(props.contract);
        }

        hasNewCommentFn();

    }, [contract, status, props.contract, props.status]);

    useEffect(() => {
        updateContract();
    }, []);

    useEffect(() => {
		if ((isPlatform('mobileweb') && isPlatform('ios')) || isPlatform('desktop')) {
			setContentHeight('100%');
		}
	});

    const terminate = async () => {
        if (contract?.id) {
            await terminateContract(contract?.id)
                .then(response => {
                    updateTerminateSignModalConfiguration({
                        title: t("contractPage.terminate.title"),
                        info: t("contractPage.terminate.verifyInfo"),
                        // approvementText: t("contractPage.terminate.terminateCheckbox"),
                        buttonText: t("contractPage.terminate.verifyButton")
                    });

                    updateTerminateSignModal(true);
                });
        }

        updateShowTerminate(false);
    };

    const terminateSave = async (code: string) => {

        await terminateContractVerify(contract?.id, code)
            .then(response => {
                updateTerminateSignModal(false);
                setStatus(ContractStatus.TERMINATED);
                contextContractPage.setStatus(ContractStatus.TERMINATED);

                if (response.data.exit_interview_questions && response.data.exit_interview_questions.length > 0) {
                    setShowExitInterview(true);
                    setShowExitInterviewQuestions(response.data.exit_interview_questions);
                }

                updateList();
                updateContract();
            }).catch(error => {
                if (error.response && error.response.status === 400) {
                    setToast(t("common.invalidVerifyCode"));
                    setShowToast(true);
                }
            });
    };


    const documentsStatementSignAction = async () => {
        if (contract?.id) {
            await documentsStatementSign(contract?.id)
                .then(response => {
                    updateDocumentsStatementSignModalConfiguration({
                        title: t("contractPage.documentsStatement.sign.title"),
                        info: t("contractPage.documentsStatement.sign.info"),
                        buttonText: t("contractPage.documentsStatement.sign.signButton")
                    });

                    updateShowDocumentsStatementSignModal(true);
                });
        }

        updateShowDocumentsStatementGenerateModal(false);
    };

    const documentsStatementSignVerifyAction = async (code: string) => {

        await documentsStatementVerify(contract?.id, code)
            .then(response => {
                updateShowDocumentsStatementSignModal(false);
                setStatus(ContractStatus.DOCUMENTS_STATEMENT_VERIFICATION);
                contextContractPage.setStatus(ContractStatus.DOCUMENTS_STATEMENT_VERIFICATION);
                updateList();
                updateContract();
            }).catch(error => {
                if (error.response && error.response.status === 400) {
                    setToast(t("common.invalidVerifyCode"));
                    setShowToast(true);
                }
            });
    };

    const saveRemarks = async (remark: string, description: string, update: boolean = false) => {
        await saveContractComments(contract?.id, remark, description)
            .then(response => {
                updateShowAddRemarks(false);
                let comment = {
                    is_worker_author: true,
                    comment: remark,
                    description: description
                } as ContractComment;

                contract?.sign_comments.unshift(comment);

                setHasNewComment(true);

                if (!update) {
                    updateShowAddRemarks(false);
                    setCommentsStatus(ContractCommentsStatus.COMMENTED_BY_WORKER);
                    contextContractPage.setCommentsStatus(ContractCommentsStatus.COMMENTED_BY_WORKER);
                    updateList();
                    updateContract();
                } else {
                    updateShowConversation(false);
                }
            });
    };

    const preSign = async () => {
        let hasPhoneNumber = null;
        await hasPhone().then(value => {
            hasPhoneNumber = value;
        });

        if (hasPhoneNumber === false) {
            updateShowPhoneModal(true);
        } else {
            if (contract && contract.is_agreement) {
                updateShowContractAgreementModal(true);
            } else {
                updateShowContractDataApprovalModal(true)
            }
        }
    }

    const sign = async () => {

        await signContract(contract?.id)
            .then(response => {
                updateSignModalConfiguration({
                    title: t("contractPage.sign.title"),
                    info: t("contractPage.sign.info"),
                    // approvementText: t("contractPage.sign.accept"),
                    buttonText: contract && contract.is_agreement ? t("contractPage.sign.signButtonWithAgreement") : t("contractPage.sign.signButton")
                });

                updateShowSignModal(true);
            });

        updateShowApprovals(false);
    };

    const signSave = async (code: string) => {
        await signContractVerify(contract?.id, code)
            .then(response => {
                updateShowSignModal(false);
                if (status === ContractStatus.WAITING_FOR_WORKER_SIGN) {
                    setStatus(ContractStatus.SIGNED);
                    contextContractPage.setStatus(ContractStatus.SIGNED);
                } else if (status === ContractStatus.WAITING_FOR_WORKER_SIGN_STATEMENT) {
                    setStatus(ContractStatus.SIGNED_WITH_DOCUMENTS_STATEMENT);
                    contextContractPage.setStatus(ContractStatus.SIGNED_WITH_DOCUMENTS_STATEMENT);
                } else if (status === ContractStatus.SEND_BY_KO) {
                    setStatus(ContractStatus.WAITING_FOR_KO_SIGN);
                    contextContractPage.setStatus(ContractStatus.WAITING_FOR_KO_SIGN);
                } else {
                    setStatus(ContractStatus.SIGNED_BY_WORKER);
                    contextContractPage.setStatus(ContractStatus.SIGNED_BY_WORKER);
                }
                updateContract();
                updateList();

                history.replace(Links.main + Links.contracts);
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    setToast(t("common.invalidVerifyCode"));
                    setShowToast(true);
                }
            });
    };

    const [showExitInterview, setShowExitInterview] = useState<boolean>(false);
    const [showExitInterviewQuestions, setShowExitInterviewQuestions] = useState<[]>();

    const reject = async () => {
        if (contract?.id) {
            await rejectContract(contract?.id)
                .then(response => {
                    setShowAlert(false);
                    setShowAlertAgreement(false);
                    updateShowContractAgreementModal(false);
                    setStatus(ContractStatus.REJECTED_BY_WORKER);
                    contextContractPage.setStatus(ContractStatus.REJECTED_BY_WORKER);

                    updateContract();
                    updateList();
                });
        }
    };

    const downloadPdf = async () => {
        if (contract?.id) {
            const info = await Device.getInfo();
            let deniedPermissions = false;
            if (info.operatingSystem === 'android' && parseFloat(info.osVersion) < 13) {
                let hasPermissions = await Filesystem.checkPermissions();
                deniedPermissions = hasPermissions.publicStorage === 'denied';
            }

            if (deniedPermissions) {
                setShowPermissionAlert(true);
            } else {
                let fileData = null;
                if (contract.has_parent) {
                    fileData = await getContractChangeTypePdf(contract?.id)
                        .then(response => {
                            return response.data;
                        })
                } else {
                    fileData = await getContractPdf(contract?.id)
                        .then(response => {
                            return response.data;
                        })
                }

                if (isPlatform('desktop') || isPlatform('mobileweb')) {
                    downloadFile(fileData, 'ExactPeople/' + contract.number.replace(/\//g, '_') + '.pdf');
                } else {
                    let base64 = null;
                    await blobToBase64(fileData).then(value => base64 = value);
                    if (base64) {
                        try {
                            const result = await Filesystem.writeFile({
                                path: 'ExactPeople/' + contract.number.replace(/\//g, '_') + '.pdf',
                                data: base64,
                                directory: Directory.Library,
                                recursive: true
                            })

                            FileOpener.open({
                                'filePath': result.uri,
                                'contentType': 'application/pdf'
                            });
                        } catch(e) {

                        }
                    }
                }
            }
        }
    };

    const downloadCertificate = async () => {
        if (contract?.id) {
            const info = await Device.getInfo();
            let deniedPermissions = false;
            if (info.operatingSystem === 'android' && parseFloat(info.osVersion) < 13) {
                let hasPermissions = await Filesystem.checkPermissions();
                deniedPermissions = hasPermissions.publicStorage === 'denied';
            }

            if (deniedPermissions) {
                setShowPermissionAlert(true);
            } else {
                let fileData = null;
                fileData = await getContractCertificatePdf(contract?.id)
                    .then(response => {
                        return response.data;
                    })

                let base64 = null;
                if (fileData) {
                    await blobToBase64(fileData).then(value => base64 = value);
                    if (base64) {
                        try {
                            const result = await Filesystem.writeFile({
                                path: 'ExactPeople/' + contract.number.replace(/\//g, '_') + '_certificate.pdf',
                                data: base64,
                                directory: Directory.Library,
                                recursive: true
                            })

                            FileOpener.open({
                                'filePath': result.uri,
                                'contentType': 'application/pdf'
                            });
                        } catch (e) {

                        }
                    }
                }
            }
        }
    };

    const downloadPdfAgreement = async () => {
        if (contract?.id) {
            const info = await Device.getInfo();
            let deniedPermissions = false;
            if (info.operatingSystem === 'android' && parseFloat(info.osVersion) < 13) {
                let hasPermissions = await Filesystem.checkPermissions();
                deniedPermissions = hasPermissions.publicStorage === 'denied';
            }

            if (deniedPermissions) {
                setShowPermissionAlert(true);
            } else {
                let fileData = null;
                fileData = await getContractAgreementPdf(contract?.id)
                    .then(response => {
                        return response.data;
                    })

                let base64 = null;
                if (fileData) {
                    await blobToBase64(fileData).then(value => base64 = value);
                    if (base64) {
                        try {
                            const result = await Filesystem.writeFile({
                                path: 'ExactPeople/' + contract.number.replace(/\//g, '_') + '_agreement.pdf',
                                data: base64,
                                directory: Directory.Library,
                                recursive: true
                            })

                            FileOpener.open({
                                'filePath': result.uri,
                                'contentType': 'application/pdf'
                            });
                        } catch (e) {

                        }
                    }
                }
            }
        }
    };

    const updateList = async () => {
        await getContracts()
            .then(response => {
                context.setContracts(response.data);
            })
            .catch(error => {
                // todo what we show??
            });
    }

    const updateContract = async () => {
        if (props.contract?.id) {
            await getContract(props.contract?.id)
                .then(response => {
                    let contract = response.data.contract as ContractModel;
                    contract.content = response.data.contract_content;
                    contract.termination_content = response.data.termination_content;
                    contract.regulations_content = response.data.regulations_content;
                    contract.agreement_content = response.data.agreement_content;
                    contract.changed_type_content = response.data.changed_type_content;
                    contract.documents_statement_content = response.data.documents_statement_content;
                    contract.tisax_content = response.data.tisax_content;
                    contract.foreign_terms_content = response.data.foreign_terms_content;
                    contract.is_contract_extension = response.data.is_contract_extension;
                    contract.payroll_regulations_content = response.data.payroll_regulations_content;
                    setContract(contract);
                    setStatus(contract.sign_status);
                    setCommentsStatus(contract.comments_status);
                    contextContractPage.setStatus(contract.sign_status);
                    contextContractPage.setCommentsStatus(contract.comments_status);
                })
                .catch(error => {
                    // todo what we show??
                });
        }
    }

    const hasNewCommentFn = () => {
        let count = contract?.sign_comments.length && contract?.sign_comments.filter((comment: ContractComment) => {
            return !comment.status;
        }).length;

        if (count && count > 0) {
            setHasNewComment(true);
        } else {
            setHasNewComment(false);
        }
    }

    const saveExitInterviewAction = async (selectedAnswers: ContractExitInterviewQuestion[], department?: string, coordinator?: string) => {
        if (contract?.id) {
            await saveExitInterview(contract?.id, selectedAnswers, department, coordinator);
        }

        setShowExitInterview(false);
    };

    const showApprovalModal = () => {
        if (contract?.id && contract?.form_type_code === 'AAS_AP' && contract?.is_contract_extension === true) {
            return false;
        }

        return true;
    }

    return (
        <div>
            <Pane topEdge={props.topEdge} marginTop={40}
                  paddingBottom={160}>
                <h1>{contract?.number}</h1>
                {!contract?.has_parent && <div style={{ height: contentHeight }} dangerouslySetInnerHTML={{__html: contract?.content || ''}}/>}
                {contract?.has_parent && <div style={{ height: contentHeight }} dangerouslySetInnerHTML={{__html: contract?.changed_type_content || ''}}/>}
            </Pane>
            {!contract?.has_parent && status && ((contract?.is_active && status !== ContractStatus.REJECTED_BY_WORKER && status !== ContractStatus.WAITING_FOR_ADDITIONAL_DOCUMENTS) || status === ContractStatus.SIGNED || status === ContractStatus.TERMINATED) &&
                <StyledFooter>
                    <IonGrid>
                        <IonRow>
                            {
                                (!commentsStatus || commentsStatus === ContractCommentsStatus.COMMENTS_ACCEPTED_BY_KO || commentsStatus === ContractCommentsStatus.COMMENTS_REJECTED_BY_KO) && (status === ContractStatus.WAITING_FOR_WORKER_SIGN || status === ContractStatus.WAITING_FOR_WORKER_SIGN_STATEMENT || status === ContractStatus.SEND_BY_KO) && contract?.is_active &&
                                <IonCol size="12"><StyledButton className="no-margin-top"
                                                                onClick={() => preSign()}>{t("contractPage.content.signButton")}</StyledButton>
                                </IonCol>
                            }
                            {
                                (!commentsStatus || commentsStatus === ContractCommentsStatus.COMMENTS_ACCEPTED_BY_KO || commentsStatus === ContractCommentsStatus.COMMENTS_REJECTED_BY_KO) && (status === ContractStatus.WAITING_FOR_DOCUMENTS) && contract?.is_active &&
                                <>
                                    <IonCol
                                        size={contract.worker_status === 'PL_STUDENT' && !contract.foreigner_with_polish_language ? "6" : "12"}>
                                        <StyledButton className="no-margin-top"
                                                      onClick={() => {
                                                          navigation.setParams({
                                                              'selectedOption': ContractPageOptions.ATTACHEMENTS
                                                          });
                                                          contextContractPage.setSelectedTab(ContractPageOptions.ATTACHEMENTS);
                                                          contextSubNavigation.setSelected(ContractPageOptions.ATTACHEMENTS);
                                                      }
                                                      }>{t("contractPage.content.addDocumentsButton")}
                                        </StyledButton>
                                    </IonCol>

                                    {contract.worker_status === 'PL_STUDENT' && !contract.foreigner_with_polish_language &&
                                        <IonCol size="6"><StyledButton className="no-margin-top"
                                                                       onClick={() => {
                                                                           updateShowDocumentsStatementGenerateModal(true);
                                                                       }
                                                                       }>{t("contractPage.content.addDocumentsStatement")}</StyledButton>
                                        </IonCol>
                                    }
                                </>
                            }
                            {
                                (!commentsStatus || commentsStatus === ContractCommentsStatus.COMMENTS_ACCEPTED_BY_KO || commentsStatus === ContractCommentsStatus.COMMENTS_REJECTED_BY_KO) && (status === ContractStatus.DOCUMENTS_STATEMENT_VERIFICATION || status === ContractStatus.SIGNED_WITH_DOCUMENTS_STATEMENT) && contract?.is_active &&
                                <>
                                    <IonCol size="6">
                                        <StyledButton className="no-margin-top"
                                                      onClick={() => {
                                                          navigation.setParams({
                                                              'selectedOption': ContractPageOptions.ATTACHEMENTS
                                                          });
                                                          contextContractPage.setSelectedTab(ContractPageOptions.ATTACHEMENTS);
                                                          contextSubNavigation.setSelected(ContractPageOptions.ATTACHEMENTS);
                                                      }
                                                      }>{t("contractPage.content.addDocumentsButton")}
                                        </StyledButton>
                                    </IonCol>

                                    <IonCol size="6">
                                        <StyledLightBlueButton className="no-margin-top"
                                                               onClick={() => {
                                                                   updateShowDocumentsStatementPreviewModal(true);
                                                               }}>
                                            {t("contractPage.content.showDocumentsStatement")}
                                        </StyledLightBlueButton>
                                    </IonCol>
                                </>
                            }
                            {
                                (!commentsStatus || commentsStatus === ContractCommentsStatus.COMMENTS_ACCEPTED_BY_KO || commentsStatus === ContractCommentsStatus.COMMENTS_REJECTED_BY_KO) && (status === ContractStatus.DOCUMENTS_IN_VERIFICATION) && contract?.is_active &&
                                <IonCol size="12"><StyledButton className="no-margin-top"
                                                                onClick={() => {
                                                                    navigation.setParams({
                                                                        'selectedOption': ContractPageOptions.ATTACHEMENTS
                                                                    });
                                                                    contextContractPage.setSelectedTab(ContractPageOptions.ATTACHEMENTS);
                                                                    contextSubNavigation.setSelected(ContractPageOptions.ATTACHEMENTS);
                                                                }
                                                                }>{t("contractPage.content.showDocumentsButton")}</StyledButton>
                                </IonCol>
                            }
                            {
                                (!commentsStatus) && (status === ContractStatus.WAITING_FOR_KO_SIGN) && contract?.is_active &&
                                <IonCol size="12"><StyledWiteButton disabled={true}
                                                                    className="no-margin-top">{t("contractPage.content.waitingForKoSign")}</StyledWiteButton>
                                </IonCol>
                            }
                            {
                                ((status === ContractStatus.WAITING_FOR_WORKER_SIGN || status === ContractStatus.WAITING_FOR_WORKER_SIGN_STATEMENT || status === ContractStatus.SEND_BY_KO || status === ContractStatus.WAITING_FOR_DOCUMENTS) && (!commentsStatus || (commentsStatus === ContractCommentsStatus.COMMENTED_BY_WORKER && !hasNewComment) || commentsStatus === ContractCommentsStatus.COMMENTS_ACCEPTED_BY_KO || commentsStatus === ContractCommentsStatus.COMMENTS_REJECTED_BY_KO)) && contract?.is_active &&
                                <IonCol size="6">
                                    <StyledWiteButton className="no-margin-top"
                                                      onClick={() => updateShowAddRemarks(true)}>{t("contractPage.content.addRemarksButton")}</StyledWiteButton>
                                </IonCol>
                            }
                            {
                                commentsStatus === ContractCommentsStatus.COMMENTED_BY_WORKER && hasNewComment &&
                                <IonCol size="12">
                                    <StyledWiteButton className="no-margin-top"
                                                      onClick={() => updateShowConversation(true)}>{t("contractPage.content.remarksButton")}
                                        <StyledButtonBadge
                                            className="default-position">{contract?.sign_comments.length}</StyledButtonBadge>
                                    </StyledWiteButton>
                                </IonCol>
                            }
                            {
                                (status === ContractStatus.SIGNED || status === ContractStatus.SIGNED_WITH_DOCUMENTS_STATEMENT) && !props.contract?.is_ap && contract?.is_active && isStarted(contract) &&
                                <IonCol size="6">
                                    <StyledWiteButton
                                        className="red no-margin-top"
                                        onClick={() => updateShowTerminate(true)}>{t("contractPage.content.terminate")}</StyledWiteButton>
                                </IonCol>
                            }
                            {
                                ((status === ContractStatus.WAITING_FOR_WORKER_SIGN || status === ContractStatus.WAITING_FOR_WORKER_SIGN_STATEMENT || status === ContractStatus.SEND_BY_KO || status === ContractStatus.WAITING_FOR_DOCUMENTS) && (!commentsStatus || commentsStatus === ContractCommentsStatus.COMMENTS_ACCEPTED_BY_KO || commentsStatus === ContractCommentsStatus.COMMENTS_REJECTED_BY_KO)) && contract?.is_active &&
                                <IonCol size="6">
                                    <StyledWiteButton
                                        className="red no-margin-top"
                                        onClick={() => setShowAlert(true)}>{t("contractPage.content.reject")}</StyledWiteButton>
                                </IonCol>
                            }
                            {
                                (status === ContractStatus.SIGNED || status === ContractStatus.SIGNED_WITH_DOCUMENTS_STATEMENT || status === ContractStatus.SIGNED_WITH_ADDITIONAL_DOCUMENTS || status === ContractStatus.TERMINATED) &&
                                <IonCol
                                    size={((status === ContractStatus.SIGNED || status === ContractStatus.SIGNED_WITH_DOCUMENTS_STATEMENT || status === ContractStatus.SIGNED_WITH_ADDITIONAL_DOCUMENTS)) ? "12" : "6"}>
                                    <StyledLightBlueButton className="no-margin-top"
                                                           onClick={() => downloadPdf()}>{t("contractPage.content.downloadPdf")}</StyledLightBlueButton>
                                </IonCol>
                            }
                            {
                                (contract?.is_certificate) &&
                                <IonCol
                                    size={"12"}>
                                    <StyledLightBlueButton className="no-margin-top"
                                                           onClick={() => downloadCertificate()}>{t("contractPage.content.downloadCertificatePdf")}</StyledLightBlueButton>
                                </IonCol>
                            }
                            {
                                status === ContractStatus.TERMINATED &&
                                <IonCol size="6">
                                    <StyledWiteButton className="no-margin-top"
                                                      onClick={() => updateShowTerminationPreviewModal(true)}>{t("contractPage.terminationPreview.button")}</StyledWiteButton>
                                </IonCol>
                            }
                        </IonRow>
                    </IonGrid>
                </StyledFooter>
            }

            {contract?.has_parent &&
                <StyledFooter>
                    <IonGrid>
                        <IonRow>
                            <IonCol
                                size="12">
                                <StyledLightBlueButton className="no-margin-top"
                                                       onClick={() => downloadPdf()}>{t("contractPage.content.download")}</StyledLightBlueButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </StyledFooter>
            }
            <TerminateModal
                contract={contract}
                isOpen={showTerminate}
                onTerminate={() => terminate()}
                onClose={() => updateShowTerminate(false)}></TerminateModal>
            <RemarksModal
                contract={contract}
                isOpen={showAddRemarks}
                onRemarks={(remark: string, description: string) => saveRemarks(remark, description)}
                onClose={() => updateShowAddRemarks(false)}></RemarksModal>
            {terminateSignModalConfiguration && <SignModal configuration={terminateSignModalConfiguration}
                                                           isOpen={showTerminateSignModal}
                                                           onClose={() => updateTerminateSignModal(false)}
                                                           onSave={(code: string) => terminateSave(code)}>

            </SignModal>}
            <ApprovalsModal
                contract={contract}
                isOpen={showApprovals}
                onSign={() => sign()}
                onClose={() => updateShowApprovals(false)}></ApprovalsModal>
            {signModalConfiguration && <SignModal configuration={signModalConfiguration}
                                                  isOpen={showSignModal}
                                                  onClose={() => updateShowSignModal(false)}
                                                  onSave={(code: string) => signSave(code)}>

            </SignModal>}
            <RemarksListModal
                isOpen={showConversation}
                onClose={() => updateShowConversation(false)}
                comments={contract?.sign_comments}>
            </RemarksListModal>
            <ContractDataApprovalsModal
                isOpen={showContractDataApprovalModal}
                onClose={() => updateShowContractDataApprovalModal(false)}
                contract={contract}
                onHaveRemarks={() => {
                    updateShowContractDataApprovalModal(false);
                    updateShowAddRemarks(true);
                }}
                onSign={() => {
                    updateShowContractDataApprovalModal(false);
                    showApprovalModal() ? updateShowApprovals(true) : sign();
                }}
            >
            </ContractDataApprovalsModal>
            <ContractAgreementModal
                isOpen={showContractAgreementModal}
                onClose={() => updateShowContractAgreementModal(false)}
                contract={contract}
                onReject={() => {
                    setShowAlertAgreement(true);
                }}
                onSign={() => {
                    updateShowContractAgreementModal(false);
                    updateShowContractDataApprovalModal(true);
                }}
            >
            </ContractAgreementModal>
            <TerminationPreviewModal
                isOpen={showTerminationPreviewModal}
                onClose={() => updateShowTerminationPreviewModal(false)}
                contract={contract}>
            </TerminationPreviewModal>
            <DocumentsStatementPreviewModal
                isOpen={showDocumentsStatementPreviewModal}
                onClose={() => updateShowDocumentsStatementPreviewModal(false)}
                contract={contract}>
            </DocumentsStatementPreviewModal>
            <DocumentStatementGenerateModal
                isOpen={showDocumentsStatementGenerateModal}
                onClose={() => updateShowDocumentsStatementGenerateModal(false)}
                onSubmit={() => {
                    documentsStatementSignAction();
                }}
                contract={contract}>
            </DocumentStatementGenerateModal>
            {documentsStatementSignModalConfiguration &&
                <SignModal configuration={documentsStatementSignModalConfiguration}
                           isOpen={showDocumentsStatementSignModal}
                           onClose={() => updateShowDocumentsStatementSignModal(false)}
                           onSave={(code: string) => documentsStatementSignVerifyAction(code)}>
                </SignModal>}
            <TeamMemberPhoneModal isOpen={showPhoneModal} onClose={() => updateShowPhoneModal(false)}
                                  onSubmit={async (phone) => {
                                      updateShowPhoneModal(false)
                                      await Preferences.set({'key': 'phone', 'value': phone});
                                      if (contract && contract.is_agreement) {
                                          updateShowContractAgreementModal(true);
                                      } else {
                                          updateShowContractDataApprovalModal(true)
                                      }
                                  }}></TeamMemberPhoneModal>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={t('contractPage.rejectAlertTitle')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowAlert(false)
                        }
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: () => {
                            reject();
                        }
                    }
                ]}
            />
            <IonAlert
                isOpen={showAlertAgreement}
                onDidDismiss={() => setShowAlertAgreement(false)}
                header={t('contractPage.rejectAgreementAlertTitle')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowAlertAgreement(false)
                        }
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: () => {
                            reject();
                        }
                    }
                ]}
            />
            <ExitInterviewModal
                isOpen={showExitInterview}
                onClose={() => setShowExitInterview(false)}
                contract={contract}
                questions={showExitInterviewQuestions}
                onSave={(selectedAnswers, department, coordinator) => {
                    saveExitInterviewAction(selectedAnswers, department, coordinator);
                }}
            />
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.file_permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.file_permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]}
            />
        </div>
    );
};

export default ContentPane;
