import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import { StyledModalContent } from '../../../components/modal/modal.style';
import {IonCol, IonContent, IonGrid, IonItem, IonLabel, IonRow, IonToast} from '@ionic/react';
import {StyledButton} from '../../../components/button/button.style';
import {Order, Report, ReportRowWorker} from "../reportPage.component";
import {DataSelectable} from "../components/report/reportRowForm.component";
import {checkSpecification, getReportTimepickerMinutesIterations, findOrderById, getPriceRates, getWorkerRates} from "../../../services/report.service";
import AvatarImage from "../../../assets/images/avatar-blue.svg";
import {StyledInput} from "../../../components/form/input/input.style";
import ArrowDownImage from "../../../assets/images/down-arrow.svg";
import ArrowRightImage from "../../../assets/images/arrow-right.svg";
import {StyledIonLabel} from "../../../components/form/input/label.style";
import {StyledIonCheckbox} from "../../../components/form/input/checkbox.style";
import {StyledSelectedWorkerExtended} from "../choosePersonPage.style";
import ListModal from "./listModal.component";
import moment from "moment";
import DateInput from "../../../components/form/input/date.component";
import {PricingType} from "../../../enums/pricing";

type ExtendedWorkerModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (worker: ReportRowWorker) => void;
    worker?: ReportRowWorker | null;
    report: Report;
    order?: Order;
    orderId?: string;
    specificationId?: string | bigint;
};

const ExtendedWorkerModal: React.FC<ExtendedWorkerModalProps> = (props: ExtendedWorkerModalProps) => {
    const {t} = useTranslation();

    const [show, updateShow] = useState<boolean>(false);
    const [selectedWorker, updateSelectedWorker] = useState<ReportRowWorker | null>();
    const [order, setOrder] = useState<Order | null>();

    const [pricingRates, updatePricingRates] = useState<DataSelectable[]>();
    const [showPricingRates, updateShowPricingRates] = useState<boolean>(false);

    const [workerRates, updateWorkerRates] = useState<DataSelectable[]>();
    const [showWorkerRates, updateShowWorkerRates] = useState<boolean>(false);

    const [toast, setToast] = useState<any>('');
    const [showToast, setShowToast] = useState(false);

    const updateSelectedPricingRate = (pricingRate: DataSelectable) => {
        if (selectedWorker) {
            selectedWorker.pricingRate = pricingRate;
        }
        updateShowPricingRates(false);
    }

    const updateSelectedWorkerRate = (workerRate: DataSelectable) => {
        if (selectedWorker) {
            selectedWorker.rate = workerRate;
        }
        updateShowWorkerRates(false);
    }

    const fetchPricingRates = async () => {
        if (props.orderId) {
            await getPriceRates(props.orderId, props.report.reportId)
                .then(response => {
                    updatePricingRates(response.data);
                });
        }
    }

    const fetchWorkerRates = async () => {
        if (props.orderId && selectedWorker && selectedWorker.number) {
            await getWorkerRates(props.orderId, selectedWorker.number, moment(props.report.serviceDate, moment.ISO_8601).format('DD-MM-YYYY'))
                .then(response => {
                    updateWorkerRates(response.data);
                });
        }
    }

    const loadOrder = async () => {
        const specificationId = props.specificationId;
        const reportId = props.report.reportId;
        const orderId = props.orderId;

        if (order === undefined) {
            if (specificationId && specificationId != '0') {
                await checkSpecification(specificationId, reportId, true).then((response) => {
                    let order = response.data as Order;
                    setOrder(order);
                })
            } else if (orderId) {
                await findOrderById(orderId, true).then((response) => {
                    let order = response.data as Order;
                    setOrder(order);
                })
            }
        }
    }

    const onStartDateChange = (value: string) => {
        if (selectedWorker) {
            selectedWorker.from = value;

            updateSelectedWorker({
                ...selectedWorker,
                from: selectedWorker.from
            })
        }
    };

    const onEndDateChange = (value: string) => {
        if (selectedWorker) {
            selectedWorker.to = value;

            updateSelectedWorker({
                ...selectedWorker,
                to: selectedWorker.to
            })
        }
    };

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        loadOrder();
    }, []);

    useEffect(() => {
        handleOpen();
    }, [props.isOpen]);

    useEffect(() => {
        fetchPricingRates();
    }, [props.orderId]);

    useEffect(() => {
        fetchWorkerRates();
    }, [props.orderId, selectedWorker]);

    const handleOpen = () => {
        updateSelectedWorker(props.worker);
        updateShow(props.isOpen);
    };

    const validateWorker = () => {
        if (selectedWorker && props.order) {
            const workingDayEnd = props.order.working_day_end ? props.order.working_day_end : '0600';

            if (selectedWorker.from && selectedWorker.to) {
                let from = moment(selectedWorker.from, moment.ISO_8601).format('HHmm');
                let to = moment(selectedWorker.to, moment.ISO_8601).format('HHmm');
                if (from > to) {
                    if (to > workingDayEnd) {
                        setToast(t("reportPage.validation.workerHoursPassedShift"));
                        setShowToast(true);

                        return false;
                    }
                } else if (from < to) {
                    if (from < workingDayEnd && to > workingDayEnd) {
                        setToast(t("reportPage.validation.workerHoursPassedShift"));
                        setShowToast(true);

                        return false;
                    }
                }
            }
        }

        return true;
    }

    const handleSave = () => {
        if (selectedWorker) {
            if (validateWorker()) {
                props.onSubmit(selectedWorker)
            }
        }
    }

    const canSave = (): boolean => {
        return !!(selectedWorker && selectedWorker.rate && selectedWorker.to && selectedWorker.from && (order && [PricingType.FIXED_PRICE, PricingType.FIXED_PRICE_PER_MONTH].includes(order.pricing_type) ? true : selectedWorker.pricingRate));
    }

    return (
        <>
            <Modal
                isOpen={show}
                disableClose={false}
                onDidDismiss={() => handleClose()}>
                <StyledModalContent>
                    <IonContent className="content full-height">
                        {selectedWorker &&
                            <StyledSelectedWorkerExtended>
                                <IonGrid className="ion-no-padding">
                                    <IonRow className="user-details">
                                        <IonCol>
                                            <div className="profile-avatar">
                                                <img src={selectedWorker?.avatarUrl || AvatarImage} className="avatar"/>
                                            </div>

                                            <br/>

                                            <IonLabel>
                                                {selectedWorker.name}
                                            </IonLabel>
                                        </IonCol>
                                    </IonRow>

                                    <IonRow>
                                        <IonCol className="title">{t('choosePersonExtended.workerRate')}</IonCol>
                                    </IonRow>
                                    <IonCol className="select worker-choose-person-worker-rates" onClick={() => {
                                        updateShowWorkerRates(true);
                                    }}>
                                        <StyledInput disabled={true} value={selectedWorker.rate?.name}/>
                                        <img className="down" src={ArrowDownImage}/>
                                    </IonCol>

                                    <IonRow>
                                        <IonCol className="title">{t('choosePersonExtended.dates')}</IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="5.5" className="worker-choose-person-time-start">
                                            <DateInput
                                                popover={true}
                                                keepContentsMounted={true}
                                                presentation="time"
                                                format="HH:mm"
                                                cancelText={t("common.dateCancel")}
                                                doneText={t("common.dateDone")}
                                                placeholder={t("common.select")}
                                                onChange={e => onStartDateChange(e)}
                                                value={selectedWorker.from}
                                                minuteValues={getReportTimepickerMinutesIterations(props.order)}
                                            />
                                        </IonCol>
                                        <IonCol className="arrow" size="1"><img src={ArrowRightImage}/></IonCol>
                                        <IonCol size="5.5" className="worker-choose-person-time-end">
                                            <DateInput
                                                popover={true}
                                                keepContentsMounted={true}
                                                presentation="time"
                                                format="HH:mm"
                                                cancelText={t("common.dateCancel")}
                                                doneText={t("common.dateDone")}
                                                placeholder={t("common.select")}
                                                onChange={e => onEndDateChange(e)}
                                                value={selectedWorker.to}
                                                minuteValues={getReportTimepickerMinutesIterations(props.order)}
                                            />
                                        </IonCol>
                                    </IonRow>

                                    <IonRow>
                                        <IonCol size="12">
                                            <IonItem lines="none">
                                                <StyledIonLabel
                                                    className="ion-text-wrap">{t('choosePersonExtended.workerOvertime')}</StyledIonLabel>
                                                <StyledIonCheckbox name="workerOvertime" slot="start"
                                                                   checked={selectedWorker.workerOvertime}
                                                                   onIonChange={(e) => {
                                                                       selectedWorker.workerOvertime = e.detail.checked;
                                                                   }}
                                                                   className="worker-choose-person-worker-overtime" />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>

                                    <IonRow>
                                        <IonCol className="title">{t('choosePersonExtended.pricingRate')}</IonCol>
                                    </IonRow>
                                    {
                                        order && [PricingType.FIXED_PRICE, PricingType.FIXED_PRICE_PER_MONTH].includes(order.pricing_type)
                                            ? <IonCol className="select">
                                                <StyledInput readOnly={true}
                                                             disabled={true}
                                                             value={order.pricing_type === PricingType.FIXED_PRICE ? t('choosePersonExtended.pricingType.fixedPrice').toString() : t('choosePersonExtended.pricingType.fixedPricePerMonth').toString()}
                                                />
                                                <img className="down" src={ArrowDownImage}/>
                                            </IonCol>
                                            : <IonCol className="select worker-choose-person-pricing-rates"
                                                      onClick={() => {
                                                          updateShowPricingRates(true);
                                                      }}
                                            >
                                                <StyledInput disabled={true}
                                                             value={selectedWorker.pricingRate?.name ? t(selectedWorker.pricingRate?.name).toString() : ''}/>
                                                <img className="down" src={ArrowDownImage}/>
                                            </IonCol>
                                    }
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonItem lines="none">
                                                <StyledIonLabel
                                                    className="ion-text-wrap worker-choose-person-client-overtime">{t('choosePersonExtended.clientOvertime')}</StyledIonLabel>
                                                <StyledIonCheckbox name="clientOvertime" slot="start"
                                                                   checked={selectedWorker.clientOvertime}
                                                                   onIonChange={(e) => {
                                                                       selectedWorker.clientOvertime = e.detail.checked;
                                                                   }}/>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                                <StyledButton
                                    disabled={!canSave()}
                                    onClick={() => handleSave()}>{t('choosePersonExtended.updateWorker')}</StyledButton>
                            </StyledSelectedWorkerExtended>
                        }
                    </IonContent>
                </StyledModalContent>
            </Modal>
            {pricingRates && <ListModal list={pricingRates} translate={true} isOpen={showPricingRates}
                                        onClose={() => updateShowPricingRates(false)}
                                        selected={selectedWorker?.pricingRate?.id}
                                        onSubmit={updateSelectedPricingRate}/>}
            {workerRates &&
                <ListModal list={workerRates} isOpen={showWorkerRates} onClose={() => updateShowWorkerRates(false)}
                           selected={selectedWorker?.rate?.id} onSubmit={updateSelectedWorkerRate}/>}

            <IonToast
                isOpen={showToast}
                onDidDismiss={() => {
                    setShowToast(false);
                    setToast('');
                }}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
        </>
    );
};

export default ExtendedWorkerModal;