import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
    IonAlert,
    IonButtons,
    IonHeader,
    IonPage,
    useIonViewDidEnter
} from '@ionic/react';
import { StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../components/content/content.style';
import { StyledButton, StyledHeaderButton, StyledHeaderButtonImage, StyledLightBlueButton } from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import RespondImage from '../../assets/images/respond.svg';
import PinImage from '../../assets/images/pin-grey.svg';
import { useForm } from 'react-hook-form';
import Form from '../../components/form';
import StaticPane from '../../components/pane/static-pane.component';
import { StyledContent } from './offerPage.style';
import { useTranslation } from 'react-i18next';
import {InputWithButton, PhoneInputWithButton} from '../../components/form/input/input.style';
import {JobOffer} from "../../models/jobOffer";
import {applyOffer, getOffer} from "../../services/jobOffer.service";
import {Preferences} from "@capacitor/preferences";
import {Browser} from "@capacitor/browser";
import JobOfferContactApprovalModal from "./modals/jobOfferContactApproval.component";
import {updateAcceptanceFieldValue} from "../../services/profile.service";

interface RouterProps {
    offerId?: string | undefined;
}

interface OfferProps extends RouteComponentProps<RouterProps> {
}

enum OfferPageState {
    IDLE,
    PHONE_INPUT,
    PHONE_ENTERED,
    APPLIED
}

const OfferPage: React.FC<OfferProps> = ({match}) => {

    const history = useHistory();
    const [userEmail, setUserEmail] = useState<string|null>('');
    const [userPhone, setUserPhone] = useState<string|null>('');
    const [userPhoneCode, setUserPhoneCode] = useState<string|null>('');
    const [jobOfferContactAcceptance, setJobOfferContactAcceptance] = useState<boolean|null>(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const {t} = useTranslation();

    const handleBack = () => {
        history.goBack();
    };

    const handleApply = async () => {
        if (offer?.link) {
            await Browser.open({ url: offer?.link });
        }
    };
    
    const [pageState, setPageState] = useState<OfferPageState>(OfferPageState.IDLE);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const { handleSubmit } = useForm({
        mode: 'all'
    });

    const {params: {offerId}} = match;

    const [offer, setOffer] = useState<JobOffer>();

    useEffect(() => {
        updateHeight();
    });

    const fetchOffer = async () => {
        if (match.params.offerId) {
            await getOffer(match.params.offerId)
                .then(response => {
                    setOffer(response.data);
                })
                .catch(error => {
                    // todo what we show??
                });
        }
    }

    const fetchUserData = async () => {
        let phone = await Preferences.get({'key': 'phone'});
        setUserPhone(phone.value);

        let phoneCode = await Preferences.get({'key': 'phone_code'});
        setUserPhoneCode(phoneCode.value);

        let email = await Preferences.get({'key': 'email'});
        setUserEmail(email.value);

        let jobOffer = await Preferences.get({'key': 'job_offer_contact_acceptance'});
        setJobOfferContactAcceptance(jobOffer.value === '1');
    }

    useIonViewDidEnter(() => {
        fetchOffer();
        fetchUserData();
    });

    useEffect(() => {
        fetchOffer();

        Preferences.set({
            'key': 'offers_skip_filters',
            'value': '1'
        })
    }, [match.params.offerId])

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current ?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

    const sendPhoneRequest = async (phoneNumber: string, email: string|null = null) => {
        if (offer?.id) {
            applyOffer(offer?.id, phoneNumber.replace(/ /g,''), email);
            setPageState(OfferPageState.PHONE_ENTERED);
        }
    }

	return (
        <IonPage>

            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={t('common.alertHeader')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowAlert(false)
                        }
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: () => {
                            if (userPhone) {
                                sendPhoneRequest(userPhoneCode+userPhone, userEmail)
                            }
                        }
                    }
                ]}
            />
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{offer?.title}</StyledIonTitle>
                        {/*{offer?.link &&*/}
                        {/*    <IonButtons slot="end">*/}
                        {/*        <StyledHeaderButton onClick={() => handleApply()}>*/}
                        {/*            <StyledHeaderButtonImage src={RespondImage}></StyledHeaderButtonImage>*/}
                        {/*        </StyledHeaderButton>*/}
                        {/*    </IonButtons>*/}
                        {/*}*/}
                    </StyledIonToolbar>
                    <div className="sub-title">
                        <small>
                            <img src={PinImage}/>
                            <span>{offer?.location}</span>
                            {offer?.contract_type &&
                                <><br /><span>{t(offer?.contract_type)}</span></>
                            }
                        </small>
                    </div>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} hideGrabber={true}>
                    <Form.Container className="modalForm" onSubmit={handleSubmit(handleApply)}>
                        <StyledContent>
                            <div className="desc" dangerouslySetInnerHTML={{__html: offer?.content || ''}}></div>
                            <div className="actions">
                                { pageState === OfferPageState.IDLE && <div className="title">{t("offerPage.dontWait")}</div> }
                                { !jobOfferContactAcceptance && pageState === OfferPageState.IDLE && <StyledLightBlueButton onClick={ (e) => {
                                    e.preventDefault()
                                    setShowModal(true)
                                } }>{t("offerPage.phoneContact")}</StyledLightBlueButton> }
                                { jobOfferContactAcceptance && userPhone && pageState === OfferPageState.IDLE && <StyledLightBlueButton onClick={ (e) => {
                                    e.preventDefault()
                                    sendPhoneRequest(userPhoneCode+userPhone, userEmail)
                                } }>{t("offerPage.phoneContact")}</StyledLightBlueButton> }
                                { jobOfferContactAcceptance && !userPhone && pageState === OfferPageState.IDLE && <StyledLightBlueButton onClick={ () => setPageState(OfferPageState.PHONE_INPUT) }>{t("offerPage.phoneContact")}</StyledLightBlueButton> }

                                { pageState === OfferPageState.PHONE_INPUT && <div className="title">{t("offerPage.leaveNumber")}</div> }
                                { pageState === OfferPageState.PHONE_INPUT && <PhoneInputWithButton
                                                                        type={'tel'}
                                                                        showListAbove={true}
                                                                        buttonText={t("offerPage.sendButton")} 
                                                                        placeholder={t("offerPage.yourNumber")}
                                                                        onButtonClick={(phoneNumber: string) => { sendPhoneRequest(phoneNumber) } }/> }

                                { (pageState === OfferPageState.IDLE || pageState === OfferPageState.PHONE_INPUT) && offer?.link && <div className="separator">
                                    <span>{t("offerPage.or")}</span>
                                </div> }
                                { (pageState === OfferPageState.IDLE || pageState === OfferPageState.PHONE_INPUT) && offer?.link && <StyledButton onClick={() => { handleApply() }}>{t("offerPage.applyNow")}</StyledButton> }
                                { (pageState === OfferPageState.PHONE_ENTERED || pageState === OfferPageState.APPLIED) && 
                                    <div className="info">
                                        <div className="title">{t("offerPage.thankYou")}</div>
                                        <div>{t("offerPage.weWillContact")}</div>
                                    </div>
                                }
                            </div>
                        </StyledContent>
                    </Form.Container>
                </StaticPane>
            </StyledIonContent>
            <JobOfferContactApprovalModal
                isOpen={showModal}
                onClose={ () => setShowModal(false)}
                    onSubmit={async () => {
                    if (userEmail) {
                        await updateAcceptanceFieldValue('job-offer-contact-acceptance', 'jobOfferContactAcceptance', true)
                        if (userPhone) {
                            sendPhoneRequest(userPhoneCode+userPhone, userEmail)
                        }
                    } else {
                        setPageState(OfferPageState.PHONE_INPUT)
                    }
                    await Preferences.set({'key': 'job_offer_contact_acceptance', 'value': '1'});
                    setJobOfferContactAcceptance(true);
                }} />
        </IonPage>
    );
};

export default OfferPage;
