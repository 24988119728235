import { IonGrid } from "@ionic/react";
import styled from "styled-components";

export const StyledPeopleBar = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    margin-top: 12px;
    margin-bottom: 24px;
    overflow-x: auto;

    > div:nth-child(1) {
        margin-left: 24px;
    }

    > div {
        margin-right: 24px;
    }
`;

export const StyledChip = styled.div`
    display: inline-block;
    text-align: center;
    padding: 4px 8px;

    background: #F4F4F9;
    border-radius: 39px;

    font-size: 10px;
    line-height: 140%;
    color: #888A98;

    &.red {
        background: rgba(244, 48, 48, 0.12);
        color: #F43030;
    }

    &.green {
        background: rgba(20, 204, 82, 0.12);
        color: #14CC52;
    }

    &.orange {
        background: rgba(255, 164, 36, 0.12);
        color: #FFA424;
    }
`;

export const StyledEditButton = styled.button`
    width: 32px;
    height: 32px;

    background: #F4F4F9;
    border-radius: 8px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export const StyledDetailsView = styled(IonGrid)`
    border: 1px solid #EDEDF3;
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgba(136, 138, 152, 0.25);
    border-radius: 16px;
    padding: 18px;

    ion-row {
        align-items: center;
    }

    ion-col {
        text-align: center;
    }
`;