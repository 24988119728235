export const Links = {
    root: '/',
    login: '/login',
    logout: '/logout',
    autoLoggedOut: '/auto-logged-out',
    forgotPassword: '/forgot-password',
    forgotPasswordSuccess: '/forgot-password/check-email',
    createAccount: '/create-account',
    main: '/main',
    addEditValue: '/addEditValue',
    addEditSize: '/addEditSize',
    editDailyAvailability: '/editDailyAvailability',
    takePhoto: '/takePhoto',
    qrCodeSpecification: '/qrCodeSpecification',
    qrCodeWorker: '/qrCodeWorker',
    contract: '/contract',
    contracts: '/contracts',
    contractAppendix: '/contract-appendix',
    folder: '/folder',
    manualBarcode: '/manualBarcode',
    manualOrderFind: '/manualOrderFind',
    choosePerson: '/choosePerson',
    choosePersonExtended: '/choosePersonExtended',
    report: '/report',
    contractData: '/contractData',
    reports: '/reports',
    offers: '/offers',
    offersOpen: '/offers-open',
    offer: '/offer',
    settings: '/settings',
    cities: '/cities',
    language: '/language',
    appMode: '/appMode',
    communicator: '/communicator',
    notifications: '/notifications',
    profile: '/profile',
    planning: '/planning',
    selectPeople: '/selectPeople', 
	status: '/profile/status',
	birthDate: '/profile/birthDate',
	city: '/profile/city',
	phone: '/profile/phone',
	languages: '/profile/languages',
	courses: '/profile/courses',
	becameTeamMember: '/becameTeamMember',
	teamMember: '/teamMember',
	sendPushMessage: '/sendPushMessage',
	conversationPage: '/conversationPage',
	absences: '/availabilities/absences',
    appUpdate: '/appUpdate',
    loan: '/loan',
    loans: '/loans',
    timetable: '/timetable',
    availability: '/availability',
    wallet: '/wallet',
    hoursRegistry: '/hours-registry',
    billings: '/billings',
    myData: '/my-data',
    referFriends: '/refer-friends',
    hoursWorked: '/hours-worked',
    changePassword: '/change-password',
    workerWarehouse: '/worker-warehouse',
    recruitment: '/recruitment',
    userRemove: '/user-remove',
    audit: '/audit',
    audits: '/audits',
    auditStart: '/audit-start',
    auditManualOrderFind: '/audit-manual-order',
    auditManualSpecificationFind: '/audit-manual-specification',
    auditWorkerFind: '/audit-worker-find',
    auditManualWorkerFind: '/audit-manual-worker-find',
    inconsistencyModule: '/inconsistency-module',
    inconsistencyRecordPreview: '/inconsistency/preview',
    inconsistencyRecordEdit: '/inconsistency/edit',
    travelAllowance: {
        car: {
            list: '/travel-allowance/car/list',
            add: '/travel-allowance/car/add',
            edit: '/travel-allowance/car/edit',
        },
        rideRoute: {
            list: '/travel-allowance/ride-route/list',
            add: '/travel-allowance/ride-route/add',
            edit: '/travel-allowance/ride-route/edit',
        },
        ride: {
            list: '/travel-allowance/ride/list',
            add: '/travel-allowance/ride/add',
            edit: '/travel-allowance/ride/edit',
            preview: '/travel-allowance/ride/preview'
        },
        rideReport: {
            preview: '/travel-allowance/ride-report/preview'
        }
    },
}
