import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

import {StyledActionSheetContainer, StyledIonModal} from "@components/modal/modal.style";
import {StyledButton} from "@components/button/button.style";
import {
    RideReportRejectionReasonsHeader,
    RideReportRejectionReasonsItem,
    RideReportRejectionReasonsList
} from "@app/travelAllowance/travelAllowance.style";

import {rejectRideReport} from "@services/travelAllowance/rideReport.service";

import {Links} from "@app/links";

type RideReportRejectActionSheetProps = {
    rideId: number,
    isOpen: boolean,
    onClose: () => void,
}

type RejectionReason = {
    type: string,
    name: string,
}

const RideReportRejectActionSheet: React.FC<RideReportRejectActionSheetProps> = ({
                                                                                     rideId,
                                                                                     isOpen,
                                                                                     onClose,
                                                                                 }: RideReportRejectActionSheetProps) => {
    const rejectionReasons: RejectionReason[] = [
        {
            type: 'INCORRECT_DATA_IN_CONTRACT',
            name: 'travelAllowance.ride.report.incorrectDataInContract',
        },
        {
            type: 'INCORRECT_RATE',
            name: 'travelAllowance.ride.report.incorrectRate',
        },
        {
            type: 'INCORRECT_DATE',
            name: 'travelAllowance.ride.report.incorrectDate',
        },
        {
            type: 'INCORRECT_POSITION',
            name: 'travelAllowance.ride.report.incorrectPosition',
        },
        {
            type: 'ANOTHER_REASON',
            name: 'travelAllowance.ride.report.anotherReason',
        },
    ]

    const {t} = useTranslation();
    const history = useHistory();

    const [selectedReason, setSelectedReason] = useState<RejectionReason>();

    const handleClose = () => {
        onClose();
    }

    const submitRejectRideReport = () => {
        if (selectedReason) {
            rejectRideReport(rideId, selectedReason?.type)
                .then(() => {
                    onClose();
                    history.push(Links.main + Links.reports + '?selectedOption=3');
                });
        }
    }

    const handleRejectionReasonSelect = (rejectionReason: RejectionReason) => {
        if (rejectionReason.type === selectedReason?.type) {
            setSelectedReason(undefined);
        } else {
            setSelectedReason(rejectionReason)
        }
    }

    return (
        <StyledIonModal
            isOpen={isOpen}
            onDidDismiss={handleClose}
            initialBreakpoint={0.75}
            breakpoints={[0, 0.75]}
        >
            <StyledActionSheetContainer className="t-p-50" onClick={(e) => e.stopPropagation()}>
                <RideReportRejectionReasonsHeader>
                    {t('travelAllowance.ride.report.rejectionHeader')}
                </RideReportRejectionReasonsHeader>
                <RideReportRejectionReasonsList>
                    {
                        rejectionReasons.map((reason, index) => {
                            return (
                                <RideReportRejectionReasonsItem selected={reason.type === selectedReason?.type}
                                                                key={index}
                                                                onClick={() => handleRejectionReasonSelect(reason)}>
                                    {t(reason.name)}
                                </RideReportRejectionReasonsItem>)
                        })
                    }
                </RideReportRejectionReasonsList>
                <StyledButton disabled={!selectedReason} onClick={(e) => submitRejectRideReport()}>
                    {t("travelAllowance.rideReport.send")}
                </StyledButton>
            </StyledActionSheetContainer>
        </StyledIonModal>
    );
};

export default RideReportRejectActionSheet;