import React, {useEffect, useRef, useState} from 'react';
import {IonButtons, IonCol, IonFooter, IonGrid, IonHeader, IonPage, IonRow, IonToast} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {RouteComponentProps, useHistory} from "react-router-dom";

import {log} from "@services/firebaseAnalytics.service";
import useNavigation from "@services/navigation.service";
import {getRideReport, sendToAssecoRideReport} from "@services/travelAllowance/rideReport.service";

import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '@components/content/content.style';
import {StyledButton, StyledHeaderButton, StyledHeaderButtonImage,} from "@components/button/button.style";
import {StyledButtonOption} from "@app/travelAllowance/travelAllowance.style";

import ArrowLeftImage from '@assets/images/arrow-left.svg';

import SubNavigation, {SubNavigationOption} from "@components/sub-navigation/subNavigation.component";
import Pane from "@components/pane/pane.component";
import MyDataSkeleton from "@app/myData/components/myDataSkeleton.component";
import RideReportRejectActionSheet
    from "@app/travelAllowance/rideReport/components/rideReportRejectActionSheet.component";

import {RideReport} from "@models/travelAllowance/rideReport";
import RideDocumentPane from "@app/travelAllowance/rideReport/panes/rideDocumentPane.component";
import {RideDocumentType} from "@enums/travelAllowance/rideDocument";

import {Links} from "@app/links";

enum RideReportPageOptions {
    CONTRACT = 0,
    TRAVEL_ORDER = 1,
    REPORT = 2,
}

interface RouterProps {
    reportId: string,
}

interface RideReportPreviewPageProps extends RouteComponentProps<RouterProps> {
}

const RideReportPreviewPage: React.FC<RideReportPreviewPageProps> = ({match}) => {
    const {t} = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);
    const {params: {reportId}} = match;

    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [selectedOption, setSelectedOption] = useState<number>(parseInt(navigation.getParam<string>('selectedOption')) || RideReportPageOptions.CONTRACT);
    const [isRideReportRejectModalOpen, setIsRideReportRejectModalOpen] = useState<boolean>(false);

    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [report, setReport] = useState<RideReport>();

    const options = [
        {
            name: t("travelAllowance.report.contractDocument"),
        },
        {
            name: t("travelAllowance.report.travelOrderDocument"),
        },
        {
            name: t("travelAllowance.report.reportDocument"),
        },
    ];

    useEffect(() => {
        log('page_visit', {
            page: 'Raporty -> Przejazdy -> Podgląd'
        });

        updateHeight();
    }, []);

    useEffect(() => {
        setLoading(true);

        fetchRideReport()
            .then(() => {
                setLoading(false);
            });
    }, []);

    const fetchRideReport = async () => {
        const rideReportData = await getRideReport(parseInt(reportId));

        setReport(rideReportData);
    }

    const handleAcceptRideReport = async () => {
        setLoading(true);

        sendToAssecoRideReport(parseInt(reportId))
            .then(() => {
                history.replace(Links.main + Links.reports);
            })
            .catch((error) => {
                setToast(t("travelAllowance.rideReport.acceptError"));
                setShowToast(true);

                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handleSelect = (option: SubNavigationOption) => {
        const optionIndex = options.indexOf(option);
        if (optionIndex === selectedOption) {
            return;
        }

        navigation.setParam('selectedOption', optionIndex.toString());
        setSelectedOption(optionIndex);
    };

    const updateHeight = () => {
        if (header.current?.clientHeight === 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const handleBack = () => {
        history.goBack();
    };

    const handleOpenRideReportRejectActionSheet = () => {
        setIsRideReportRejectModalOpen(true);
    };

    const handleCloseRideReportRejectActionSheet = () => {
        setIsRideReportRejectModalOpen(false);
    };

    return (
        <IonPage>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("rideReportPreviewPage.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                    <SubNavigation options={options} onSelect={(option) => handleSelect(option)}/>
                </IonHeader>
                <RideReportRejectActionSheet rideId={parseInt(reportId)} isOpen={isRideReportRejectModalOpen}
                                             onClose={handleCloseRideReportRejectActionSheet}/>
                {
                    loading &&
                    <Pane topEdge={topEdge}>
                        <MyDataSkeleton/>
                    </Pane>
                }
                {
                    !loading && report && selectedOption === RideReportPageOptions.CONTRACT &&
                    <RideDocumentPane topEdge={topEdge} report={report} type={RideDocumentType.CONTRACT}/>
                }
                {
                    !loading && report && selectedOption === RideReportPageOptions.TRAVEL_ORDER &&
                    <RideDocumentPane topEdge={topEdge} report={report} type={RideDocumentType.TRAVEL_ORDER}/>
                }
                {
                    !loading && report && selectedOption === RideReportPageOptions.REPORT &&
                    <RideDocumentPane topEdge={topEdge} report={report} type={RideDocumentType.REPORT}/>
                }
            </StyledIonContent>
            <IonFooter>
                <IonGrid>
                    <IonRow>
                        <IonCol size="12">
                            {
                                selectedOption === RideReportPageOptions.CONTRACT &&
                                <StyledButton onClick={() => handleSelect(options[RideReportPageOptions.TRAVEL_ORDER])}>
                                    {t('travelAllowance.report.continue')}
                                </StyledButton>
                            }
                            {
                                selectedOption === RideReportPageOptions.TRAVEL_ORDER &&
                                <StyledButton onClick={() => handleSelect(options[RideReportPageOptions.REPORT])}>
                                    {t('travelAllowance.report.continue')}
                                </StyledButton>
                            }
                            {
                                selectedOption === RideReportPageOptions.REPORT &&
                                <StyledButton onClick={() => handleAcceptRideReport()}>
                                    {t('travelAllowance.report.accept')}
                                </StyledButton>
                            }
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6">
                            <StyledButton className="danger" style={{margin: 0}}
                                          onClick={handleOpenRideReportRejectActionSheet}>
                                {t('travelAllowance.report.reject')}
                            </StyledButton>
                        </IonCol>
                        <IonCol size="6">
                            {
                                selectedOption === RideReportPageOptions.CONTRACT &&
                                <StyledButtonOption style={{margin: 0}}>
                                    <div className="btn-option">
                                        <span>{t('travelAllowance.report.downloadContract')}</span>
                                    </div>
                                </StyledButtonOption>
                            }
                            {
                                selectedOption === RideReportPageOptions.TRAVEL_ORDER &&
                                <StyledButtonOption style={{margin: 0}}>
                                    <div className="btn-option">
                                        <span>{t('travelAllowance.report.downloadTravelOrder')}</span>
                                    </div>
                                </StyledButtonOption>
                            }
                            {
                                selectedOption === RideReportPageOptions.REPORT &&
                                <StyledButtonOption style={{margin: 0}}>
                                    <div className="btn-option">
                                        <span>{t('travelAllowance.report.downloadReport')}</span>
                                    </div>
                                </StyledButtonOption>
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default RideReportPreviewPage;