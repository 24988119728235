const MomentRange = require('moment-range');
const Moment = require('moment-timezone')
const moment = MomentRange.extendMoment(Moment);

const minutesToHoursMinutes = (mins: number) => {
    let h = Math.floor(mins / 60);
    let m = (mins % 60).toFixed(0);

    let hr = h;
    let mn = parseInt(m) < 10 ? "0" + m : m;
    return `${hr}h ${mn}m`;
};

const nextMoment = (interval: number = 5) => {
    let nextMoment = moment().add(interval, 'minutes');
    nextMoment.minutes(Math.floor(nextMoment.minutes() / interval) * interval);

    return nextMoment;
}

const prevMoment = (interval: number = 5) => {
    let prevMoment = moment().subtract(interval, 'minutes');
    prevMoment.minutes(Math.floor(prevMoment.minutes() / interval) * interval);

    return prevMoment;
}

const getDefaultOffset = () => {
    return moment().tz('Europe/Warsaw').utcOffset()
}

const getDefaultTimezone = () => {
    return 'Europe/Warsaw';
}

const getNumberOfWorkDays = (startDate: string, endDate: string) => {
	let numWorkDays = 0;
	const currentDate = moment(startDate);
	const lastDate = moment(endDate);

	while (currentDate <= lastDate) {
		if (currentDate.day() !== 0 && currentDate.day() !== 6) {
			numWorkDays++;
		}
		currentDate.add(1, 'day');
	}

	return numWorkDays;
}

export {
    minutesToHoursMinutes,
    getDefaultOffset,
    getDefaultTimezone,
	nextMoment,
	prevMoment,
    getNumberOfWorkDays,
};
