import React, {useState} from 'react';

import {RideReport} from "@models/travelAllowance/rideReport";
import Pane from '@components/pane/pane.component';
import {Device} from "@capacitor/device";
import {Directory, Filesystem} from "@capacitor/filesystem";
import {blobToBase64} from "@services/file.service";
import {FileOpener} from "@capacitor-community/file-opener";
import {getRideDocumentPdfByType} from "@services/travelAllowance/rideDocument.service";
import {RideDocumentType} from "@enums/travelAllowance/rideDocument";
import {isString} from "@craco/craco/dist/lib/utils";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";
import {IonAlert} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {StyledButton} from "@app/travelAllowance/travelAllowance.style";

type RideDocumentPaneProps = {
    topEdge?: number,
    report: RideReport,
    type: RideDocumentType,
}

const RideDocumentPane: React.FC<RideDocumentPaneProps> = ({topEdge, report, type}: RideDocumentPaneProps) => {
    const {t} = useTranslation();
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    const downloadRideDocumentPdf = async () => {
        const info = await Device.getInfo();

        let deniedPermissions = false;
        if (info.operatingSystem === 'android' && parseFloat(info.osVersion) < 13) {
            let hasPermissions = await Filesystem.checkPermissions();
            deniedPermissions = hasPermissions.publicStorage === 'denied';
        }

        if (deniedPermissions) {
            setShowPermissionAlert(true);
        } else {
            const fileData = await getRideDocumentPdfByType(report.id, type)
                .then(response => {
                    return response;
                })

            if (fileData) {
                const base64 = await blobToBase64(fileData).then((value) => {
                    return value;
                });

                if (isString(base64)) {
                    try {
                        const result = await Filesystem.writeFile({
                            path: 'ExactPeople/' + report.number.replace(/\//g, '_') + '.pdf',
                            data: fileData,
                            directory: Directory.Library,
                            recursive: true
                        })

                        await FileOpener.open({
                            'filePath': result.uri,
                            'contentType': 'application/pdf'
                        });
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        }
    };

    return (
        <Pane topEdge={topEdge} marginTop={40} paddingBottom={147}>
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.file_permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.file_permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);

                            await NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]}
            />
            <StyledButton onClick={() => downloadRideDocumentPdf()}
                          className="center"
                          style={{width: "99%", marginLeft: "0.5%"}}
            >
                <div className="btn center">
                    <span>{t('travelAllowance.report.rideDocument.download')}</span>
                </div>
            </StyledButton>
        </Pane>
    );
};

export default RideDocumentPane;
