import {Preferences} from "@capacitor/preferences";

const isTravelAllowanceEnabled = async () => {
    const isTravelAllowanceEnabled = await Preferences.get({'key': 'travel_allowance_enabled'});

    return isTravelAllowanceEnabled.value === '1';
}

export {
    isTravelAllowanceEnabled
};