import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IonButtons, IonHeader, IonPage } from '@ionic/react';
import { StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../components/content/content.style';
import { StyledHeaderButton, StyledHeaderButtonImage } from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import CheckImage from '../../assets/images/check.svg';
import { StyledContent } from './addEditDatePage.style';
import { getConfiguration } from '../../services/addEditValuePage.service';
import { StyledIonDatetime } from "../../components/form/input/date.style";
import {useTranslation} from "react-i18next";
import moment from 'moment';
import StaticPane from '../../components/pane/static-pane.component';
import DateInput from "../../components/form/input/date.component";

const AddEditDatePage: React.FC = () => {

	const { t } = useTranslation();
	const history = useHistory();
	const [error, setError] = useState<string>();

	if (!getConfiguration()) {
        history.goBack();
    }

    const handleBack = () => {
        const handleDismiss = getConfiguration()?.handleDismiss;

        if (typeof handleDismiss === 'function') {
            handleDismiss();
        } else {
            history.goBack();
        }
    };

    const handleSave = async () => {
        const handleSave = getConfiguration()?.handleSave;

        if (typeof handleSave === 'function') {
            let response:any = await handleSave(selectedDate);
            if (response !== undefined) {
				setError(response);
			}
	    } else {
            history.goBack();
        }
    };

    let date = getConfiguration()?.defaultValue !== null && getConfiguration()?.defaultValue !== "undefined" ?
		moment(getConfiguration()?.defaultValue, 'DD.MM.YYYY').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');

	const [selectedDate, setSelectedDate] = useState<string>(date);
	const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    useEffect(() => {
        updateHeight();
    });

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current ?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

	const onChange = (value: string) => {
		setSelectedDate(value!);

		if (moment().diff(value, 'years') < 18) {
			setError(t('profileTab.personalData.validation.youShouldBeAdult'));
		} else {
			setError('');
		}
	}

	return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton className="back-btn" onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{getConfiguration()?.title}</StyledIonTitle>
                        <IonButtons slot="end">
                            <StyledHeaderButton className="save-btn" onClick={() => handleSave()} disabled={error != ''}>
                                <StyledHeaderButtonImage src={CheckImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} hideGrabber={true}>
                    <StyledContent>
						<DateInput
                            format="DD.MM.YYYY"
                            presentation="date"
                            doneText={t('common.dateDone')}
                            cancelText={t('common.dateCancel')}
                            placeholder={t('common.select')}
                            onChange={e => onChange(e) }
                            value={selectedDate} />
						{error && <p className="errorMessage">{error}</p>}
					</StyledContent>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default AddEditDatePage;
